<template>
  <div
    ref="scrollButtonsWrapper"
    class="scroll-buttons-wrapper position-absolute d-flex justify-content-center align-items-end w-100 pr-2"
  >
    <VBtn
      v-if="selectedPoiId && !isScrollUpButtonShown && isScrollDownButtonShown"
      v-blur
      rounded
      small
      class="scroll-down-button mx-2 grey-lighten-4 box-shadow-elevation-12"
      data-test="scrollDownButton"
      @click="$emit('scrollToPoiSelection', selectedPoiType, { activePoi: true, id: selectedPoiId }, 'center')"
    >
      <VIcon
        class="pr-2"
        color="textSecondary"
      >
        mdi-arrow-down
      </VIcon>
      <span class="button textPrimary--text ">{{ $t('bookingForm.scrollToStops') }}</span>
    </VBtn>
    <VBtn
      v-else-if="selectedPoiId && directionContainerScrollPosition && isScrollUpButtonShown"
      v-blur
      small
      fab
      class="scroll-up-button mx-2"
      data-test="scrollUpButton"
      color="brandingPrimary box-shadow-elevation-6"
      @click="$emit('scrolToTop')"
    >
      <VIcon color="textWhite">
        mdi-arrow-up
      </VIcon>
    </VBtn>
  </div>
</template>

<script>

export default {
  name: 'ScrollButtons',
  props: {
    directionContainerScrollPosition: {
      type: Number,
      default: 0,
    },
    selectedPoiId: {
      type: String,
      default: null,
    },
    selectedPoiType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isScrollUpButtonShown: false,
      isScrollDownButtonShown: false,
      timeOutId: null,
    }
  },
  watch: {
    directionContainerScrollPosition: {
      handler() {
        this.setStyles();
        this.hideScrollDownButton();
      },
      immediate: false,
    },
    selectedPoiId: {
      handler() {
        clearTimeout(this.timeOutId);
        this.timeOutId = setTimeout(() => {
          this.setStyles();
          this.hideScrollDownButton();
        }, 300);
      },
    },
  },
  methods: {
    setStyles() {
      const setLocationButton = document.getElementById('set-location-button');
      const container = document.getElementById('direction-dialog-container');

      if (setLocationButton) {
        this.isScrollUpButtonShown = !this.checkVisibility(setLocationButton, container);
        const stepperHeader = document.getElementsByClassName('stepper-container')[0];

        if (this.isScrollUpButtonShown) {
          this.$refs.scrollButtonsWrapper.style.bottom = null;
          this.$refs.scrollButtonsWrapper.style.top = `${stepperHeader.clientHeight + 20}px`;
        } else {
          const dialog = document.getElementsByClassName('direction-dialog')[0];
          this.$refs.scrollButtonsWrapper.style.top = `${dialog.clientHeight - 53}px`;
        }
      }
    },
    hideScrollDownButton() {
      if (this.selectedPoiId) {
        const poiItemStop = document.getElementById(`poi-stop-${this.selectedPoiId}`);

        if (!poiItemStop) {
          this.isScrollDownButtonShown = false;
          return;
        }

        const container = document.getElementById('direction-dialog-container');
        const isVisiblePoiItem = this.checkVisibility(poiItemStop, container);

        if (isVisiblePoiItem && !this.isScrollUpButtonShown) {
          this.isScrollDownButtonShown = false;
        } else {
          this.isScrollDownButtonShown = true;
        }
      }
    },
    checkVisibility(targetElement, container) {
      if (!targetElement) {
        return false;
      }

      const { bottom, height, top } = targetElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    },
  },
}
</script>

<style lang="scss">
.scroll-buttons-wrapper {
  .scroll-up-button {
    z-index: 3;
  }

  .scroll-down-button {
    z-index: 3;
    height: 36px !important;
   }
}
</style>
