/* eslint-disable no-case-declarations */
<template>
  <VCard ref="datesSelection">
    <ValidationObserver
      ref="dateForm"
      v-slot="{ invalid }"
      name="dateForm"
    >
      <ValidationProvider
        ref="date"
        name="date"
        :rules="{ required: true }"
        mode="eager"
      >
        <VDatePicker
          :value="date"
          full-width
          :show-current="false"
          data-test="datePicker"
          :locale="getCurrentLang"
          scrollable
          :class="isCustomHeightDatePickerHeight ? 'date-picker body-1' : ''"
          calendar-class="my-datepicker_calendar"
          :picker-date="pickerDate"
          firstDayOfWeek="1"
          :allowedDates="allowedDates"
          :min="minDate"
          :max="maxDate"
          color="brandingPrimary"
          @update:picker-date="onChangePickerDate"
          @change="updateDate"
        >
          <slot name="customDefaultHeader">
            <span
              v-if="!date"
              class="date__picker-custom-header"
            >{{ dialogTitle ? dialogTitle : $t("bookingForm.outward.selectFlightDate") }}
            </span>
          </slot>
          <VBtn
            class="close-button mx-2"
            fab
            small
            @click="$emit('closeDialog')"
          >
            <VIcon> mdi-close </VIcon>
          </VBtn>
        </VDatePicker>
      </ValidationProvider>
      <template v-if="date">
        <VRow
          class="dialog-row pl-10"
        >
          <VCol
            cols="10"
            class="p-0"
          >
            <VMenu
              ref="menu"
              v-model="timePickerMenuOpen"
              :close-on-content-click="false"
              :nudge-top="180"
              :nudge-right="18"
              :value="time"
              transition="scale-transition"
              offset-y
              max-width="276px"
              min-width="276px"
              @change="updateTime"
            >
              <template #activator="{ on, attrs }">
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="minutesInput"
                  name="minutesInput"
                  mode="aggressive"
                  :rules="{
                    required: true,
                    timeInInterval: {
                      time: pickerTimeInMinutes,
                      ranges: [],
                    },
                  }"
                >
                  <VTextField
                    :value="pickerTime"
                    :label="$t('bookingForm.outward.selectTime')"
                    data-test="timeTextField"
                    prepend-icon="mdi-clock-outline"
                    :hide-details="!errors.length"
                    :error="!!errors.length"
                    :errorMessages="$t(errors[0])"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </ValidationProvider>
              </template>
              <VTimePicker
                v-if="timePickerMenuOpen"
                v-model="pickerTime"
                format="24hr"
                color="brandingPrimary"
                data-test="timePicker"
                :allowed-hours="allowedHours"
                :allowed-minutes="allowedMinutes"
                :min="minTime"
                :max="maxTime"
                full-width
                @click:minute="updateMinutesPicker"
                @click:hour="updateTime"
              />
            </VMenu>
          </VCol>
        </VRow>
      </template>
      <slot
        name="customBottom"
        :invalid="invalid"
      >
        <VRow class="ma-0 dialog-row px-6 pb-4">
          <VBtn
            color="brandingPrimary"
            width="100%"
            class="button"
            data-test="setDateAndTimeButton"
            :class="{ disabled: invalid }"
            @click="submitSelect"
          >
            <span class="textWhite--text">
              {{ $t("bookingForm.outward.select") }}
            </span>
          </VBtn>
        </VRow>
      </slot>
    </ValidationObserver>
  </VCard>
</template>
<script>
import STimeConverter from '@slg/web-customer-shared/src/utils/timeConverter';
import { mapGetters } from 'vuex';

// todo: rethink this
export default {
  name: 'CustomDateSelect',
  props: {
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    minTime: {
      type: String,
      default: '',
    },
    maxTime: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    dialogTitle: {
      type: String,
      default: '',
    },
    isDialogOpen: {
      type: Boolean,
      default: null,
    },
    isRouteTaxi: {
      type: Boolean,
      default: false,
    },
    pickerDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      weekEnd: null,
      pickerTime: null,
      hours: null,
      timePickerMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters('settingsConfiguration', ['getBookingMinutesStep']),
    isCustomHeightDatePickerHeight() {
      return this.getCurrentLang !== 'en' && !this.date;
    },
    getCurrentLang() {
      return this.$route.params.lang;
    },
    formattedDate() {
      return STimeConverter.convertToMonthDayFormatWithoutComma(
        this.date,
        this.$route.params.lang,
      );
    },
    pickerTimeInMinutes() {
      return STimeConverter.convertTimeToMinutes(this.pickerTime);
    },
    timeRanges() {
      return ['00:00-00:00'];
    },
  },
  created() {
    this.pickerTime = this.time;
  },
  methods: {
    onChangePickerDate(data) {
      this.$emit('onChangePickerDate', data);
    },
    updateMinutesPicker() {
      this.updateTime();
      this.timePickerMenuOpen = false;
    },
    allowedDates(val) {
      return (
        !this.weekEnd?.length
          || this.weekEnd.every(
            (day) => day !== STimeConverter.convertToDayOfWeek(val),
          )
      );
    },
    allowedHours() {
      return true;
    },
    allowedMinutes(val) {
      if (this.isRouteTaxi) {
        return val % this.getBookingMinutesStep === 0;
      }
      return true;
    },
    updateDate(newValue) {
      this.$emit('updateDate', newValue);
      if (this.$vuetify.breakpoint.xs) {
        setTimeout(() => {
          this.$refs.datesSelection.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'center',
          });
        }, 100);
      }
    },
    updateTime(val) {
      this.hours = val;
      this.$nextTick(() => {
        this.$emit('updateTime', this.pickerTime);
      });
    },
    submitSelect() {
      this.$emit('submitSelect');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@slg/web-customer-shared/src/style/variables.scss";

.dialog-row {
  width: 100%;
}

.datepicker {
  .v-date-picker-title {
    height: 140px !important;
  }
}

.date__picker-custom-header {
  margin-top: 7px;
  margin-right: 2px;
  position: absolute;
  top: 28px;
  left: 14px;
  right: 14px;
  font-size: $fs-32;
  line-height: 133%;
  color: var(--v-textWhite-base) !important;
}

.close-button {
  line-height: 12px;
  font-size: $fs-8;
  margin-top: 7px;
  margin-right: 2px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
