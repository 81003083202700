<template>
  <VContainer class="px-0 w-30">
    <VCard>
      <VRow justify="center">
        <VCol
          class="d-flex align-items-center flex-column px-2 pb-0"
          cols="12"
          col="12"
        >
          <div class="py-3 pb-0">
            <div
              class="
                      img-container
                      d-flex
                      align-items-center
                      justify-content-center
                    "
            >
              <img
                :src="
                  require('@/assets/img/login.svg')
                "
                alt="...step"
              >
            </div>
            <div class="pt-6 text-center">
              <span class="heading-5 textPrimary--text">
                {{ $t('authForm.login') }}
              </span>
            </div>
          </div>
        </VCol>
      </VRow>
      <VRow class="pt-6 px-4">
        <VCol class="body-1 textPrimary--text">
          {{ $t('authForm.orUseYourPhone') }}
        </VCol>
      </VRow>
      <ValidationObserver
        ref="login"
        name="login"
      >
        <VRow justify="center">
          <VCol
            class="px-8 pt-0 pb-0"
            cols="12"
            col="12"
            @keyup.enter="onSubmit"
          >
            <ValidationProvider
              ref="login"
              v-slot="{ errors }"
              name="login"
              :rules="{ required: true }"
              mode="aggressive"
            >
              <VTextField
                v-model="login"
                data-test="login.emailField"
                :hide-details="!errors.length"
                :placeholder="`${$t('authForm.email')} *`"
                :error="!!errors.length"
                :error-messages="$t(errors[0])"
                @input="isLoginOrPasswordIncorrect = false"
              />
            </ValidationProvider>
          </VCol>
          <VCol
            class="px-8 py-0"
            cols="12"
          >
            <ValidationProvider
              ref="password"
              v-slot="{ errors }"
              name="password"
              :rules="{ required: true }"
              mode="aggressive"
            >
              <VTextField
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :hide-details="!errors.length"
                data-test="login.passwordField"
                :placeholder="`${$t('authForm.password')} *`"
                :class="{
                  'pb-3': isLoginOrPasswordIncorrect
                }"
                :error="!!errors.length"
                :error-messages="validationErrorResolve(errors[0])"
                class="body-1"
                @click:append="showPassword = !showPassword"
                @input="isLoginOrPasswordIncorrect = false"
              />
            </ValidationProvider>
          </VCol>
          <VCol
            v-if="isLoginOrPasswordIncorrect"
            data-test="login.errorMessage"
            class="px-8 pt-0 pb-3"
          >
            <span class="body-2 error--text">
              {{ $t('authForm.logInError') }}
            </span>
          </VCol>
          <VCol
            class="px-8 pb-3 pt-0"
            cols="12"
            col="12"
          >
            <VBtn
              class="w-100 border-radius-8"
              dark
              large
              color="brandingPrimary"
              data-test="login.submitButton"
              @click="onSubmit"
            >
              <span class="button">
                {{ $t('authForm.login') }}
              </span>
            </VBtn>
          </VCol>
        </VRow>
      </ValidationObserver>
    </VCard>
  </VContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import RetailerUserService from '@/services/RetailerUserService';
import ValidationErrorMixin from '@slg/web-customer-shared/src/mixins/ValidationErrorMixin';
import { SGlobalErrorDialogMixin } from '@slg/web-customer-shared';

export default {
  name: 'AuthForm',
  mixins: [SGlobalErrorDialogMixin, ValidationErrorMixin],
  data() {
    return {
      login: '',
      password: '',
      showPassword: false,
      isLoginOrPasswordIncorrect: false,
    }
  },
  computed: {
    ...mapGetters({
      getLocation: 'auth/getLocation',
    }),
  },
  created() {
    this.$store.commit('common/changeMidocoAuthFailedState', false);
    this.$store.commit('auth/setIsMidocoAuth', false);
    this.$store.commit('auth/clearAuthTokenAndProfileInfo');
  },
  methods: {
    setGlobalLoading(isLoading) {
      this.$store.commit('common/setGlobalLoading', isLoading);
    },
    setAuthToken(authToken) {
      if (localStorage.getItem('authToken') !== authToken) {
        this.$store.commit('auth/clearAuthTokenAndProfileInfo');
        this.$store.commit('auth/setAuthToken', authToken);
      }
    },
    async populateUserProfileInfo() {
      try {
        await this.$store.dispatch('auth/populateUserProfileInfo');
      } catch (e) {
        await this.setGlobalError(e);
      }
    },
    async redirect() {
      await this.$emit('onSignInSuccess');
    },
    async onSubmit() {
      this.setGlobalLoading(true);
      this.$refs.login.validate().then(async (success) => {
        if (success) {
          try {
            // eslint-disable-next-line camelcase
            const { data: { access_token } } = await RetailerUserService.logIn(this.login, this.password);
            this.setAuthToken(access_token)
            this.$router.push({ name: 'Booking' });
          } catch (error) {
            this.isLoginOrPasswordIncorrect = true;
          }
        }
      });
      this.setGlobalLoading(false);
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 30%;
}
</style>
