<template>
  <div>
    <VDialog
      v-if="isDialogOpen"
      :value="isDialogOpen"
      data-test="datesSelectionDialog"
      width="360"
      persistent
      outlined
    >
      <component
        :is="currentComponent"
        :outwardState="outwardState"
        :outwardTime="currentComponent === 'OutwardDateSelect' ? outwardState.time : outwardTaxiState.time"
        :outwardDate="outwardState.date"
        :outwardTaxiState="outwardTaxiState"
        :defaultMaxDate="getDefaultMaxDate"
        :defaultMinDate="getDefaultMinDate"
        :dateNow="dateNow"
        @setOutwardDate="setOutwardDate"
        @setOutwardRouteTaxiDate="setOutwardRouteTaxiDate"
        @closeDialog="closeDialog"
      />
    </VDialog>
    <ValidationObserver
      ref="bookingForm"
      name="bookingFormOutward"
      class="w-100"
      tag="form"
    >
      <div @click.stop="getReservation.originalOutwardPrice === null && openDialog('OutwardDateSelect')">
        <ValidationProvider
          v-slot="{ errors }"
          ref="outward"
          name="outward"
          mode="lazy"
          :rules="{ required: (status !== BOOKING_STATUSES.BOOKED && bookingEdit) }"
        >
          <VTextField
            v-model="outwardDate"
            class="pb-6 pt-0 mt-0"
            data-test="outwardDateField"
            :disabled="(getReservation.originalOutwardPrice !== null) || (status === BOOKING_STATUSES.BOOKED && bookingEdit)"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${$t('bookingForm.outward.flightDate')} *`"
            prepend-icon="mdi-calendar"
            readonly
          />
        </ValidationProvider>
      </div>
      <div @click.stop="getReservation.originalOutwardPrice === null && openDialog('RouteTaxiDateSelect')">
        <ValidationProvider
          v-slot="{ errors }"
          ref="outwardtaxi"
          name="outwardtaxi"
          mode="lazy"
          :rules="{ required: (status !== BOOKING_STATUSES.BOOKED && bookingEdit) }"
        >
          <VTextField
            v-model="outwardTaxiDate"
            class="pb-6 pt-0 mt-0"
            data-test="outwardTaxiDateField"
            :disabled="(getReservation.originalOutwardPrice !== null) || (status === BOOKING_STATUSES.BOOKED && bookingEdit)"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${isPickUpRide ? $t('bookingForm.outward.pickUp') : $t('bookingForm.outward.dropOff')} *`"
            prepend-icon="mdi-calendar"
            readonly
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="outwardflight"
          name="outwardflight"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            :disabled="status === BOOKING_STATUSES.BOOKED && bookingEdit"
            :value="outwardFlightNumber"
            class="pb-6 pt-0 mt-0"
            data-test="outwardFlightField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${$t('bookingForm.outward.flightNumber')} *`"
            @input="setFlightNumber"
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="outwardcomment"
          name="outwardcomment"
          mode="lazy"
          :rules="{ required: false }"
        >
          <VTextField
            :value="outwardComment"
            class="pb-6 pt-0 mt-0"
            data-test="outwardCommentField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="commentLabel"
            @input="setComment"
          />
        </ValidationProvider>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import moment from 'moment';
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import STimeConverter from '@slg/web-customer-shared/src/utils/timeConverter';
import TimeConverter from '@/utils/timeConvertor/timeConverter';
import { mapGetters } from 'vuex';
import { BOOKING_STATUSES } from '@/utils/constants/booking';
import i18n from '@/plugins/i18n';
import OutwardDateSelect from './outwardDateSelect/OutwardDateSelect.vue';
import RouteTaxiDateSelect from './routeTaxiDateSelect/RouteTaxiDateSelect.vue';

export default {
  name: 'OutwardTrip',
  components: {
    OutwardDateSelect,
    RouteTaxiDateSelect,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    commentLabel: {
      type: String,
      default: i18n.t('bookingForm.outward.comment'),
    },
  },
  data() {
    return {
      BOOKING_STATUSES,
      isDialogOpen: false,
      currentComponent: null,
      dateNow: null,
      maxNumberOfDaysForFutureBooking: 365,
    };
  },
  computed: {
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
    }),
    ...mapGetters({
      getOutwardFormCreate: 'booking/getOutwardForm',
      isPickUpRideCreate: 'booking/isPickUpRide',
      getReservationCreate: 'booking/getReservation',
    }),
    ...mapGetters({
      getOutwardFormEdit: 'bookingEdit/getOutwardForm',
      isPickUpRideEdit: 'bookingEdit/isPickUpRide',
      getReservationEdit: 'bookingEdit/getReservation',
    }),
    getOutwardForm() {
      return this.bookingEdit ? this.getOutwardFormEdit : this.getOutwardFormCreate;
    },
    isPickUpRide() {
      return this.bookingEdit ? this.isPickUpRideEdit : this.isPickUpRideCreate;
    },
    getReservation() {
      return this.bookingEdit ? this.getReservationEdit : this.getReservationCreate;
    },
    stateName() {
      return this.bookingEdit ? 'bookingEdit' : 'booking';
    },
    outwardState() {
      return this.getOutwardForm.outwardDate;
    },
    outwardTaxiState() {
      return this.getOutwardForm.routeTaxiDate;
    },
    outwardDate() {
      if (this.outwardState.date && this.outwardState.time) {
        return TimeConverter.convertDateToFormatWithYear(this.outwardState.time, this.outwardState.date, this.$route.params.lang);
      }
      return '';
    },
    outwardTaxiDate() {
      if (this.outwardTaxiState.date && this.outwardTaxiState.time) {
        return TimeConverter.convertDateToFormatWithYear(this.outwardTaxiState.time, this.outwardTaxiState.date, this.$route.params.lang);
      }
      return '';
    },
    outwardFlightNumber() {
      return this.getOutwardForm.flightNumber || '';
    },
    outwardComment() {
      return this.getOutwardForm.comment || '';
    },
    getDefaultMaxDate() {
      const maxDate = this.dateNow.clone();
      return maxDate
        .add(this.maxNumberOfDaysForFutureBooking, 'days')
        .format(SConstants.TIME_FORMAT.YYYY_MM_DD);
    },
    getDefaultMinDate() {
      return this.dateNow.format(SConstants.TIME_FORMAT.YYYY_MM_DD);
    },
  },
  created() {
    this.dateNow = moment();
    if (
      !Number.isNaN(
        STimeConverter.convertStringToMilliseconds(
          this.outwardState.date,
          this.outwardState.time,
        ),
      )
      && this.dateNow.valueOf()
      >= STimeConverter.convertStringToMilliseconds(
        this.outwardState.date,
        this.outwardState.time,
      )
    ) {
      this.$emit('clearDates');
    }
  },
  methods: {
    openDialog(name) {
      if (this.status === BOOKING_STATUSES.BOOKED && this.bookingEdit) {
        return;
      }
      this.dateNow = moment();
      this.currentComponent = name;
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    setOutwardDate(outwardDate) {
      this.$store.commit(`${this.stateName}/setOutwardDate`, outwardDate.date);
      this.$store.commit(`${this.stateName}/setOutwardTime`, outwardDate.time);
      this.$store.commit(`${this.stateName}/generateOutwardRouteTaxiDate`);
    },
    setOutwardRouteTaxiDate(outwardRouteTaxiDate) {
      this.$store.commit(`${this.stateName}/setOutwardTaxiDate`, outwardRouteTaxiDate.date);
      this.$store.commit(`${this.stateName}/setOutwardTaxiTime`, outwardRouteTaxiDate.time);
    },
    setComment(data) {
      this.$store.commit(`${this.stateName}/setOutwardComment`, data);
    },
    setFlightNumber(data) {
      this.$store.commit(`${this.stateName}/setOutwardFlightNumber`, data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
