import { render, staticRenderFns } from "./ReturnTaxiDateSelect.vue?vue&type=template&id=28cfa19a&scoped=true"
import script from "./ReturnTaxiDateSelect.vue?vue&type=script&lang=js"
export * from "./ReturnTaxiDateSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28cfa19a",
  null
  
)

export default component.exports