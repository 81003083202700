import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        brandingPrimary: '#6A32A1',
        greyLight: '#F5F5F5',
        secondary: '#0694D3',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        brandingSecondary: '#FF9126',
        brandingPrimaryDark: '#461A72',
        textPrimary: '#252525',
        textWhite: '#ffffff',
        textSecondary: '#64666B',
        textTertiary: '#767676',
        cardBg: '#FFFFFF',
        pageBg: '#F7F6F6',
        lightGreen: '#DCEDC8',
        amber: '#FFECB3',
        deepOrange: '#FFCCBC',
      },
    },
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
  },
})
