export default {
  position: 'top-right',
  duration: 3000,
  iconPack: 'mdi',
  action: {
    icon: 'mdi-close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
}
