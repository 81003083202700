<template>
  <div>
    <slot>
      <CustomBtnToggle
        :value="getTripType"
        data-test="tripTypeToggle"
        :disabled="getReservation.originalOutwardPrice !== null"
        :items="tripTypeItems"
        @change="setTripType"
      />
    </slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TripTypeEnum from '@slg/web-customer-shared/src/enum/TripTypeEnum';
import CustomBtnToggle from '@slg/web-customer-shared/src/components/common/CustomBtnToggle.vue';

export default {
  name: 'TripTypeSelection',
  components: {
    CustomBtnToggle,
  },
  data() {
    return {
      tripTypes: [TripTypeEnum.ONE_WAY, TripTypeEnum.ROUND_TRIP],
    }
  },
  computed: {
    ...mapGetters({
      getTripType: 'booking/getTripType',
    }),
    ...mapGetters('booking', ['getReservation']),
    tripTypeItems() {
      const availableTripTypes = this.tripTypes.map((trip) => (
        {
          text: this.$t(`bookingForm.rideDetails.${trip}`),
          value: trip,
        }
      ));
      return availableTripTypes;
    },
  },
  methods: {
    setTripType(newType) {
      this.$store.commit('booking/setTripType', newType);
      if (newType === this.tripTypes[0]) {
        this.$store.commit('booking/clearReturnForm');
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
