import SConstants from '@slg/web-customer-shared/src/utils/constants';
import * as STimeConverter from '@slg/web-customer-shared/src/utils/timeConverter';
import moment from 'moment';

const TIME_FORMAT = {
  MMM_DD_YYYY: 'MMM, DD, YYYY',
  MMM_DD_YYYY_HH_MM: 'MMM DD, YYYY, HH:mm',
  MMMM_DO_YYYY: 'MMMM Do, YYYY',
}

const convertDateToFormatWithYear = (time, date, lang = 'en') => `${time} ${moment(date).locale(lang)
  .format(TIME_FORMAT.MMM_DD_YYYY)}`;

const convertDateToDefaultMinTimeFormat = (minTime) => moment(minTime).format(SConstants.TIME_FORMAT.YYYY_MM_DD);

const subtractDateTime = (date, time, value, valueFormat) => moment(`${date} ${time}`).subtract(value, valueFormat);

const addDateTime = (date, time, value, valueFormat) => moment(`${date} ${time}`).add(value, valueFormat);

const bookingTableDate = (date) => moment(date).format(TIME_FORMAT.MMM_DD_YYYY_HH_MM);

const bookingsTableFilterDateFormat = (date) => moment(date).format(TIME_FORMAT.MMMM_DO_YYYY);

export default {
  ...STimeConverter.default,
  convertDateToFormatWithYear,
  convertDateToDefaultMinTimeFormat,
  subtractDateTime,
  addDateTime,
  bookingTableDate,
  bookingsTableFilterDateFormat,
}
