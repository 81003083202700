<template>
  <div>
    <VDialog
      v-if="isDialogOpen"
      :value="isDialogOpen"
      data-test="datesSelectionDialog"
      width="360"
      persistent
      outlined
    >
      <component
        :is="currentComponent"
        :outwardState="outwardState"
        :outwardTime="currentComponent === 'ReturnDateSelect' ? outwardState.time : returnTaxiState.time"
        :outwardDate="currentComponent === 'ReturnDateSelect' ? outwardState.date : returnTaxiState.date"
        :returnTaxiState="returnTaxiState"
        :returnState="returnState"
        :defaultMaxDate="getDefaultMaxDate"
        :defaultMinDate="getDefaultMinDate"
        :dateNow="dateNow"
        @setReturndDate="setReturndDate"
        @setReturndRouteTaxiDate="setReturndRouteTaxiDate"
        @closeDialog="closeDialog"
      />
    </VDialog>
    <ValidationObserver
      ref="bookingForm"
      name="bookingFormReturn"
      class="w-100"
      tag="form"
    >
      <div @click.stop="getReservation.originalOutwardPrice === null && openDialog('ReturnDateSelect')">
        <ValidationProvider
          v-slot="{ errors }"
          ref="return"
          name="return"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            v-model="returnDate"
            class="pb-6 pt-0 mt-0"
            data-test="returnDateField"
            :disabled="getReservation.originalOutwardPrice !== null"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${$t('bookingForm.outward.flightDate')} *`"
            prepend-icon="mdi-calendar"
            readonly
          />
        </ValidationProvider>
      </div>
      <div @click.stop="getReservation.originalOutwardPrice === null && openDialog('ReturnTaxiDateSelect')">
        <ValidationProvider
          v-slot="{ errors }"
          ref="returntaxi"
          name="returntaxi"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            v-model="returnTaxiDate"
            class="pb-6 pt-0 mt-0"
            data-test="returnTaxiDateField"
            :disabled="getReservation.originalOutwardPrice !== null"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${isPickUpRide ? $t('bookingForm.outward.dropOff') : $t('bookingForm.outward.pickUp')} *`"
            prepend-icon="mdi-calendar"
            readonly
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="returnflight"
          name="returnflight"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            :value="returnFlightNumber"
            class="pb-6 pt-0 mt-0"
            data-test="returnFlightField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${$t('bookingForm.outward.flightNumber')} *`"
            @input="setFlightNumber"
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="returncomment"
          name="returncomment"
          mode="lazy"
          :rules="{ required: false }"
        >
          <VTextField
            :value="returnComment"
            class="pb-6 pt-0 mt-0"
            data-test="returnCommentField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="$t('bookingForm.outward.commentReturn')"
            @input="setComment"
          />
        </ValidationProvider>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import moment from 'moment';
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import STimeConverter from '@slg/web-customer-shared/src/utils/timeConverter';
import { mapGetters, mapState } from 'vuex';
import TimeConverter from '@/utils/timeConvertor/timeConverter';
import ReturnDateSelect from './returnDateSelect/ReturnDateSelect.vue';
import ReturnTaxiDateSelect from './ReturnTaxiDateSelect/ReturnTaxiDateSelect.vue';

export default {
  name: 'ReturnTrip',
  components: {
    ReturnDateSelect,
    ReturnTaxiDateSelect,
  },
  data() {
    return {
      isDialogOpen: false,
      currentComponent: null,
      dateNow: null,
      maxNumberOfDaysForFutureBooking: 365,
    };
  },
  computed: {
    ...mapGetters('booking', ['getOutwardForm', 'isPickUpRide', 'getReturnForm', 'getReservation']),
    ...mapState('booking', ['returnForm']),
    outwardState() {
      return this.getOutwardForm.outwardDate;
    },
    returnTaxiState() {
      return this.getReturnForm.routeTaxiDate;
    },
    returnState() {
      return this.getReturnForm.returnDate;
    },
    outwardDate() {
      if (this.outwardState.date && this.outwardState.time) {
        return TimeConverter.convertDateToFormatWithYear(this.outwardState.time, this.outwardState.date, this.$route.params.lang);
      }
      return '';
    },
    returnDate() {
      if (this.returnState.date && this.returnState.time) {
        return TimeConverter.convertDateToFormatWithYear(this.returnState.time, this.returnState.date, this.$route.params.lang);
      }
      return '';
    },
    returnTaxiDate() {
      if (this.returnTaxiState.date && this.returnTaxiState.time) {
        return TimeConverter.convertDateToFormatWithYear(this.returnTaxiState.time, this.returnTaxiState.date, this.$route.params.lang);
      }
      return '';
    },
    returnFlightNumber() {
      return this.returnForm.flightNumber || '';
    },
    returnComment() {
      return this.returnForm.comment || '';
    },
    getDefaultMaxDate() {
      const maxDate = this.dateNow.clone();
      return maxDate
        .add(this.maxNumberOfDaysForFutureBooking, 'days')
        .format(SConstants.TIME_FORMAT.YYYY_MM_DD);
    },
    getDefaultMinDate() {
      return this.dateNow.format(SConstants.TIME_FORMAT.YYYY_MM_DD);
    },
  },
  created() {
    this.dateNow = moment();
    if (
      !Number.isNaN(
        STimeConverter.convertStringToMilliseconds(
          this.outwardState.date,
          this.outwardState.time,
        ),
      )
      && this.dateNow.valueOf()
      >= STimeConverter.convertStringToMilliseconds(
        this.outwardState.date,
        this.outwardState.time,
      )
    ) {
      this.$emit('clearDates');
    }
  },
  methods: {
    openDialog(name) {
      this.dateNow = moment();
      this.currentComponent = name;
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    setReturndDate(returnDate) {
      this.$store.commit('booking/setReturnDate', returnDate.date);
      this.$store.commit('booking/setReturnTime', returnDate.time);
      this.$store.commit('booking/generateReturnRouteTaxiDate');
    },
    setReturndRouteTaxiDate(returndRouteTaxiDate) {
      this.$store.commit('booking/setReturnTaxiDate', returndRouteTaxiDate.date);
      this.$store.commit('booking/setReturnTaxiTime', returndRouteTaxiDate.time);
    },
    setComment(data) {
      this.$store.commit('booking/setReturnComment', data);
    },
    setFlightNumber(data) {
      this.$store.commit('booking/setReturnFlightNumber', data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
