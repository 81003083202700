<template>
  <div>
    <VDialog
      v-if="isDialogOpen"
      :value="isDialogOpen"
      width="1020"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      :contentClass="$vuetify.breakpoint.xs ? 'direction-dialog' : ''"
      outlined
      :scrollable="$vuetify.breakpoint.xs"
    >
      <DirectionDialog
        ref="directionDialog"
        data-test="directionSelectionDialog"
        :openFrom="currentStep"
        :currentStep="currentStep"
        :isLoading="isLoading"
        :stepsMeta="stepsMeta"
        :getArrivalPlace="getArrivalPlace"
        :getDeparturePlace="getDeparturePlace"
        :getIsArrivalPlaceFilled="getIsArrivalPlaceFilled"
        :getIsDeparturePlaceFilled="getIsDeparturePlaceFilled"
        :poiList="poiList"
        :error="error"
        :poiListWithoutGrouping="poiListWithoutGrouping"
        :isArrivalPlacePoi="isArrivalPlacePoi"
        :isDeparturePlacePoi="isDeparturePlacePoi"
        @changeStep="changeStep"
        @setLocation="setLocation"
        @loadState="loadState"
        @closeDialog="closeDialog"
      />
    </VDialog>
    <ValidationObserver
      ref="directionSelection"
      name="directionSelection"
    >
      <VTextField
        v-if="getIsMidocoBooking"
        :value="orderId"
        class="cursor-pointer_input pt-0 pb-0 pointer-events_none"
        :label=" $t('bookingForm.rideDetails.orderId')"
        disabled
      />
      <div @click.stop="getReservation.originalOutwardPrice === null && openPickUpDialog()">
        <ValidationProvider
          v-slot="{ errors }"
          ref="departure"
          tag="div"
          name="departure"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            :value="getDeparturePlace.poiName || getDeparturePlace.address"
            clear-icon="mdi-close-circle from"
            clearable
            :disabled="getReservation.originalOutwardPrice !== null"
            class="cursor-pointer_input pt-0"
            data-test="fromField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${$t('bookingForm.rideDetails.pickUpLocation')} *`"
            @click:clear="clearDeparturePlaceField"
          >
            <template #prepend>
              <VIcon v-if="getDeparturePlaceIcon && getDeparturePlaceIcon.includes('mdi')">
                {{ getDeparturePlaceIcon }}
              </VIcon>
              <div
                v-else-if="getDeparturePlaceIcon"
                class="img-wrapper"
              >
                <img
                  :src="require(`@slg/web-customer-shared/src/assets/img/${getDeparturePlaceIcon}.svg`)"
                  alt="place"
                >
              </div>
            </template>
          </VTextField>
        </ValidationProvider>
      </div>
      <VBtn
        v-if="getIsDeparturePlaceFilled && getIsArrivalPlaceFilled"
        v-blur
        :disabled="getReservation.originalOutwardPrice !== null"
        data-test="swapLocationsButton"
        class="swap-button mx-2 grey-lighten-4 box-shadow-elevation-12"
        :style="getSwapButtonStyles"
        @click="swapLocations"
      >
        <VIcon
          color="textSecondary"
        >
          mdi-swap-vertical
        </VIcon>
      </VBtn>
      <div @click.stop="getReservation.originalOutwardPrice === null && openDropOffDialog()">
        <ValidationProvider
          v-slot="{ errors }"
          ref="arrival"
          tag="div"
          name="arrival"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            :value="getArrivalPlace.poiName || getArrivalPlace.address"
            class="cursor-pointer_input pt-0 pb-5"
            clear-icon="mdi-close-circle to"
            data-test="toField"
            :disabled="getReservation.originalOutwardPrice !== null"
            clearable
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="`${$t('bookingForm.rideDetails.dropOffLocation')} *`"
            @click:clear="clearArrivalPlaceField"
          >
            <template #prepend>
              <VIcon v-if="getArrivalPlaceIcon && getArrivalPlaceIcon.includes('mdi')">
                {{ getArrivalPlaceIcon }}
              </VIcon>
              <div v-else-if="getArrivalPlaceIcon">
                <img
                  :src="require(`@slg/web-customer-shared/src/assets/img/${getArrivalPlaceIcon}.svg`)"
                  alt="place"
                >
              </div>
            </template>
          </VTextField>
        </ValidationProvider>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getIconNameForBookingFromFromMarkerType } from '@slg/web-customer-shared/src/utils/iconResolver';
import PointOfInterstGroupManagement from '@/services/PointOfInterstGroupManagement';
import RetailerUserService from '@/services/RetailerUserService';
import GlobalErrorDialogMixin from '@slg/web-customer-shared/src/mixins/GlobalErrorDialogMixin';
import { generatePoiList } from './sDirectionSelection.logic';
import DirectionDialog from './directionSelectionDialog/DirectionDialog.vue';

export default {
  name: 'DirectionSelection',
  components: {
    DirectionDialog,
  },
  mixins: [GlobalErrorDialogMixin],
  data() {
    return {
      error: '',
      isLoading: false,
      currentStep: '',
      stepsMeta: {
        pickUp: 'pickUp',
        dropOff: 'dropOff',
      },
      retailerPartner: null,
      poiList: {},
      poiListWithoutGrouping: [],
      isDialogOpen: false,
    };
  },
  computed: {
    ...mapState('booking', ['orderId']),
    ...mapGetters('booking', ['getIsMidocoBooking']),
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
    }),
    ...mapGetters({
      getArrivalPlaceCreate: 'booking/getArrivalPlace',
      getDeparturePlaceCreate: 'booking/getDeparturePlace',
      getIsArrivalPlaceFilledCreate: 'booking/isArrivalPlaceFilled',
      getIsDeparturePlaceFilledCreate: 'booking/isDeparturePlaceFilled',
      getArrivalFirstSelectedCreate: 'booking/getArrivalFirstSelected',
      getDepartureFirstSelectedCreate: 'booking/getDepartureFirstSelected',
      isOutwardDateFilledCreate: 'booking/isOutwardDateFilled',
      isRoundTripCreate: 'booking/isRoundTrip',
      isReturnDateFilledCreate: 'booking/isReturnDateFilled',
      isArrivalPlacePoiCreate: 'booking/isArrivalPlacePoi',
      isDeparturePlacePoiCreate: 'booking/isDeparturePlacePoi',
    }),
    ...mapGetters({
      getArrivalPlaceEdit: 'bookingEdit/getArrivalPlace',
      getDeparturePlaceEdit: 'bookingEdit/getDeparturePlace',
      getIsArrivalPlaceFilledEdit: 'bookingEdit/isArrivalPlaceFilled',
      getIsDeparturePlaceFilledEdit: 'bookingEdit/isDeparturePlaceFilled',
      getArrivalFirstSelectedEdit: 'bookingEdit/getArrivalFirstSelected',
      getDepartureFirstSelectedEdit: 'bookingEdit/getDepartureFirstSelected',
      isOutwardDateFilledEdit: 'bookingEdit/isOutwardDateFilled',
      isRoundTripEdit: 'bookingEdit/isRoundTrip',
      isReturnDateFilledEdit: 'bookingEdit/isReturnDateFilled',
      isArrivalPlacePoiEdit: 'bookingEdit/isArrivalPlacePoi',
      isDeparturePlacePoiEdit: 'bookingEdit/isDeparturePlacePoi',
    }),
    stateName() {
      return this.bookingEdit ? 'bookingEdit' : 'booking';
    },
    getArrivalPlace() {
      return this.bookingEdit ? this.getArrivalPlaceEdit : this.getArrivalPlaceCreate;
    },
    getDeparturePlace() {
      return this.bookingEdit ? this.getDeparturePlaceEdit : this.getDeparturePlaceCreate;
    },
    getIsArrivalPlaceFilled() {
      return this.bookingEdit ? this.getIsArrivalPlaceFilledEdit : this.getIsArrivalPlaceFilledCreate;
    },
    getIsDeparturePlaceFilled() {
      return this.bookingEdit ? this.getIsDeparturePlaceFilledEdit : this.getIsDeparturePlaceFilledCreate;
    },
    getArrivalFirstSelected() {
      return this.bookingEdit ? this.getArrivalFirstSelectedEdit : this.getArrivalFirstSelectedCreate;
    },
    getDepartureFirstSelected() {
      return this.bookingEdit ? this.getDepartureFirstSelectedEdit : this.getDepartureFirstSelectedCreate;
    },
    isOutwardDateFilled() {
      return this.bookingEdit ? this.isOutwardDateFilledEdit : this.isOutwardDateFilledCreate;
    },
    isRoundTrip() {
      return this.bookingEdit ? this.isRoundTripEdit : this.isRoundTripCreate;
    },
    isReturnDateFilled() {
      return this.bookingEdit ? this.isReturnDateFilledEdit : this.isReturnDateFilledCreate;
    },
    isArrivalPlacePoi() {
      return this.bookingEdit ? this.isArrivalPlacePoiEdit : this.isArrivalPlacePoiCreate;
    },
    isDeparturePlacePoi() {
      return this.bookingEdit ? this.isDeparturePlacePoiEdit : this.isDeparturePlacePoiCreate;
    },
    ...mapGetters('booking', ['getReservation']),
    getDeparturePlaceIcon() {
      return (
        this.getDeparturePlace.address
        && getIconNameForBookingFromFromMarkerType(this.getDeparturePlace.type)
      );
    },
    getArrivalPlaceIcon() {
      return (
        this.getArrivalPlace.address
        && getIconNameForBookingFromFromMarkerType(this.getArrivalPlace.type)
      );
    },
    getSwapButtonStyles() {
      return {
        height: '40px',
        minWidth: '40px',
      }
    },
  },
  watch: {
    '$route.params.lang': {
      handler() {
        this.poiList = generatePoiList();
      },
    },
  },
  created() {
    this.poiList = generatePoiList();
  },
  methods: {
    async loadState() {
      this.isLoading = true;
      try {
        const { data } = await RetailerUserService.getPartnerProfile();
        this.retailerPartner = data;
        await this.searchPois();
      } catch (error) {
        // await this.setGlobalError(error);
      }
      this.isLoading = false;
    },
    async changeStep({ step }) {
      if (this.currentStep === step) {
        return;
      }
      this.error = '';
      this.isLoading = true;
      this.currentStep = step;
      try {
        await this.searchPois();
      } catch (error) {
        await this.setGlobalError(error);
      }
      this.isLoading = false;
    },
    swapLocations() {
      const newDeparturePlace = { ...this.getArrivalPlace };
      const newArrivalPlace = { ...this.getDeparturePlace };
      this.$store.commit(`${this.stateName}/setDeparturePlace`, newDeparturePlace);
      this.$store.commit(`${this.stateName}/setArrivalPlace`, newArrivalPlace);
      this.generateOutwardRouteTaxiDateAction();
    },
    setLocation(data) {
      if (this.currentStep === this.stepsMeta.pickUp) {
        if (data.isPoi) {
          data.isFirstSelected = true;
          this.$store.commit(`${this.stateName}/setArrivalFirstSelected`, false);
        } else if (!data.isPoi) {
          data.isFirstSelected = false;
          this.$store.commit(`${this.stateName}/setArrivalFirstSelected`, true);
        }
        this.$store.commit(`${this.stateName}/setDeparturePlace`, data);
        if (!this.getIsArrivalPlaceFilled) {
          this.currentStep = this.stepsMeta.dropOff;
          this.populatePoiList();
          this.initializeMap();
        } else {
          this.closeDialog();
        }
      } else {
        if (data.isPoi) {
          data.isFirstSelected = true;
          this.$store.commit(`${this.stateName}/setDepartureFirstSelected`, false);
        } else if (!data.isPoi) {
          data.isFirstSelected = false;
          this.$store.commit(`${this.stateName}/setDepartureFirstSelected`, true);
        }
        this.$store.commit(`${this.stateName}/setArrivalPlace`, data)

        if (!this.getIsDeparturePlaceFilled) {
          this.currentStep = this.stepsMeta.pickUp;
          this.populatePoiList();
          this.initializeMap();
        } else {
          this.closeDialog();
        }
      }
      this.generateOutwardRouteTaxiDateAction();
    },
    clearPoiList() {
      Object.keys(this.poiList).forEach((key) => {
        this.poiList[key].items = [];
      });
    },
    initializeMap() {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs?.directionDialog?.$refs?.addressSelection?.$refs?.map?.initializeMap();
      }, 300)
    },
    populatePoiList(data) {
      this.clearPoiList();
      if (data) {
        this.poiListWithoutGrouping = data;
      }

      this.poiListWithoutGrouping.forEach((item) => {
        const stops = [];
        if (item?.pointsOfInterest) {
          const firstPermittedPoi = item.pointsOfInterest
            .find((poi) => !this.retailerPartner.restrictedTenants?.find((tenant) => tenant === poi.tenantShortName));
          if (firstPermittedPoi) {
            stops.push({
              name: item?.poiGroupName,
              address: firstPermittedPoi?.stops[0]?.address,
              lat: firstPermittedPoi.stops[0]?.latitude,
              lon: firstPermittedPoi?.stops[0]?.longitude,
              description: null,
              isPoiGroup: true,
              timeZone: firstPermittedPoi.timeZone,
              poiGroupId: item.poiGroupId,
              id: firstPermittedPoi?.stops[0]?.poiStopId,
              parentInd: firstPermittedPoi?.pointOfInterestId,
              type: item?.poiGroupType,
            });
          }
        } else if (!this.retailerPartner.restrictedTenants?.find((tenant) => tenant === item.tenantShortName)) {
          item.stops.forEach((stop) => {
            stops.push({
              name: item.name,
              address: stop.address,
              lat: stop.latitude,
              lon: stop.longitude,
              timeZone: item.timeZone,
              description: null,
              isPoiGroup: false,
              poiGroupId: null,
              id: stop.poiStopId,
              parentInd: item.pointOfInterestId,
              type: item?.type,
            })
          })
        }

        if (stops.length) {
          const type = item?.poiGroupType || item?.type;
          // eslint-disable-next-line no-unused-expressions
         this.poiList[type]?.items?.push({
           poiId: item?.pointsOfInterest ? item?.pointsOfInterest[0]?.pointOfInterestId : item.pointOfInterestId,
           name: item?.poiGroupName || item?.name,
           address: item?.pointsOfInterest ? item?.pointsOfInterest[0]?.stops[0]?.address : item.stops[0]?.address,
           deep: item?.stops?.length > 1,
           subItems: stops,
         });
        }
      });
    },
    async searchPois() {
      const { data: { pointOfInterestGroupList, pointOfInterestList } } = await PointOfInterstGroupManagement.getAllPois(null);
      const summarizedPoiList = [...pointOfInterestGroupList, ...pointOfInterestList];
      this.populatePoiList(summarizedPoiList);
    },
    setDeparturePlace(data) {
      this.$store.commit(`${this.stateName}/setDeparturePlace`, data);
      this.$emit('clearBookingRequestError');
    },
    setArrivalPlace(data) {
      this.$store.commit(`${this.stateName}/setArrivalPlace`, data);
      this.$emit('clearBookingRequestError');
    },
    clearBookingForm() {
      if (this.isOpsZoneSelected) {
        this.$store.commit(`${this.stateName}/clearDates`);
        this.$store.commit(`${this.stateName}/clearOptions`);
        this.$store.commit('opsZone/clearOpsZone');
      }
    },
    openPickUpDialog() {
      this.isDialogOpen = true;
      this.currentStep = this.stepsMeta.pickUp;
    },
    openDropOffDialog() {
      this.isDialogOpen = true;
      this.currentStep = this.stepsMeta.dropOff;
    },
    clearDeparturePlaceField() {
      this.$store.commit(`${this.stateName}/clearDeparturePlace`);
      this.generateOutwardRouteTaxiDateAction();
    },
    clearArrivalPlaceField() {
      this.$store.commit(`${this.stateName}/clearArrivalPlace`);
      this.generateOutwardRouteTaxiDateAction();
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    generateOutwardRouteTaxiDateAction() {
      if (this.isOutwardDateFilled) {
        this.$store.commit(`${this.stateName}/generateOutwardRouteTaxiDate`);
      }
      if (this.isRoundTrip && this.isReturnDateFilled) {
        this.$store.commit(`${this.stateName}/generateReturnRouteTaxiDate`);
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.swap-button {
  width: 40px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  right: 14%;
  top: 33%;
}

.pointer-events_none {
  pointer-events: none;
}
</style>
