var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepper-container d-flex align-items-center w-100",class:{
    'py-3': _vm.$vuetify.breakpoint.xs,
    'py-4': !_vm.$vuetify.breakpoint.xs
  }},[_vm._l((_vm.steps),function(step,index){return [(!_vm.$vuetify.breakpoint.xs || _vm.currentStep === step.value)?_c('div',{key:index,staticClass:"d-flex align-center step-item",class:{
        'active': _vm.currentStep === step.value || step.isComplete,
        'w-100': _vm.$vuetify.breakpoint.xs,
        'max-width-100': _vm.$vuetify.breakpoint.xs
      }},[_vm._t("default",function(){return [(index && !_vm.$vuetify.breakpoint.xs)?_c('VDivider',{staticClass:"stepper-divider"}):_vm._e(),_c('div',{staticClass:"step-body align-items-center px-3 d-flex cursor-pointer",on:{"click":function($event){return _vm.$emit('onStepClick', step.value)}}},[_c('div',{staticClass:"step-count"},[_c('span',{staticClass:"caption"},[(step.isComplete && _vm.currentStep !== step.value)?[_c('VIcon',{attrs:{"small":"","color":"textWhite"}},[_vm._v("mdi-check")])]:[_vm._v(" "+_vm._s(index + 1)+" ")]],2)]),_c('div',{staticClass:"ml-2 step-body-content"},[_c('div',{staticClass:"step-title body-1"},[_vm._v(" "+_vm._s(step.title)+" ")]),_c('div',{staticClass:"step-sub-title caption textTertiary--text text-ellipsis-2-line"},[_vm._v(" "+_vm._s(step.subTitle)+" ")])])])]})],2):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }