<template>
  <div class="auth-container d-flex align-items-center justify-content-center">
    <AuthForm />
  </div>
</template>

<script>
import AuthForm from '@/components/authForm/AuthForm.vue';

export default {
  name: 'AuthPage',
  components: {
    AuthForm,
  },
}
</script>

<style lang="scss" scoped>
.auth-container {
  background-image: url("../../assets/img/background-image.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 100vh;
  width: 100vw;
}
</style>
