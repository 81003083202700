<template>
  <CustomDateSelect
    :date="date"
    :time="time"
    :isDialogOpen="isDialogOpen"
    :minDate="defaultMinDate"
    :maxDate="returnSelectedFirst ? getReturnForm.returnDate.date : returnDate ? returnDate : defaultMaxDate"
    :minTime="getDefaultMinTime"
    :maxTime="isOutwardAndReturnSameDay ? getReturnForm.returnDate.time : null"
    :isOutward="true"
    @submitSelect="submitSelect"
    @closeDialog="closeDialog"
    @updateTime="updateTime"
    @updateDate="updateDate"
  />
</template>

<script>
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import CustomDateSelect from '@/components/common/CustomDateSelect.vue';
import TimeConverter from '@/utils/timeConvertor/timeConverter';
import { mapGetters } from 'vuex';

export default {
  name: 'OutwardDateSelect',
  components: {
    CustomDateSelect,
  },
  props: {
    outwardState: {
      type: Object,
      default: null,
    },
    returnTime: {
      type: String,
      default: null,
    },
    returnDate: {
      type: String,
      default: null,
    },
    defaultMaxDate: {
      type: String,
      default: null,
    },
    defaultMinDate: {
      type: String,
      default: null,
    },
    dateNow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      isOutwardNow: false,
      time: null,
      date: null,
    };
  },
  computed: {
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
    }),
    ...mapGetters({
      getReturnFormCreate: 'booking/getReturnForm',
      isRoundTripCreate: 'booking/isRoundTrip',
    }),
    ...mapGetters({
      getReturnFormEdit: 'bookingEdit/getReturnForm',
      isRoundTripEdit: 'bookingEdit/isRoundTrip',
    }),
    getReturnForm() {
      return this.bookingEdit ? this.getReturnFormEdit : this.getReturnFormCreate;
    },
    isRoundTrip() {
      return this.bookingEdit ? this.isRoundTripEdit : this.isRoundTripCreate;
    },
    isOutwardAndReturnSameDay() {
      return this.getReturnForm.returnDate.date === this.date && this.isRoundTrip;
    },
    getDefaultMinTime() {
      const minTime = this.dateNow.clone();
      return TimeConverter.convertDateToDefaultMinTimeFormat(minTime)
        === this.date
        ? minTime
          .add(this.minTimeBeforeBooking, 'minutes')
          .format(SConstants.TIME_FORMAT.HH_mm)
        : null;
    },
    returnSelectedFirst() {
      if (this.getReturnForm.returnDate.date && this.isRoundTrip) {
        return true;
      }
      return false;
    },
  },
  created() {
    const { date, time } = this.outwardState;
    this.date = date;
    this.time = time;
  },
  methods: {
    updateDate(newValue) {
      this.date = newValue;
    },
    updateTime(newValue) {
      this.time = newValue;
    },
    submitSelect() {
      const outwardDate = {
        date: this.date,
        time: this.time,
      };
      this.$emit('setOutwardDate', outwardDate);
      this.$emit('closeDialog');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
};
</script>
<style lang="scss" scoped></style>
