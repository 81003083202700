import PointOfInterstGroupManagement from '@/services/PointOfInterstGroupManagement';
import RetailerUserService from '@/services/RetailerUserService';

const generateStopsList = async (booking) => {
  let stops = [];
  const { data: { pointOfInterestGroupList, pointOfInterestList } } = await PointOfInterstGroupManagement.getAllPois(null);
  const response = await RetailerUserService.getPartnerProfile();
  const retailerPartner = response.data;

  if (booking.departurePlace?.poiGroupId) {
    const poiGroup = pointOfInterestGroupList.find((group) => group.poiGroupId === booking.departurePlace.poiGroupId);
    const poiGroupStops = poiGroup.pointsOfInterest
      .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
      .reduce((acc, poi) => {
        acc.push({
          address: poi.stops[0].address,
          latitude: poi.stops[0].latitude,
          longitude: poi.stops[0].longitude,
          poiStopId: poi.stops[0].poiStopId || null,
          stopNumber: 1,
          tenantShortName: poi?.tenantShortName || null,
          poiType: poi?.type || null,
          poiId: poi?.pointOfInterestId || null,
        })
        return acc;
      }, [])
    stops = [...stops, ...poiGroupStops];
  } else {
    let poi;
    if (booking.departurePlace?.poiStopId) {
      poi = pointOfInterestList
        .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
        .find((p) => p.stops.find((stop) => stop.poiStopId === booking.departurePlace.poiStopId))
    }
    stops.push({
      address: booking.departurePlace.address,
      latitude: booking.departurePlace.lat,
      longitude: booking.departurePlace.lon,
      poiStopId: booking.departurePlace.poiStopId || null,
      stopNumber: 1,
      tenantShortName: poi?.tenantShortName || null,
      poiType: poi?.type || null,
      poiId: poi?.pointOfInterestId || null,
    });
  }

  if (booking.arrivalPlace?.poiGroupId) {
    const poiGroup = pointOfInterestGroupList.find((group) => group.poiGroupId === booking.arrivalPlace.poiGroupId);
    const poiGroupStops = poiGroup.pointsOfInterest
      .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
      .reduce((acc, poi) => {
        acc.push({
          address: poi.stops[0].address,
          latitude: poi.stops[0].latitude,
          longitude: poi.stops[0].longitude,
          poiStopId: poi.stops[0].poiStopId || null,
          stopNumber: 2,
          tenantShortName: poi?.tenantShortName || null,
          poiType: poi?.type || null,
          poiId: poi?.pointOfInterestId || null,
        })
        return acc;
      }, [])
    stops = [...stops, ...poiGroupStops];
  } else {
    let poi;
    if (booking.arrivalPlace.poiStopId) {
      poi = pointOfInterestList
        .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
        .find((p) => p.stops.find((stop) => stop.poiStopId === booking.arrivalPlace.poiStopId))
    }

    stops.push({
      address: booking.arrivalPlace.address,
      latitude: booking.arrivalPlace.lat,
      longitude: booking.arrivalPlace.lon,
      poiStopId: booking.arrivalPlace.poiStopId || null,
      stopNumber: 2,
      tenantShortName: poi?.tenantShortName || null,
      poiType: poi?.type || null,
      poiId: poi?.pointOfInterestId || null,
    });
  }
  return stops;
}

export default {
  generateStopsList,
}
