import store from '@/store';
import UserRetailerTaskManagement from '@/services/UserRetailerTaskManagement';
import { STATUSES } from '@/utils/constants/asyncManagerStatuses';

const execute = async (task, successCallback = null, errorCallback = null, vm = null) => {
  const model = vm;
  setTimeout(async () => {
    const res = await UserRetailerTaskManagement.getResult(task.taskId);
    const { data } = res;
    const executionStage = data?.taskStatus?.executionStage || data?.executionStage;
    task.executionStage = executionStage;
    if (executionStage === STATUSES.SUCCESS) {
      store.commit('common/setGlobalLoading', false);
      if (successCallback) {
        successCallback(data, model);
      }
    } else if (executionStage === STATUSES.ERROR) {
      store.commit('common/setGlobalLoading', false);

      if (errorCallback) {
        errorCallback(data, model);
      }
    } else if (executionStage === STATUSES.PROCESSING || executionStage === STATUSES.PENDING) {
      execute(task, successCallback, errorCallback, model);
    }
  }, task.delay);
}

export { execute }
