<template>
  <div>
    <ValidationObserver
      ref="passengersSelection"
      v-slot="{ invalid }"
      name="passengerSelection"
      tag="div"
    >
      <div
        v-for="(item, index) in getItems"
        :key="`${item.refName} ${index}`"
        :ref="item.refName"
        :name="item.refName"
        tag="div"
      >
        <div
          class="d-flex justify-space-between px-0 align-items-center pb-4"
        >
          <div
            class="d-flex body-2 p-0"
          >
            <VIcon class="mr-2">
              {{ item.icon }}
            </VIcon>
            <div>
              <span class="body-1">{{ $t(item.nameKey) }} {{ (item.refName === 'adultsCounter') && '*' || '' }}</span>
            </div>
          </div>
          <VSpacer />
          <CustomCounter
            :amount="item.amount"
            :maxValue="item.maxValue"
            :dataTest="item.dataTest"
            :disabled="getReservation.originalOutwardPrice !== null"
            :validationProviderRef="item.refName"
            :hint="$t('validations.maxLimitForStandardSeats', { number: getMaximumPassengersPerBooking })"
            :rules="{
              atLeastOnePassenger: [item.adultsAmount, 0],
              amountOfPassengerIsValid: [item.adultsAmount, item.childrenAmount, item.maxStandardSeatCapacity],
            }"
            @updateAmount="item.updateAmountCallback"
          />
        </div>
      </div>
      <div
        v-if="invalid"
        class="mb-2"
      >
        <span class="caption error--text">
          {{ $t(getPassengersError, { number: getMaximumPassengersPerBooking }) }} </span>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomCounter from '@slg/web-customer-shared/src/components/common/CustomCounter.vue';

export default {
  name: 'PassengersSelection',
  components: {
    CustomCounter,
  },
  computed: {
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
    }),
    ...mapGetters({
      getAdultsCreate: 'booking/getAdults',
      getChildrenCreate: 'booking/getChildren',
      getReservationCreate: 'booking/getReservation',
      getMaximumPassengersPerBooking: 'settingsConfiguration/getMaximumPassengersPerBooking',
    }),
    ...mapGetters({
      getAdultsEdit: 'bookingEdit/getAdults',
      getChildrenEdit: 'bookingEdit/getChildren',
      getReservationEdit: 'bookingEdit/getReservation',
    }),
    stateName() {
      return this.bookingEdit ? 'bookingEdit' : 'booking';
    },
    getAdults() {
      return this.bookingEdit ? this.getAdultsEdit : this.getAdultsCreate;
    },
    getChildren() {
      return this.bookingEdit ? this.getChildrenEdit : this.getChildrenCreate;
    },
    getReservation() {
      return this.bookingEdit ? this.getReservationEdit : this.getReservationCreate;
    },
    getItems() {
      return [
        {
          refName: 'adultsCounter',
          dataTest: 'adultsCounter',
          maxStandardSeatCapacity: this.getMaximumPassengersPerBooking,
          maxValue: this.maxAdults,
          amount: this.getAdults,
          adultsAmount: this.getAdults,
          childrenAmount: this.getChildren,
          icon: 'mdi-account',
          nameKey: 'bookingForm.passengersAndServices.adults',
          isBookingFormNarrow: this.isBookingFormNarrow,
          className: '',
          updateAmountCallback: this.updateAdults,
        },
        {
          refName: 'childrenCounter',
          dataTest: 'childrenCounter',
          maxStandardSeatCapacity: this.getMaximumPassengersPerBooking,
          maxValue: this.maxChildren,
          amount: this.getChildren,
          adultsAmount: this.getAdults,
          childrenAmount: this.getChildren,
          icon: 'mdi-face-man',
          nameKey: 'bookingForm.passengersAndServices.children',
          isBookingFormNarrow: this.isBookingFormNarrow,
          className: '',
          updateAmountCallback: this.updateChildren,
        },
      ]
    },
    isBookingFormNarrow() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    maxAdults() {
      return this.getMaximumPassengersPerBooking - this.getChildren;
    },
    maxChildren() {
      return this.getMaximumPassengersPerBooking - this.getAdults;
    },
    getPassengersError() {
      const passengersSelectionObserver = this.$refs?.passengersSelection;
      const maxPassengersError = passengersSelectionObserver?.errors?.counteradultsCounter
        ?.find((message) => message === 'bookingForm.validations.maxLimitForStandardSeats') || null;
      const atLeastOnePassengersError = passengersSelectionObserver?.errors?.counteradultsCounter
        ?.find((message) => message === 'bookingForm.validations.noPassengers') || null;

      if (maxPassengersError) {
        return 'validations.maxLimitForStandardSeats';
      }

      if (atLeastOnePassengersError) {
        return 'validations.noPassengers'
      }

      return null;
    },
  },
  mounted() {
    if (this.getAdults === 0) {
      this.$store.commit(`${this.stateName}/setAdults`, 1);
    }
  },
  methods: {
    updateChildren(val) {
      this.$nextTick(async () => {
        await this.$store.commit(`${this.stateName}/setChildren`, val);
        this.$refs.passengersSelection.validate();
      })
    },
    updateAdults(val) {
      this.$nextTick(async () => {
        await this.$store.commit(`${this.stateName}/setAdults`, val);
        this.$refs.passengersSelection.validate();
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
