<template>
  <div class="no-data">
    <img :src="require(`@/assets/img/no-data-bookings.svg`)">
    <div class="no-data-title">
      <span>{{ $t('bookingsTable.noBookingsToDisplay') }}</span> {{ $t('bookingsTable.tryToChangeSearch') }}
    </div>
    <VBtn
      v-if="!getIsMidocoAuth"
      class="w-20 border-radius-8 mr-6 clear-button p-0"
      dark
      large
      variant="outlined"
      color="pageBg"
      @click="clearFilters"
    >
      <router-link
        to="booking"
        class="create-booking"
      >
        <VIcon color="brandingPrimary">
          mdi-plus
        </VIcon>
        <span
          class="button brandingPrimary--text"
        >
          {{ $t('bookingsTable.createNewBooking') }}
        </span>
      </router-link>
    </VBtn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BookingsTableNoData',
  computed: {
    ...mapGetters('auth', ['getIsMidocoAuth']),
  },
  methods: {
    clearFilters() {
      this.$store.commit('bookingsTable/clearFilters');
    },
  },
}
</script>

<style lang="scss" scoped>
.create-booking {
 display: flex;
 padding: 0 20px;
 text-decoration: none;
}
</style>
