import { axios } from '@slg/web-customer-shared';
import store from '@/store';

const whiteList = [
  'maps.googleapis.com',
  'apiv3.geoportail.lu',
];

// eslint-disable-next-line no-undef
const clusterHost = config.VUE_APP_CLUSTER_HOST || process.env.VUE_APP_CLUSTER_HOST;

export default function setup() {
  axios.interceptors.request.use(
    (axiosConfig) => {
      if (whiteList.some((item) => (axiosConfig.url || '').includes(item))) {
        return axiosConfig;
      }
      // eslint-disable-next-line no-undef
      const baseUrl = config.VUE_APP_BACKEND_URL || process.env.VUE_APP_BACKEND_URL;
      // eslint-disable-next-line no-undef
      const backendClusterHost = config.VUE_APP_BACKEND_CLUSTER_URL || process.env.VUE_APP_BACKEND_CLUSTER_URL;

      const { hostname } = new URL(window.location.href);

      axiosConfig.baseURL = hostname === clusterHost ? backendClusterHost : baseUrl;
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        axiosConfig.headers.authorization = `Bearer ${authToken}`;
      }
      return axiosConfig;
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    ({ response }) => {
      const { data, status } = response || {};
      const { errorCode, message, errorDetails } = data || {};
      const dataToThrow = {
        message,
        errorCode,
        errorDetails,
        status,
      }
      const isMidocoAuth = store.getters['auth/getIsMidocoAuth'];
      if (status === 401 && !isMidocoAuth) {
        store.commit('common/changeSessionExpiredDialogOpenState', true);
      } else if (status === 401 && isMidocoAuth) {
        store.commit('common/changeMidocoAuthFailedState', true);
      }
      return Promise.reject(dataToThrow);
    },
  );
}
