<template>
  <VDialog
    ref="cancelBookingDialog"
    v-model="cancelBookingDialog"
    width="425"
    persistent
    outlined
  >
    <VCard>
      <VCardTitle class="pb-0">
        <span class="heading-6">
          {{ $t('bookingsTable.cancelTitle') }}
        </span>
      </VCardTitle>
      <VCardText class="pb-0">
        <div class="pt-6">
          <v-btn
            class="cancel-booking-close"
            fab
            color="greyLight"
            small
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <span class="body-1 textPrimary--text">
            {{ $t('bookingsTable.cancelText',
                  { first_name: booking.firstName,
                    last_name: booking.lastName,
                    trip_date: moment(booking.tripDate).format(SConstants.TIME_FORMAT.YYYY_MM_DD)
                  }
            ) }}
          </span>
        </div>
      </VCardText>
      <VCardActions class="pb-4 pt-6 pr-6">
        <VBtn
          class="shadow-none border-radius-8 w-50"
          color="textTertiary"
          outlined
          dark
          @click="closeDialog"
        >
          <span class="button">
            {{ $t('bookingsTable.no') }}
          </span>
        </VBtn>
        <VBtn
          class="shadow-none border-radius-8 w-50"
          color="error"
          dark
          @click="cancelBooking(booking)"
        >
          <span class="button">
            {{ $t('bookingsTable.yes') }}
          </span>
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import UserRetailerBookingsManagement from '@/services/UserRetailerBookingsManagement';
import { execute } from '@/utils/asyncTaskManagement';

export default {
  name: 'AccessDeniedDialog',
  data() {
    return {
      moment,
      SConstants,
    }
  },
  computed: {
    ...mapState('bookingsTable', ['cancelBookingDialog', 'bookingForCancel']),
    getIsSessionExpiredDialogOpen() {
      return this.$store.getters['common/getIsSessionExpiredDialogOpen'];
    },
    booking() {
      return this.bookingForCancel;
    },
  },
  methods: {
    ...mapActions('bookingsTable', ['getBookings']),
    closeDialog() {
      this.$store.commit('bookingsTable/updateCancelBookingDialog', false);
      this.$store.commit('bookingsTable/updateBookingForCancel', null);
    },
    async cancelBooking(booking) {
      this.$store.commit('common/setGlobalLoading', true, { root: true });
      try {
        const task = {
          executionStage: 'PROCESSING',
          delay: 5000,
        }
        const id = booking.retailerBookingId;
        const { data: { taskId } } = await UserRetailerBookingsManagement.cancelBooking(id);
        task.taskId = taskId;
        await execute(task, this.onSuccessCancelBooking, this.onErrorCancelBooking, this);
      } catch (e) {
        this.$toasted.error(this.$i18n.t('general.somethingWentWrong'))
      }
    },
    onSuccessCancelBooking() {
      this.$store.commit('bookingsTable/updateCancelBookingDialog', false);
      this.$toasted.success(this.$t('bookingsTable.successfullyCancelled'));
      this.$store.commit('common/setGlobalLoading', false, { root: true });
      this.getBookings();
    },
    onErrorCancelBooking() {
      this.$store.commit('bookingsTable/updateCancelBookingDialog', false);
      this.$toasted.error(this.$t('general.somethingWentWrong'));
      this.$store.commit('common/setGlobalLoading', false, { root: true });
      this.getBookings();
    },
  },
}
</script>

<style scoped>
::v-deep .greyLight i {
  color: #252525 !important;
}

.cancel-booking-close{
  position: absolute;
  right: 16px;
  top: 16px;
}
</style>
