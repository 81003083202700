const state = () => ({
  bookings: [],
  offset: 0,
  limit: 5,
  totalCount: 0,
  loading: false,
  cancelBookingDialog: false,
  editBookingDialog: false,
  sortField: 'tripDate',
  sortParams: {
    ignoreCaseSort: true,
    descending: true,
  },
  bookingForEdit: null,
  bookingForCancel: {
    firstName: '',
    lastName: '',
    tripDate: '',
    retailerBookingId: '',
  },
  originalBookingDetails: null,
  numericFilters: [],
  stringFilters: [],
});

export default state;
