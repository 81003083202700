import { axios } from '@slg/web-customer-shared';

const apiDomain = 'abs-retailer/api/v1/retailer/bookings';

const createBooking = (model) => axios.post(`${apiDomain}/create`, model);

const calculatePrice = (model) => axios.post(`${apiDomain}/price`, model);

const createAwaitingBooking = (model) => axios.post(`${apiDomain}/awaiting/create`, model);

export default { createBooking, calculatePrice, createAwaitingBooking }
