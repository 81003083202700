import Vue from 'vue';
import Toasted from 'vue-toasted';
import vuetify from '@/plugins/vuetify';
// eslint-disable-next-line import/extensions
import VueMoment from 'vue-moment';
import vueDebounce from 'vue-debounce'
import VueBraintree from 'vue-braintree';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import {
  SRestrict, SBlur, SValidationComponents, SPhoneRestrict,
} from '@slg/web-customer-shared';
import i18n from './plugins/i18n';
import '@slg/web-customer-shared/src/utils/validations';
import router from './router/index';
import axiosInterceptorsSetup from './utils/axiosInterceptors';
import App from './App.vue';
import store from './store';
import styles from './style/main.scss';
import 'leaflet/dist/leaflet.css';
import './utils/validations';
import toastsOptions from './utils/toasts';

Vue.use(Toasted, toastsOptions);

Vue.component('ValidationObserver', SValidationComponents.ValidationObserver);
Vue.component('ValidationProvider', SValidationComponents.ValidationProvider);

Vue.directive('restrict', SRestrict);
Vue.directive('blur', SBlur);
Vue.directive('phone-restrict', SPhoneRestrict);

Vue.use(VueBraintree);
Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VueMoment);
Vue.use(vueDebounce);

axiosInterceptorsSetup();

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  styles,
  render: (h) => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.app = app;
}
