<template>
  <VDialog
    ref="editBookingDialog"
    v-model="editBookingDialog"
    width="798"
    persistent
    outlined
    :disabled="true"
  >
    <VCard>
      <VCardTitle class="pb-0">
        <span class="heading-6">
          {{ $t('bookingsTable.editBooking') }}
        </span>
      </VCardTitle>
      <ValidationObserver
        ref="bookingEditForm"
        name="bookingEditForm"
        tag="form"
      >
        <VCardText class="pb-0">
          <div class="pt-6">
            <v-btn
              class="cancel-booking-close"
              fab
              color="greyLight"
              small
              @click="closeDialog"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <div class="body-1">
              <VCard
                class="p-0 pt-2 direction-selection-card direction-card-edit"
                :disabled="bookingStatus === BOOKING_STATUSES.BOOKED"
                :class="{ disabledDirection: bookingStatus === BOOKING_STATUSES.BOOKED }"
              >
                <RideDetails
                  :showTripType="false"
                  :showRideType="false"
                />
              </VCard>
              <div class="pt-1 d-flex justify-content-between align-items-start">
                <VCard class="edit-booking-date-select p-0 direction-selection-card pr-5">
                  <div
                    class="heading-5 pb-8 disabled-header"
                    :class="{ disabled: bookingStatus === BOOKING_STATUSES.BOOKED }"
                  >
                    {{ $t('bookingsTable.bookingDateAndTime') }}
                  </div>
                  <OutwardTrip
                    :status="bookingStatus"
                    :commentLabel="bookingForEdit.round ? $t('bookingForm.outward.commentReturn') : $t('bookingForm.outward.comment')"
                  />
                </VCard>
                <VCard
                  class="edit-booking-date-select p-0 direction-selection-card pl-5"
                  :disabled="bookingStatus === BOOKING_STATUSES.BOOKED"
                >
                  <div class="heading-5 pb-8">
                    {{ $t('bookingForm.passengersAndServices.title') }}
                  </div>
                  <PassengersAndServices />
                </VCard>
              </div>
              <div class="pt-1 d-flex justify-content-between align-items-start">
                <VCard class="edit-booking-date-select p-0 direction-selection-card pr-5">
                  <div class="heading-5 pb-8">
                    {{ $t('bookingForm.passenger.title') }}
                  </div>
                  <PassengerInfo />
                </VCard>
              </div>
            </div>
          </div>
        </VCardText>
      </ValidationObserver>
      <VCardActions class="pb-4pt-6 pr-6">
        <VBtn
          class="shadow-none border-radius-8 w-50"
          color="textTertiary"
          outlined
          dark
          @click="closeDialog"
        >
          <span class="button">
            {{ $t('bookingsTable.cancel') }}
          </span>
        </VBtn>
        <VBtn
          class="shadow-none border-radius-8 w-50"
          color="brandingPrimary"
          dark
          @click="saveBooking()"
        >
          <span class="button">
            {{ $t('bookingsTable.save') }}
          </span>
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import RideDetails from '@/components/bookingForm/rideDetails/RideDetails.vue';
import OutwardTrip from '@/components/bookingForm/outwardTrip/OutwardTrip.vue';
import PassengersAndServices from '@/components/bookingForm/passengersAndServices/PassengersAndServices.vue';
import PassengerInfo from '@/components/bookingForm/passengerInfo/PassengerInfo.vue';
import { BOOKING_STATUSES } from '@/utils/constants/booking';
// import UserRetailerBookingsManagement from '@/services/UserRetailerBookingsManagement';
// import { execute } from '@/utils/asyncTaskManagement';

export default {
  name: 'EditBookingDialog',
  components: {
    RideDetails,
    OutwardTrip,
    PassengersAndServices,
    PassengerInfo,
  },
  data() {
    return {
      BOOKING_STATUSES,
      SConstants,
    }
  },
  computed: {
    ...mapState('bookingsTable', ['editBookingDialog', 'bookingForEdit']),
    getIsSessionExpiredDialogOpen() {
      return this.$store.getters['common/getIsSessionExpiredDialogOpen'];
    },
    bookingStatus() {
      return this.bookingForEdit?.bookingStatus;
    },
  },
  methods: {
    ...mapActions('bookingsTable', ['getBookings', 'getBooking', 'updateBooking']),
    closeDialog() {
      this.$store.commit('bookingsTable/updateEditBookingDialog', false);
      this.$store.commit('bookingsTable/updateBookingForEdit', null);
      this.$store.commit('bookingEdit/clearBookingState');
    },
    saveBooking() {
      this.$refs.bookingEditForm.validate().then(async (success) => {
        if (!success) {
          return
        }
        this.$store.commit('common/setGlobalLoading', true);
        this.updateBooking().then(() => {
          this.onSuccessUpdateBooking();
        }).catch(() => {
          this.onErrorUpdateBooking();
        });
      })
    },
    onSuccessUpdateBooking() {
      this.closeDialog();
      this.$toasted.success(this.$t('bookingsTable.successfullyEdited'));
      this.$store.commit('common/setGlobalLoading', false, { root: true });
      this.getBookings();
    },
    onErrorUpdateBooking() {
      this.closeDialog();
      this.$toasted.error(this.$t('general.somethingWentWrong'));
      this.$store.commit('common/setGlobalLoading', false, { root: true });
      this.getBookings();
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .greyLight i {
  color: #252525 !important;
}
.cancel-booking-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
::v-deep .swap-button {
    top: 25% !important;
}
::v-deep .direction-card-edit {
    top: 25% !important;
    box-shadow: none !important;
}
::v-deep .direction-card-edit.disabledDirection label{
    pointer-events: none;
}

::v-deep .edit-booking-date-select {
  box-shadow: none !important;
  width: 50%;
}

.v-application .disabled-header.disabled {
  box-shadow: none !important;
  background: none !important;
  opacity: .6;
}
</style>
