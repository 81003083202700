import PlatformSettingsService from '@/services/PlatformSettingsService';

export default {
  namespaced: true,
  state: {
    enabledFeatures: [],
  },
  mutations: {
    setEnabledFeatures(state, enabledFeatures) {
      state.enabledFeatures = enabledFeatures;
    },
  },
  actions: {
    async getAllEnabledFeatures({ commit }) {
      const { data: { enabledFeatures } } = await PlatformSettingsService.getFeatureFlags();
      commit('setEnabledFeatures', enabledFeatures);
    },
  },
  getters: {
    getEnabledFeatures: (state) => state.enabledFeatures,
  },
}
