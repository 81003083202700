import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import BookingPage from '@/components/pages/BookingPage.vue';
import AuthPage from '@/components/pages/AuthPage.vue';
import BookingsListPage from '@/components/pages/bookingsListPage/BookingsListPage.vue';
import TheMainContent from '@/components/TheMainContent.vue';
import { getQuery } from 'queryzz';
import appConfig from '@/appConfig';
import store from '@/store';

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    const el = document.getElementById('main-container');
    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
  },
  routes: [
    {
      path: '/:lang',
      component: TheMainContent,
      redirect: (to) => ({ name: 'Booking', params: { lang: to.params.lang } }),
      children: [
        {
          path: 'booking',
          name: 'Booking',
          meta: { auth: true },
          component: BookingPage,
        },
        {
          path: 'extended',
          name: 'Booking',
          meta: { auth: true },
          component: BookingPage,
        },
        {
          path: 'login',
          name: 'Login',
          component: AuthPage,
        },
        {
          path: 'bookings-list',
          name: 'Bookings list',
          component: BookingsListPage,
        },
      ],
    },
    {
      path: '*',
      redirect: { name: 'Booking', params: { lang: 'en' } },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const queryParams = getQuery();
  // eslint-disable-next-line camelcase
  const { auth_token } = queryParams;

  if (!['en', 'de', 'fr'].includes(to.params.lang)) {
    to.params.lang = 'en';
  }

  // eslint-disable-next-line camelcase
  if (auth_token) {
    store.commit('auth/clearAuthTokenAndProfileInfo');
    store.commit('auth/setAuthToken', auth_token);
    store.commit('auth/setIsMidocoAuth', true);
    store.commit('booking/clearBookingState');
  } else {
    // eslint-disable-next-line camelcase
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      try {
        const isMidocoAuth = JSON.parse(localStorage.getItem('isMidocoAuth'));
        store.commit('auth/setIsMidocoAuth', isMidocoAuth);
        store.commit('auth/setAuthToken', authToken);
        await store.dispatch('auth/populateUserProfileInfo');
      } catch (e) {
        store.commit('auth/clearAuthTokenAndProfileInfo');
      }
    }
  }

  const lang = from.params.lang || to.params.lang;
  const isUserLogged = store.getters['auth/getIsAuth'];

  if (to.meta.auth) {
    if (isUserLogged) {
      next();
    } else {
      next({ name: 'Login', params: { lang: lang || appConfig.defaultLanguage } })
    }
    return;
  }

  if (store.getters['common/isGlobalLoading']) {
    store.commit('common/setGlobalLoading', false);
  }

  if (to.name !== 'Booking') {
    if (isUserLogged && !to.params?.isErrorBookingConfirmation) {
      try {
        await store.dispatch('booking/cancelTemporaryReservation');
      } catch (e) {
        // todo
      }
    }
  }

  if (to.name === 'Confirmation') {
    // store.commit('booking/clearTemporaryReservation');
  }

  next();
  // if (!appConfig.languages.includes(lang)) {
  //   next({ name: to.name, params: { lang: from.params.lang || appConfig.defaultLanguage } });
  // } else {
  //   next();
  // }
})

export default router;
