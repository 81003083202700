<template>
  <div>
    <ValidationObserver
      ref="passangerInfo"
      name="passangerInfo"
      class="breakpoint-xl"
    >
      <div>
        <ValidationProvider
          ref="passangerPhone"
          v-slot="{ errors }"
          name="passangerPhone"
          :rules="{ required: true, phoneNumber: { isValidPhoneNumber } }"
          :allowFalse="false"
          mode="eager"
        >
          <vue-tel-input-vuetify
            ref="phonePassengerInput"
            v-model="phoneNumber"
            v-phone-restrict
            class="body-1 pb-0 "
            :class="{ 'mb-5': !!errors.length, }"
            :loading="isLoadingCountries"
            data-test="passengerPhoneNumber"
            defaultCountry="LU"
            disabledFetchingCountry
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :placeholder="$t('bookingForm.passenger.enterPhoneNumber')"
            :mode="'international'"
            :label="`${$t('bookingForm.passenger.phone')} *`"
            @input="validatePhone"
            @country-changed="(val) => selectCountry(val)"
          />
        </validationprovider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="passengerFirstName"
          name="passengerFirstName"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            :value="passengerFirstName"
            class="pb-6 pt-0 mt-0"
            data-test="passengerFirstNameField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="$t('bookingForm.passenger.firstName')"
            @input="setFirstName"
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="passengerLastName"
          name="passengerLastName"
          mode="lazy"
          :rules="{ required: true }"
        >
          <VTextField
            :value="passengerLastName"
            class="pb-6 pt-0 mt-0"
            data-test="passengerLastNameField"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="$t('bookingForm.passenger.lastName')"
            @input="setLastName"
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          ref="passengerLanguage"
          name="passengerLanguage"
          mode="lazy"
        >
          <v-select
            class="pb-0"
            :items="languages"
            data-test="passengerLanguageDropdown"
            :label="$t('bookingForm.passenger.customerLanguage')"
            return-object
            item-text="name"
            :value="passengerLanguage"
            @change="setLanguage"
          />
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider
          v-slot="{ errors }"
          ref="passengerEmail"
          name="passengerEmail"
          mode="lazy"
          :rules="{ email: true, required: !!passengerEmail }"
        >
          <VTextField
            :value="passengerEmail"
            class="pb-6 pt-0 mt-0"
            data-test="passengerEmail"
            :hide-details="!errors.length"
            :error="!!errors.length"
            :error-messages="$t(errors[0])"
            :label="$t('bookingsTable.email')"
            @input="setEmail"
          />
        </ValidationProvider>
      </div>
      <div v-if="bookingEdit">
        <ValidationProvider
          ref="resendConfirmationEmail"
          name="resendConfirmationEmail"
          mode="lazy"
          :rules="{ required: false }"
        >
          <v-checkbox
            :input-value="passengerResendEmailConfirmation"
            class="pb-0 pt-0 mt-0 passengerEmailConfirmation"
            :label="$t('bookingForm.midocoPassenger.reSendConfirmationEmail')"
            color="#6A32A1"
            @change="setResendEmailConfirmation"
          />
        </ValidationProvider>
      </div>
      <div v-else>
        <ValidationProvider
          ref="confirmationEmail"
          name="confirmationEmail"
          mode="lazy"
          :rules="{ required: false }"
        >
          <v-checkbox
            :input-value="passengerEmailConfirmation"
            class="pb-0 pt-0 mt-0 passengerEmailConfirmation"
            :label="$t('bookingForm.passenger.emailConfirmation')"
            color="#6A32A1"
            @change="setEmailConfirmation"
          />
        </ValidationProvider>
      </div>
    </validationobserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SGlobalErrorDialogMixin from '@slg/web-customer-shared/src/mixins/GlobalErrorDialogMixin';
import SValidationErrorMixin from '@slg/web-customer-shared/src/mixins/ValidationErrorMixin';

export default {
  name: 'PassengerInfoForm',
  mixins: [
    SValidationErrorMixin,
    SGlobalErrorDialogMixin,
  ],
  data() {
    return {
      phoneNumber: '',
      isLoading: false,
      isLoadingCountries: false,
      isValidPhoneNumber: true,
      phoneFilled: false,
      languages: [
        { name: this.$t('bookingForm.passenger.english'), code: 'en' },
        { name: this.$t('bookingForm.passenger.french'), code: 'fr' },
        { name: this.$t('bookingForm.passenger.german'), code: 'de' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
      getPassengerDetailsStateCreate: 'booking/getPassengerDetailsState',
      getClearPhoneFieldStatusCreate: 'booking/getClearPhoneFieldStatus',
      getPassengerDetailsStateEdit: 'bookingEdit/getPassengerDetailsState',
      getClearPhoneFieldStatusEdit: 'bookingEdit/getClearPhoneFieldStatus',
    }),
    stateName() {
      return this.bookingEdit ? 'bookingEdit' : 'booking';
    },
    getPassengerDetailsState() {
      return this.bookingEdit ? this.getPassengerDetailsStateEdit : this.getPassengerDetailsStateCreate;
    },
    getClearPhoneFieldStatus() {
      return this.bookingEdit ? this.getClearPhoneFieldStatusEdit : this.getClearPhoneFieldStatusCreate;
    },
    passengerFirstName() {
      return this.getPassengerDetailsState.firstName;
    },
    passengerLastName() {
      return this.getPassengerDetailsState.lastName;
    },
    passengerLanguage() {
      return this.languages.find((i) => i.code === this.getPassengerDetailsState.language);
    },
    passengerEmail() {
      return this.getPassengerDetailsState.email;
    },
    passengerEmailConfirmation() {
      return this.getPassengerDetailsState.confirmationEmail;
    },
    passengerResendEmailConfirmation() {
      return this.getPassengerDetailsState.resendConfirmationEmail;
    },
  },
  watch: {
    phoneNumber: {

      async handler(newVal) {
        if (!this.phoneFilled) {
          this.setPhone(newVal.replace(/\s/g, ''));
        }
      },
      deep: true,
    },
    getClearPhoneFieldStatus: {

      async handler(newVal) {
        if (newVal) {
          this.phoneNumber = '';
          this.$store.commit(`${this.stateName}/clearPhoneField`, true);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.getPassengerDetailsState.phone) {
      this.phoneFilled = true;
      this.phoneNumber = this.getPassengerDetailsState.phone;
    }
  },
  methods: {
    setPhone(data) {
      this.$store.commit(`${this.stateName}/setPassengerPhone`, data);
    },
    setFirstName(data) {
      this.$store.commit(`${this.stateName}/setPassengerFirstName`, data);
    },
    setLastName(data) {
      this.$store.commit(`${this.stateName}/setPassengerLastName`, data);
    },
    setLanguage(data) {
      this.$store.commit(`${this.stateName}/setPassengerLanguage`, data.code);
    },
    setEmail(data) {
      this.$store.commit(`${this.stateName}/setPassengerEmail`, data);
    },
    setEmailConfirmation(data) {
      this.$store.commit(`${this.stateName}/setPassengerEmailConfirmation`, data);
    },
    setResendEmailConfirmation(data) {
      this.$store.commit(`${this.stateName}/setResendPassengerEmailConfirmation`, data);
    },
    async validatePhone() {
      this.$nextTick(async () => {
        this.isValidPhoneNumber = this.$refs?.phonePassengerInput?.phoneObject.isValid;
      });
    },
    async selectCountry(val) {
      this.isLoadingCountries = true;
      this.$nextTick(async () => {
        if (this.phoneFilled) {
          this.phoneFilled = false;
        } else {
          this.phoneNumber = `+${val?.dialCode}`;
        }
        this.isLoadingCountries = false;
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.vue-tel-input-vuetify {
  height: 42px !important;
}

::v-deep .passengerEmailConfirmation.v-input--selection-controls .v-input__slot>.v-label {
  margin-bottom: 0 !important;
}
</style>
