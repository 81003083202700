import { axios } from '@slg/web-customer-shared';

const apiDomain = 'abs-retailer/api/v1/retailer/bookings';

const getBookings = (params) => axios.post(`${apiDomain}/search`, { ...params });

const getBooking = (id) => axios.get(`${apiDomain}/${id}`);

const cancelBooking = (id) => axios.post(`${apiDomain}/${id}/cancel`);

const updateBooking = (id, booking) => axios.patch(`${apiDomain}/${id}`, { ...booking });

export default {
  getBookings, cancelBooking, getBooking, updateBooking,
}
