<template>
  <div>
    <ValidationObserver
      ref="servicesSelection"
      v-slot="{ errors }"
      name="servicesSelection"
      tag="div"
    >
      <div
        v-for="(item, index) in getItems"
        :key="`${item.refName} ${index}`"
        :ref="item.refName"
        :name="item.refName"
        tag="div"
      >
        <div
          class="d-flex justify-space-between px-0 align-items-center pb-4"
        >
          <div
            class="d-flex body-2 p-0"
          >
            <VIcon class="mr-2">
              {{ item.icon }}
            </VIcon>
            <div>
              <div>
                <span class="body-1">{{ $t(item.nameKey) }}</span>
              </div>
              <div>
                <span
                  class="body-2"
                >{{ item.amount }} x {{ item.price }}€ = {{ item.sumPrice }}€</span>
              </div>
            </div>
          </div>
          <VSpacer />
          <CustomCounter
            :amount="item.amount"
            :maxValue="item.maxValue"
            :disabled="getReservation.originalOutwardPrice !== null"
            :hint="$t('validations.maxExtraLuggage', { number: getExtraLuggageMaximumQuantityPerBooking })"
            :rules="{ amountIsValid: [item.amount, item.maxValue] } "
            @updateAmount="item.updateAmountCallback"
          />
        </div>
        <div
          v-if="errors.counter && errors.counter.length"
          class="mt-0 pb-4 pt-1"
        >
          <span class="caption error--text">{{ $t(getPassengersError(item.errorKey), { number: item.maxValue }) }}</span>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomCounter from '@slg/web-customer-shared/src/components/common/CustomCounter.vue';

export default {
  name: 'ServicesSelection',
  components: {
    CustomCounter,
  },
  computed: {
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
      getReservation: 'booking/getReservation',
      getExtraLuggageFeaturePrice:
        'settingsConfiguration/getExtraLuggageFeaturePrice',
      getExtraLuggageMaximumQuantityPerBooking:
        'settingsConfiguration/getExtraLuggageMaximumQuantityPerBooking',

      getExtraLuggageCreate: 'booking/getExtraLuggage',
      getExtraLuggageEdit: 'bookingEdit/getExtraLuggage',
    }),
    stateName() {
      return this.bookingEdit ? 'bookingEdit' : 'booking';
    },
    getExtraLuggage() {
      return this.bookingEdit
        ? this.getExtraLuggageEdit
        : this.getExtraLuggageCreate;
    },
    getItems() {
      return [
        {
          refName: 'extraLuggage',
          maxValue: this.getExtraLuggageMaximumQuantityPerBooking,
          amount: this.getExtraLuggage,
          price: this.getExtraLuggageFeaturePrice,
          sumPrice: (
            this.getExtraLuggage * this.getExtraLuggageFeaturePrice
          ).toFixed(2),
          icon: 'mdi-bag-suitcase',
          nameKey: 'bookingForm.passengersAndServices.extraLuggage',
          errorKey: 'validations.maxExtraLuggage',
          isBookingFormNarrow: this.isBookingFormNarrow,
          className: '',
          updateAmountCallback: this.updateExtraLuggage,
        },
      ];
    },
    isBookingFormNarrow() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getPassengersError(errorKey) {
      const servicesSelectionObserver = this.$refs?.servicesSelection;
      const maxFeatureAmountError = servicesSelectionObserver?.errors?.counter?.find(
        (message) => message === 'bookingForm.validations.maximumFeatureAmountIsReached',
      ) || null;

      if (maxFeatureAmountError) {
        return errorKey;
      }

      return null;
    },
    updateExtraLuggage(val) {
      this.$store.commit(`${this.stateName}/setExtraLuggage`, val);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
