<template>
  <div>
    <STheHeader
      :isHeaderShown="isHeaderShown"
      :currentLang="getCurrentLang"
      :supportedLanguages="languages"
      :toolbarItems="toolbarItems"
      :authToken="getAuthToken"
      :firstLetters="getFirstLetters"
      :fullName="getFullName"
      :cabinetToolbarItems="cabinetToolbarItems"
      :isLoadingLogOut="isLoadingLogOut"
      :logoRedirectPath="getLogoRedirectPath"
      @setLang="setLang"
      @toAuthPage="toAuthPage"
      @logOut="logOut"
    >
      <template #headerLogoIcon>
        <span />
      </template>
      <template #loginButton>
        <span />
      </template>
    </STheHeader>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  STheHeader, SGlobalErrorDialogMixin,
} from '@slg/web-customer-shared';
import appConfig from '@/appConfig';

export default {
  name: 'TheHeader',
  components: {
    STheHeader,
  },
  mixins: [
    SGlobalErrorDialogMixin,
  ],
  props: {
    isHeaderShown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoadingLogOut: false,
      isCancelingBookingDialogOpen: false,
      languages: null,
      toolbarItems: [],
      cabinetToolbarItems: [
        {
          text: 'cabinet.logOut',
          icon: 'mdi-logout-variant',
          value: 'LogOut',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getAuthToken: 'auth/getAuthToken',
      getIsMidocoAuth: 'auth/getIsMidocoAuth',
      getFirstLetters: 'auth/getFirstLetters',
      getFullName: 'auth/getFullName',
    }),
    getAppConfig() {
      return appConfig;
    },
    getLogoRedirectPath() {
      const { defaultLanguage } = appConfig;
      const currentLang = this.$route.params.lang || defaultLanguage;
      return { name: 'Booking', params: { lang: currentLang } };
    },
    getCurrentLang() {
      return this.$route.params.lang;
    },
  },
  created() {
    this.languages = appConfig.languages;
    this.toolbarItems = [
      {
        text: 'header.bookings',
        value: 'Bookings list',
      },
      ...(!this.getIsMidocoAuth
        ? [
          {
            text: 'header.createNewBooking',
            value: 'Booking',
          },
        ]
        : []),
    ];
  },
  methods: {
    setLang(lang) {
      if (lang !== this.getCurrentLang) {
        // if it is midoco booking
        if (this.$route.query?.addressFrom) {
          this.$router.push({ name: this.$route.name, params: { lang }, query: this.$route.query });
          this.$router.go();
        } else {
          this.$router.push({ params: { lang } });
        }
      }
    },
    toAuthPage() {
      this.$router.push({ name: 'Auth' });
    },
    async logOut() {
      this.isLoadingLogOut = true;
      try {
        // this.$store.commit('booking/clearTemporaryReservation');
      } catch (e) {
        await this.setGlobalError(e);
      }
      this.isLoadingLogOut = false;
      this.$store.commit('auth/clearAuthTokenAndProfileInfo');
      this.$store.commit('booking/clearBookingState');
      await this.$router.push({ name: 'Login' });
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/style/headerLinks.scss";
.header {
  transform: translateY(0);
  transition: transform 300ms linear !important;
}
.header.is-hidden {
  transform: translateY(-100%) !important;
}

.list-item {
  width: 172px !important;
}

.ps {
  width: auto;
  bottom: 0;
}

</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
