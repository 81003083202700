<template>
  <div
    data-test="map-container"
    class="w-100 map-container"
    :style="getMapContainerStyle"
  >
    <template v-if="!previewMode">
      <div
        class="prepend-inner-container"
        :class="{
          'mobile-focused': $vuetify.breakpoint.xs && isFocused && !getIsPlaceSelected,
          'mobile-focused-place-selected': $vuetify.breakpoint.xs && isFocused && getIsPlaceSelected,
        }"
      >
        <slot
          name="prepend-inner"
        />
        <slot
          name="prepend-inner-after"
        />
      </div>
      <div
        class="append-inner-container"
        :style="getAppendInnerContainerStyle"
      >
        <slot
          name="append-inner"
        />
      </div>
      <slot name="zoom">
        <div
          class="zoom-container"
          :style="getZoomContainerContainerStyle"
        >
          <div class="zoom-icon-container mb-3">
            <VBtn
              v-blur
              color="textWhite"
              fab
              small
              :disabled="zoom >= maxZoom"
              @click.prevent="$emit('updateZoom', zoom + 1)"
            >
              <VIcon dark>
                mdi-plus
              </VIcon>
            </VBtn>
          </div>
          <div class="zoom-icon-container">
            <VBtn
              v-blur
              color="textWhite"
              fab
              small
              :disabled="zoom <= minZoom"
              @click.prevent="$emit('updateZoom', zoom - 1)"
            >
              <VIcon dark>
                mdi-minus
              </VIcon>
            </VBtn>
          </div>
        </div>
      </slot>
    </template>
    <LMap
      ref="map"
      :key="mapKey"
      :zoom="zoom"
      class="w-100 h-100 position-relative"
      :zoom-control="false"
      :options="{ gestureHandling: true }"
      @click="data => $emit('onMapClick', data)"
      @update:zoom="data => $emit('updateZoom', data)"
      @drag="isFocused = true"
    >
      <LTileLayer
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <slot
        v-if="!getAtLeastOnePlaceIsPoi || selectedLocation.isPoi"
        name="placeMarkers"
      >
        <LMarker
          v-for="(item, index) in getPlaceMarkerList"
          :key="`placeMarkers-${index}-${item.id}`"
          :data-test="`${item.type}-marker`"
          :class="`${item.type}-marker`"
          :lat-lng.sync="item.position"
          @click="selectMarker(item)"
        >
          <LIcon
            class-name="custom-div-icon"
          >
            <LTooltip :content="item.tooltip" />
            <div
              class="marker-pin d-flex justify-center align-items-center"
              :class="item.iconClass"
            >
              <img
                v-if="isCustomIcon(item.type) && !item.activePoi"
                class="rotate-45"
                :src="require(`@slg/web-customer-shared/src/assets/img/${item.icon}.svg`)"
                alt="place"
              >
              <span
                v-else-if="item.activePoi"
                class="textWhite--text rotate-45 heading-6"
              >{{ item.stopCount + 1 }}
              </span>
              <i
                v-else-if="!item.activePoi"
                :class="`v-icon mdi ${item.icon} mdi-rotate-45 textWhite--text`"
              />
            </div>
            <div
              class="circle"
              :class="item.activePoi ? 'active' : ''"
            />
          </LIcon>
        </LMarker>
      </slot>
      <slot name="userMarkers">
        <LMarker
          v-for="(item, index) in getUserMarkerList"
          :key="`UserMarker${index}`"
          :lat-lng.sync="item.position"
          :icon="item.icon"
        />
      </slot>
      <slot
        v-if="getRideMarkers.length"
        name="rideMarkers"
      >
        <LMarker
          v-for="(item, index) in getRideMarkers"
          :key="`RideMarker${index}`"
          :lat-lng.sync="item.position"
          :icon="item.icon"
        />
      </slot>
    </LMap>
  </div>
</template>

<script>
import * as L from 'leaflet';
import { mapGetters } from 'vuex';
import PoiTypeEnum from '@slg/web-customer-shared/src/enum/PoiTypeEnum';
import { getIconNameFromMarkerType } from '@slg/web-customer-shared/src/utils/iconResolver';
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
} from 'vue2-leaflet';
// eslint-disable-next-line no-unused-vars
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import { latLng } from 'leaflet';

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 6,
    },
    mapKey: {
      type: String,
      default: '',
    },
    mapCenter: {
      type: Array,
      default: () => [0, 0],
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    userMarkers: {
      type: Array,
      default: () => [],
    },
    rideMarkers: {
      type: Array,
      default: () => [],
    },
    placeMarkers: {
      type: Array,
      default: () => [],
    },
    selectedLocation: {
      type: Object,
      default: () => ({}),
    },
    selectedPoi: {
      type: Object,
      default: () => ({}),
    },
    selectedPoiType: {
      type: String,
      default: null,
    },
    currentStep: {
      type: String,
      default: '',
    },
    isArrivalPlaceFilled: {
      type: Boolean,
      default: false,
    },
    isDeparturePlaceFilled: {
      type: Boolean,
      default: false,
    },
    additionalHeightForMap: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      maxZoom: 18,
      minZoom: 1,
      tileProvider: {
        name: 'OpenStreetMap',
        visible: true,
        attribution:
          '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      isMapReady: false,
      isFocused: true,
    };
  },
  computed: {
    ...mapGetters({
      getAtLeastOnePlaceIsPoi: 'booking/getAtLeastOnePlaceIsPoi',
    }),
    getCorrectHeightValue() {
      return this.additionalHeightForMap > 0 ? this.additionalHeightForMap : 0;
    },
    getMapContainerStyle() {
      return this.$vuetify.breakpoint.xs
        ? {
          height: `${400 + this.getCorrectHeightValue}px`,
        }
        : {
          height: '100%',
        };
    },
    getAppendInnerContainerStyle() {
      if (
        this.$vuetify.breakpoint.xs
        && this.isFocused
        && !this.getIsPlaceSelected
      ) {
        return {
          position: 'fixed',
          top: `${396 + this.getCorrectHeightValue}px`,
          bottom: 'unset',
        };
      } if (
        this.$vuetify.breakpoint.xs
        && this.isFocused
        && this.getIsPlaceSelected
      ) {
        return {
          position: 'fixed',
          top: `${436 + this.getCorrectHeightValue}px`,
          bottom: 'unset',
        };
      } if (
        this.$vuetify.breakpoint.xs
        && this.isFocused
        && !this.placeMarkers.length
      ) {
        return {
          position: 'fixed',
          top: 'unset',
          bottom: '16px',
        };
      }

      return {};
    },
    getZoomContainerContainerStyle() {
      if (
        this.$vuetify.breakpoint.xs
        && this.isFocused
        && !this.getIsPlaceSelected
      ) {
        return {
          position: 'fixed',
          top: `${348 + this.getCorrectHeightValue}px`,
          right: '20px',
          bottom: 'unset',
        };
      } if (
        this.$vuetify.breakpoint.xs
        && this.isFocused
        && this.getIsPlaceSelected
      ) {
        return {
          position: 'fixed',
          top: `${388 + this.getCorrectHeightValue}px`,
          bottom: 'unset',
          right: '20px',
        };
      } if (
        this.$vuetify.breakpoint.xs
        && this.isFocused
        && !this.placeMarkers.length
      ) {
        return {
          position: 'fixed',
          top: 'unset',
          bottom: '12px',
          right: '20px',
        };
      }

      return {};
    },
    getIsPlaceSelected() {
      return (
        (this.currentStep === 'pickUp' && this.isDeparturePlaceFilled)
        || (this.currentStep === 'dropOff' && this.isArrivalPlaceFilled)
      );
    },
    getCenter() {
      return latLng(this.mapCenter[0], this.mapCenter[1]);
    },
    getRideMarkers() {
      return this.rideMarkers.map((marker, stopCount) => {
        const pinColor = stopCount ? 'secondary' : 'brandingPrimary';
        const innerIcon = `<span class="textWhite--text rotate-45 heading-6">${
          stopCount + 1
        }</span>`;
        const htmlElement = `<div class='marker-pin d-flex justify-center align-items-center ${pinColor}'>${innerIcon}</div>`;
        const icon = L.divIcon({
          className: 'custom-div-icon',
          html: htmlElement,
          iconSize: [30, 42],
          iconAnchor: [15, 42],
        });
        return {
          position: [marker.lat, marker.lon],
          icon,
        };
      });
    },
    getUserMarkerList() {
      return this.userMarkers
        .filter((marker) => marker?.lat && marker?.lon)
        .map((userMarker) => {
          const fill = userMarker.valid
            ? this.$vuetify.theme.themes.light.brandingPrimary
            : this.$vuetify.theme.themes.light.error;
          const icon = L.divIcon({
            className: 'custom-div-icon',
            html:
              '<div><svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg">\n'
              // eslint-disable-next-line max-len
              + `<path fill-rule="evenodd" clip-rule="evenodd" d="M19.621 33.869C28.0089 32.825 34.5 25.6706 34.5 17C34.5 7.61116 26.8888 0 17.5 0C8.11116 0 0.5 7.61116 0.5 17C0.5 25.6706 6.99114 32.825 15.379 33.869C15.3763 33.9123 15.375 33.956 15.375 34V48.875C15.375 50.0486 16.3264 51 17.5 51C18.6736 51 19.625 50.0486 19.625 48.875V34C19.625 33.956 19.6237 33.9123 19.621 33.869ZM17.5 23.375C21.0208 23.375 23.875 20.5208 23.875 17C23.875 13.4792 21.0208 10.625 17.5 10.625C13.9792 10.625 11.125 13.4792 11.125 17C11.125 20.5208 13.9792 23.375 17.5 23.375Z" fill="${fill}"/>`
              + '</svg></div>',
            iconSize: [35, 51],
            iconAnchor: [18, 50],
          });

          return {
            position: [userMarker.lat, userMarker.lon],
            icon,
          };
        });
    },
    getPlaceMarkerList() {
      const activeStopIdsFromPoi = this.placeMarkers.reduce(
        (accumulator, currentValue) => {
          if (currentValue.activePoi) {
            return accumulator.concat(currentValue);
          }
          return accumulator;
        },
        [],
      );

      return this.placeMarkers.map((marker) => {
        const icon = getIconNameFromMarkerType(marker.type);
        const { activeStop, activePoi } = marker;
        const stopCount = activeStopIdsFromPoi.findIndex(
          (stop) => stop.id === marker.id,
        );
        const iconClass = `${activePoi ? 'selected-poi' : ''} ${
          activeStop ? 'active' : ''
        }`;

        return {
          position: marker.position,
          tooltip: marker.tooltip,
          poiId: marker.poiId,
          id: marker.id,
          type: marker.type,
          isPoiGroup: marker.isPoiGroup,
          poiGroupId: marker?.poiGroupId,
          icon,
          activePoi,
          activeStop,
          stopCount,
          iconClass,
        };
      });
    },
  },
  watch: {
    selectedPoi: {
      handler() {
        if (!this.selectedPoi?.address) {
          this.isMapReady = true;
          return;
        }

        const dotsList = this.selectedPoi.stops
          .map((stop) => [[stop.latitude, stop.longitude]])
          .flat();
        this.zoomToPoi(dotsList, 300);
      },
      deep: true,
      immediate: true,
    },
    selectedLocation: {
      handler() {
        if (!this.selectedLocation.address) {
          this.isMapReady = true;
          return;
        }

        const dotsList = [
          [this.selectedLocation.lat, this.selectedLocation.lon],
        ];
        this.zoomToPoi(dotsList, 300);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.invalidateMapSize();
    });
    this.$watch(
      (vm) => [vm.isLoading],
      () => {
        if (!this.isLoading) {
          this.initializeMap();
        }
      },
      {
        deep: true,
        immediate: false,
      },
    );
  },
  methods: {
    initializeMap() {
      const dotsList = this.placeMarkers
        .map((item) => item.position.map((point) => point))

      this.isMapReady = true;
      this.zoomToPoi(dotsList, 300);
      if (this.selectedLocation.lat && this.selectedLocation.lon) {
        this.setCenter(
          this.selectedLocation.lat,
          this.selectedLocation.lon,
        );
      }
      this.isFocused = true;
      setTimeout(async () => {
        await this.$emit('recalculateHeight');
        this.invalidateMapSize();
      }, 700);
    },
    dotsBound(dots) {
      if (!this.$refs.map) {
        return;
      }
      this.$refs.map.fitBounds(dots);
    },
    invalidateMapSize() {
      if (!this.$refs.map) {
        return;
      }
      this.$refs.map.mapObject.invalidateSize();
    },
    setCenter(lat, lon) {
      this.$refs.map.setCenter([lat, lon]);
    },
    isCustomIcon(poiType) {
      return (
        poiType === PoiTypeEnum.WORKSHOP_FOR_DISABLED_PEOPLE
        || poiType === PoiTypeEnum.SCHOOL_AND_SPORT_COMPLEX
        || poiType === PoiTypeEnum.AIRPORT
      );
    },
    selectMarker(item) {
      this.$emit('selectMarker', item);
      this.$emit('selectPlace');
    },
    zoomToPoi(dotsList, delay) {
      const markerList = dotsList
        .map((marker) => L.circleMarker([marker[0], marker[1]]))
        .flat();
      // eslint-disable-next-line new-cap
      const group = new L.featureGroup(markerList);
      this.dotsBound(group.getBounds());
      this.$nextTick(() => {
        setTimeout(() => {
          this.invalidateMapSize();
          this.dotsBound(group.getBounds().pad(0.1));
        }, delay);
      });
    },
  },
};
</script>

<style lang="scss">
.prepend-inner-container,
.prepend-inner-after {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, 0);
  top: 12px;
  left: 50%;
  width: 96%;
  z-index: 1000;

  &.mobile-focused {
    position: fixed;
    top: 66px;
  }

  &.mobile-focused-place-selected {
    position: fixed;
    top: 102px;
  }
}

.append-inner-container {
  display: flex;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, 0);
  bottom: 16px;
  left: 50%;
  width: 96%;
  z-index: 1000;
}

.marker-pin {
  width: 32px;
  height: 32px;
  border: 1px solid var(--v-textWhite-base);
  border-radius: 50% 50% 50% 0;
  background: var(--v-brandingSecondary-base);
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;

  &.selected-poi {
    background: var(--v-textTertiary-base);
  }

  &.active {
    background: var(--v-brandingPrimary-base);
  }

  &:after {
    content: "";
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    position: absolute;
    border-radius: 50%;
  }
}

.map-container {
  z-index: 1000;

  .zoom-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 12px;
    bottom: 12px;
    z-index: 1000;

    .zoom-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.rotate-45 {
  transform: rotate(45deg);
}

.circle {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  border: 1px solid #ff9126;
  display: inline-block;
  margin-top: 30px;
  margin-left: 4.5px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), 0px 4px 8px rgb(0 0 0 / 8%);

  &.active {
    border: 1px solid var(--v-brandingPrimary-base);
  }
}

.leaflet-control-container {
  display: none;
}
</style>
