import store from '@/store';
import moment from 'moment-timezone';
import PointOfInterstGroupManagement from '@/services/PointOfInterstGroupManagement';
import RetailerUserService from '@/services/RetailerUserService';
import { validate as uuidValidate } from 'uuid';
import i18n from '@/plugins/i18n';
import { determiningSelectedQuery } from '@slg/web-customer-shared/src/providers/MapProvider';
import RetailerBookingReservationManagement from '@/services/RetailerBookingReservationManagement';
import { execute } from '@/utils/asyncTaskManagement';
import { RIDE_TYPES, PASSENGER_TYPES, ERROR_CODES } from '@/utils/constants/booking';

const removeSpaces = (str) => str.replace(/\s/g, '');

const convertDateTime = (date, time) => {
  const booking = store.getters['booking/getBookingForm'];
  return moment.tz(`${date} ${time}`, booking.departurePlace?.timeZone || booking.arrivalPlace?.timeZone).valueOf();
}

const generateStopsList = async (booking, ascending = true) => {
  let stops = [];
  const { data: { pointOfInterestGroupList, pointOfInterestList } } = await PointOfInterstGroupManagement.getAllPois(null);
  const response = await RetailerUserService.getPartnerProfile();
  const retailerPartner = response.data;

  if (booking.departurePlace.poiGroupId) {
    const poiGroup = pointOfInterestGroupList.find((group) => group.poiGroupId === booking.departurePlace.poiGroupId);
    const poiGroupStops = poiGroup.pointsOfInterest
      .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
      .reduce((acc, poi) => {
        acc.push({
          address: poi.stops[0].address,
          latitude: poi.stops[0].latitude,
          longitude: poi.stops[0].longitude,
          poiStopId: poi.stops[0].poiStopId || null,
          stopNumber: ascending ? 1 : 2,
          tenantShortName: poi?.tenantShortName || null,
          poiType: poi?.type || null,
          poiId: poi?.pointOfInterestId || null,
        })
        return acc;
      }, [])
    stops = [...stops, ...poiGroupStops];
  } else {
    let poi;
    if (booking.departurePlace.poiStopId) {
      poi = pointOfInterestList
        .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
        .find((p) => p.stops.find((stop) => stop.poiStopId === booking.departurePlace.poiStopId))
    }

    stops.push({
      address: booking.departurePlace.address,
      latitude: booking.departurePlace.lat,
      longitude: booking.departurePlace.lon,
      poiStopId: booking.departurePlace.poiStopId || null,
      stopNumber: ascending ? 1 : 2,
      tenantShortName: poi?.tenantShortName || null,
      poiType: poi?.type || null,
      poiId: poi?.pointOfInterestId || null,
    });
  }

  if (booking.arrivalPlace.poiGroupId) {
    const poiGroup = pointOfInterestGroupList.find((group) => group.poiGroupId === booking.arrivalPlace.poiGroupId);
    const poiGroupStops = poiGroup.pointsOfInterest
      .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
      .reduce((acc, poi) => {
        acc.push({
          address: poi.stops[0].address,
          latitude: poi.stops[0].latitude,
          longitude: poi.stops[0].longitude,
          poiStopId: poi.stops[0].poiStopId || null,
          stopNumber: ascending ? 2 : 1,
          tenantShortName: poi?.tenantShortName || null,
          poiType: poi?.type || null,
          poiId: poi?.pointOfInterestId || null,
        })
        return acc;
      }, [])
    stops = [...stops, ...poiGroupStops];
  } else {
    let poi;
    if (booking.arrivalPlace.poiStopId) {
      poi = pointOfInterestList
        .filter(({ tenantShortName }) => !retailerPartner.restrictedTenants?.find((tenant) => tenant === tenantShortName))
        .find((p) => p.stops.find((stop) => stop.poiStopId === booking.arrivalPlace.poiStopId))
    }

    stops.push({
      address: booking.arrivalPlace.address,
      latitude: booking.arrivalPlace.lat,
      longitude: booking.arrivalPlace.lon,
      poiStopId: booking.arrivalPlace.poiStopId || null,
      stopNumber: ascending ? 2 : 1,
      tenantShortName: poi?.tenantShortName || null,
      poiType: poi?.type || null,
      poiId: poi?.pointOfInterestId || null,
    });
  }
  return stops;
}

const buildReservationModel = async (booking) => {
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.outwardForm.outwardDate.date, booking.outwardForm.outwardDate.time)
  model.flightOutwardNumber = booking.outwardForm.flightNumber;
  model.flightReturnDateTimeMillis = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.returnDate.date, booking.returnForm.returnDate.time)
    : null;
  model.flightReturnNumber = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.returnForm.flightNumber
    : null;
  model.returnDateTime = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.routeTaxiDate.date, booking.returnForm.routeTaxiDate.time)
    : null;
  model.returnDeparture = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.arrivalPlace.isFirstSelected
    : null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.outwardForm.routeTaxiDate.date, booking.outwardForm.routeTaxiDate.time)
  model.outwardDeparture = booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE;
  model.roundTrip = booking.tripType !== RIDE_TYPES.ONE_WAY;
  model.stops = await generateStopsList(booking);
  return model;
}

export const buildReservationModelForOutward = async () => {
  const booking = store.getters['booking/getBookingForm'];
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.outwardForm.outwardDate.date, booking.outwardForm.outwardDate.time);
  model.flightOutwardNumber = booking.outwardForm.flightNumber;
  model.flightReturnDateTimeMillis = null;
  model.flightReturnNumber = null;
  model.returnDateTime = null;
  model.returnDeparture = null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.outwardForm.routeTaxiDate.date, booking.outwardForm.routeTaxiDate.time);
  model.outwardDeparture = booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE;
  model.roundTrip = false;
  model.stops = await generateStopsList(booking);
  return model;
}

export const buildCalculatePriceModelForReturn = async () => {
  const booking = store.getters['booking/getBookingForm'];
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.returnForm.returnDate.date, booking.returnForm.returnDate.time);
  model.flightOutwardNumber = booking.returnForm.flightNumber;
  model.flightReturnDateTimeMillis = null;
  model.flightReturnNumber = null;
  model.returnDateTime = null;
  model.returnDeparture = null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.returnForm.routeTaxiDate.date, booking.returnForm.routeTaxiDate.time);
  model.outwardDeparture = !booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE;
  model.roundTrip = false;
  model.stops = await generateStopsList(booking, false);
  model.stops.reverse();
  return model;
}

const buildCalculatePriceModel = async (booking) => {
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.outwardForm.outwardDate.date, booking.outwardForm.outwardDate.time);
  model.flightOutwardNumber = booking.outwardForm.flightNumber;
  model.flightReturnDateTimeMillis = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.returnDate.date, booking.returnForm.returnDate.time)
    : null;
  model.flightReturnNumber = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.returnForm.flightNumber
    : null;
  model.returnDateTime = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.routeTaxiDate.date, booking.returnForm.routeTaxiDate.time)
    : null;
  model.returnDeparture = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.arrivalPlace.isFirstSelected
    : null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.outwardForm.routeTaxiDate.date, booking.outwardForm.routeTaxiDate.time);
  model.outwardDeparture = booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE;
  model.roundTrip = booking.tripType !== RIDE_TYPES.ONE_WAY;
  model.stops = await generateStopsList(booking);
  return model;
}

export const buildBookingCreateModelForOutward = async () => {
  const booking = store.getters['booking/getBookingForm'];
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.outwardForm.outwardDate.date, booking.outwardForm.outwardDate.time);
  model.flightOutwardNumber = booking.outwardForm.flightNumber;
  model.flightReturnDateTimeMillis = null;
  model.flightReturnNumber = null;
  model.returnDateTime = null;
  model.returnDeparture = null;
  model.returnTripComment = null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.outwardForm.routeTaxiDate.date, booking.outwardForm.routeTaxiDate.time);
  model.outwardDeparture = booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE
  model.roundTrip = false;
  model.outwardTripComment = booking.outwardForm.commentForOutward;
  // midoco
  // model.orderId = reservation.orderId;
  // model.orgUnit = reservation.orgUnit;
  model.paymentType = 'INVOICE';
  model.reservationId = booking.reservation.reservationId;
  model.reservationTill = booking.reservation.reservationTill;
  model.tenantId = booking.reservation.tenantId;
  model.tenantShortName = booking.reservation.tenantShortName;

  if (booking.isMidocoBooking) {
    model.orderMidocoId = booking.orderId;
    model.mainPassengerDetails = {
      phone: removeSpaces(booking.midocoPassengers[0].phone),
      firstName: booking.midocoPassengers[0].firstName,
      lastName: booking.midocoPassengers[0].lastName,
      email: booking.midocoPassengers[0].email,
      language: booking.midocoPassengers[0].language,
      passengerType: booking.midocoPassengers[0].passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
      sendConfirmationEmail: booking.midocoPassengers[0].sendConfirmationEmail,
    };
    model.passengerDetails = booking.midocoPassengers.map((passenger) => ({
      ...(passenger.phone && { phone: removeSpaces(passenger.phone) }),
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      ...(passenger.email && { email: passenger.email }),
      passengerType: passenger.passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
    }));
  } else {
    model.mainPassengerDetails = {
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    };
    model.passengerDetails = [{
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    }];
  }

  model.stops = await generateStopsList(booking);
  return model;
}

export const buildAwaitingBookingModelForReturn = async (isOneWay) => {
  const booking = store.getters['booking/getBookingForm'];
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.returnForm.returnDate.date, booking.returnForm.returnDate.time);
  model.flightOutwardNumber = booking.returnForm.flightNumber;
  model.flightReturnDateTimeMillis = null;
  model.flightReturnNumber = null;
  model.returnDateTime = null;
  model.returnDeparture = null;
  model.returnTripComment = null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.returnForm.routeTaxiDate.date, booking.returnForm.routeTaxiDate.time);
  model.outwardDeparture = !booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE;
  model.roundTrip = booking.tripType !== RIDE_TYPES.ONE_WAY;
  model.outwardTripComment = booking.returnForm.comment;

  if (isOneWay) {
    model.originalOutwardPrice = booking.reservation.originalReturnPrice;
    model.originalOutwardFeaturePrice = booking.reservation.originalReturnFeaturePrice;
  } else {
    model.originalOutwardPrice = booking.reservation.originalOutwardPrice;
    model.originalReturnFeaturePrice = booking.reservation.originalReturnFeaturePrice;
    model.originalOutwardFeaturePrice = booking.reservation.originalOutwardFeaturePrice;
    model.originalReturnPrice = booking.reservation.originalReturnPrice;
  }

  if (booking.isMidocoBooking) {
    model.orderMidocoId = booking.orderId;
    model.mainPassengerDetails = {
      phone: removeSpaces(booking.midocoPassengers[0].phone),
      firstName: booking.midocoPassengers[0].firstName,
      lastName: booking.midocoPassengers[0].lastName,
      email: booking.midocoPassengers[0].email,
      language: booking.midocoPassengers[0].language,
      passengerType: booking.midocoPassengers[0].passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
      sendConfirmationEmail: booking.midocoPassengers[0].sendConfirmationEmail,
    };
    model.passengerDetails = booking.midocoPassengers.map((passenger) => ({
      ...(passenger.phone && { phone: removeSpaces(passenger.phone) }),
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      ...(passenger.email && { email: removeSpaces(passenger.email) }),
      passengerType: passenger.passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
    }));
  } else {
    model.mainPassengerDetails = {
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    };
    model.passengerDetails = [{
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    }];
  }

  model.stops = await generateStopsList(booking, false);
  model.stops.reverse();
  return model;
}

export const buildBookingCreateModel = async (booking) => {
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.outwardForm.outwardDate.date, booking.outwardForm.outwardDate.time);
  model.flightOutwardNumber = booking.outwardForm.flightNumber;
  model.flightReturnDateTimeMillis = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.returnDate.date, booking.returnForm.returnDate.time)
    : null;
  model.flightReturnNumber = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.returnForm.flightNumber
    : null;
  model.returnDateTime = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.routeTaxiDate.date, booking.returnForm.routeTaxiDate.time)
    : null;
  model.returnDeparture = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.arrivalPlace.isFirstSelected
    : null;
  model.returnTripComment = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.returnForm.comment
    : null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.outwardForm.routeTaxiDate.date, booking.outwardForm.routeTaxiDate.time).valueOf();
  model.outwardDeparture = booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE
  model.roundTrip = booking.tripType !== RIDE_TYPES.ONE_WAY;
  model.outwardTripComment = booking.outwardForm.comment;
  // midoco
  // model.orderId = reservation.orderId;
  // model.orgUnit = reservation.orgUnit;
  model.paymentType = 'INVOICE';
  model.reservationId = booking.reservation.reservationId;
  model.reservationTill = booking.reservation.reservationTill;
  model.tenantId = booking.reservation.tenantId;
  model.tenantShortName = booking.reservation.tenantShortName;

  if (booking.isMidocoBooking) {
    model.orderMidocoId = booking.orderId;
    model.mainPassengerDetails = {
      phone: removeSpaces(booking.midocoPassengers[0].phone),
      firstName: booking.midocoPassengers[0].firstName,
      lastName: booking.midocoPassengers[0].lastName,
      email: booking.midocoPassengers[0].email,
      language: booking.midocoPassengers[0].language,
      passengerType: booking.midocoPassengers[0].passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
      sendConfirmationEmail: booking.midocoPassengers[0].sendConfirmationEmail,
    };
    model.passengerDetails = booking.midocoPassengers.map((passenger) => ({
      ...(passenger.phone && { phone: removeSpaces(passenger.phone) }),
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      ...(passenger.email && { email: removeSpaces(passenger.email) }),
      passengerType: passenger.passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
    }));
  } else {
    model.mainPassengerDetails = {
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    };
    model.passengerDetails = [{
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    }];
  }

  model.stops = await generateStopsList(booking);
  return model;
}

const buildAwaitingBookingModel = async (booking) => {
  const model = {};
  model.adultsCount = booking.adultsCount;
  model.featureCapacity = booking.extraLuggage;
  model.flightOutwardDateTimeMillis = convertDateTime(booking.outwardForm.outwardDate.date, booking.outwardForm.outwardDate.time);
  model.flightOutwardNumber = booking.outwardForm.flightNumber;
  model.flightReturnDateTimeMillis = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.returnDate.date, booking.returnForm.returnDate.time)
    : null;
  model.flightReturnNumber = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.returnForm.flightNumber
    : null;
  model.returnDateTime = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? convertDateTime(booking.returnForm.routeTaxiDate.date, booking.returnForm.routeTaxiDate.time)
    : null;
  model.returnDeparture = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.arrivalPlace.isFirstSelected
    : null;
  model.returnTripComment = booking.tripType !== RIDE_TYPES.ONE_WAY
    ? booking.returnForm.comment
    : null;
  model.infantsCount = booking.infantsCount;
  model.outwardDateTime = convertDateTime(booking.outwardForm.routeTaxiDate.date, booking.outwardForm.routeTaxiDate.time);
  model.outwardDeparture = booking.departurePlace.isFirstSelected;
  model.privateShuttle = booking.rideType === RIDE_TYPES.PRIVATE;
  model.roundTrip = booking.tripType !== RIDE_TYPES.ONE_WAY;
  model.outwardTripComment = booking.outwardForm.comment;
  model.originalOutwardPrice = booking.reservation.originalOutwardPrice;
  model.originalReturnFeaturePrice = booking.reservation.originalReturnFeaturePrice;
  model.originalOutwardFeaturePrice = booking.reservation.originalOutwardFeaturePrice;
  model.originalReturnPrice = booking.reservation.originalReturnPrice;

  if (booking.isMidocoBooking) {
    model.orderMidocoId = booking.orderId;
    model.mainPassengerDetails = {
      phone: removeSpaces(booking.midocoPassengers[0].phone),
      firstName: booking.midocoPassengers[0].firstName,
      lastName: booking.midocoPassengers[0].lastName,
      email: booking.midocoPassengers[0].email,
      language: booking.midocoPassengers[0].language,
      passengerType: booking.midocoPassengers[0].passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
      sendConfirmationEmail: booking.midocoPassengers[0].sendConfirmationEmail,
    };
    model.passengerDetails = booking.midocoPassengers.map((passenger) => ({
      ...(passenger.phone && { phone: removeSpaces(passenger.phone) }),
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      ...(passenger.email && { email: removeSpaces(passenger.email) }),
      passengerType: passenger.passengerType === PASSENGER_TYPES.ADULT ? PASSENGER_TYPES.ADULT : PASSENGER_TYPES.INFANT,
    }));
  } else {
    model.mainPassengerDetails = {
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    };
    model.passengerDetails = [{
      phone: booking.passengerDetails.phone,
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      language: booking.passengerDetails.language,
      email: booking.passengerDetails.email,
      sendConfirmationEmail: booking.passengerDetails.confirmationEmail,
    }];
  }

  model.stops = await generateStopsList(booking);
  return model;
}

export const buildCreateReservationModel = () => {
  const booking = store.getters['booking/getBookingForm'];
  const getDaysDifferenceBetweenTodayAndOutward = store.getters['booking/getDaysDifferenceBetweenTodayAndOutward'];
  const getDaysDifferenceBetweenTodayAndReturn = store.getters['booking/getDaysDifferenceBetweenTodayAndReturn'];
  const isRoundTrip = store.getters['booking/isRoundTrip'];

  let model;
  if (isRoundTrip) {
    if (getDaysDifferenceBetweenTodayAndOutward < 14 && getDaysDifferenceBetweenTodayAndReturn < 14) {
      model = buildReservationModel(booking);
    } else {
      model = buildCalculatePriceModel(booking);
    }
  } else if (getDaysDifferenceBetweenTodayAndOutward < 14) {
    model = buildReservationModel(booking);
  } else {
    model = buildCalculatePriceModel(booking);
  }
  return model;
}

export const buildCreateBookingModel = () => {
  const booking = store.getters['booking/getBookingForm'];
  const getDaysDifferenceBetweenTodayAndOutward = store.getters['booking/getDaysDifferenceBetweenTodayAndOutward'];
  const getDaysDifferenceBetweenTodayAndReturn = store.getters['booking/getDaysDifferenceBetweenTodayAndReturn'];
  const isRoundTrip = store.getters['booking/isRoundTrip'];

  let model;
  if (isRoundTrip) {
    if (getDaysDifferenceBetweenTodayAndOutward < 14 && getDaysDifferenceBetweenTodayAndReturn < 14) {
      model = buildBookingCreateModel(booking);
    } else {
      model = buildAwaitingBookingModel(booking);
    }
  } else if (getDaysDifferenceBetweenTodayAndOutward < 14) {
    model = buildBookingCreateModel(booking);
  } else {
    model = buildAwaitingBookingModel(booking);
  }
  return model;
}

export const parseString = (str) => (str ? str.slice(1).slice(0, str.length - 2) : null)

export const getPhone = (phone) => (phone[0] === '+' ? phone : `+${phone}`)

export const getErrorMessage = (errorCode) => {
  if (errorCode === ERROR_CODES.RETAILER_BOOKING_STOP_WITH_ADDRESS_NOT_EXISTS) {
    return i18n.t('bookingForm.bookingStopWithAddressDoesntExist')
  }
  return null;
}

export const onSuccessfulBookingRequest = (result, vm) => {
  store.commit('booking/populateReservation', result.taskResult);
  vm.$toasted.success(i18n.t('bookingForm.successfulBookingRequest'))
}

export const onSuccessfulBookingPriceRequestForReturnTrip = (result, vm) => {
  store.commit('booking/populateOutwardTripReservation', result.taskResult);
  vm.$toasted.success(i18n.t('bookingForm.successfulBookingRequest'))
}

export const onErrorBookingRequest = (result, vm) => {
  const errorMessage = getErrorMessage(result?.taskStatus?.errorCode);
  const isRoundTrip = store.getters['booking/isRoundTrip'];
  if (errorMessage) {
    vm.$toasted.error(errorMessage)
  } else if (isRoundTrip) {
    vm.$toasted.error(i18n.t('bookingForm.errorRoundBookingRequestTwoDirections'))
  } else {
    vm.$toasted.error(i18n.t('bookingForm.errorOneWayBookingRequest'))
  }
}

export const onSuccessfulPriceCalculation = (result, vm) => {
  store.commit('booking/populateReservation', result.taskResult);
  vm.$toasted.success(i18n.t('bookingForm.successfulBookingRequest'))
}

export const onErrorPriceCalculation = (result, vm) => {
  const errorMessage = getErrorMessage(result?.taskStatus?.errorCode);
  const isRoundTrip = store.getters['booking/isRoundTrip'];
  if (errorMessage) {
    vm.$toasted.error(errorMessage)
  } else if (isRoundTrip) {
    vm.$toasted.error(i18n.t('bookingForm.errorRoundBookingRequestTwoDirections'))
  } else {
    vm.$toasted.error(i18n.t('bookingForm.errorOneWayBookingRequest'))
  }
}

export const onSuccessfulBookingCreate = (result, vm) => {
  store.commit('booking/clearReservation');
  vm.$router.push({ name: 'Bookings list' });
  const isRoundTrip = store.getters['booking/isRoundTrip'];
  if (isRoundTrip) {
    vm.$toasted.success(i18n.t('bookingForm.bookingsAreSuccessfullyCreated'))
  } else {
    vm.$toasted.success(i18n.t('bookingForm.bookingIsSuccessfullyCreated'))
  }
}

export const onErrorBookingCreate = (result, vm) => {
  store.commit('booking/clearReservation');
  const errorMessage = getErrorMessage(result?.taskStatus?.errorCode);
  if (errorMessage) {
    vm.$toasted.error(errorMessage)
  } else {
    vm.$toasted.error(i18n.t('general.somethingWentWrong'))
  }
}

export const onSuccessfulBookingPriceForReturn = async (result, vm) => {
  store.commit('booking/populateReturnTripReservation', result.taskResult);

  store.commit('common/setGlobalLoading', true);
  const task = {
    executionStage: 'PROCESSING',
    delay: 5000,
  }
  const modelForOutward = await buildReservationModelForOutward();
  const response = await RetailerBookingReservationManagement.createReservation(modelForOutward);
  const taskForOutward = { ...task };
  taskForOutward.taskId = response.data.taskId;
  await execute(taskForOutward, onSuccessfulBookingPriceRequestForReturnTrip, onErrorBookingRequest, vm);
}

export const onErrorBookingPriceForReturn = (result, vm) => {
  const errorMessage = getErrorMessage(result?.taskStatus?.errorCode);
  const isRoundTrip = store.getters['booking/isRoundTrip'];
  if (errorMessage) {
    vm.$toasted.error(errorMessage)
  } else if (isRoundTrip) {
    vm.$toasted.error(i18n.t('bookingForm.errorRoundBookingRequestTwoDirections'))
  } else {
    vm.$toasted.error(i18n.t('bookingForm.errorOneWayBookingRequest'))
  }
}

export const getPassengerFromUrl = (queryParams) => {
  if (queryParams?.customersNames) {
    const passengers = parseString(queryParams.customersNames).split(';');
    const passengersToSet = passengers.reduce((acc, passenger, index) => {
      const splittedPassenger = passenger.split(',');
      const p = {
        firstName: splittedPassenger[0],
        lastName: splittedPassenger[1],
        passengerType: splittedPassenger[2],
        phone: index ? '' : getPhone(parseString(queryParams.customerPhone)),
        email: index ? '' : parseString(queryParams.customerEmail),
        language: 'fr',
        sendConfirmationEmail: false,
      }

      acc.push(p);
      return acc;
    }, []);

    return passengersToSet;
  }
  return [];
}

export const generateDateAndTime = (queryParams, isOutwardDateFilled, isReturnDateFilled) => {
  if (isOutwardDateFilled) {
    store.commit(
      'booking/generateOutwardRouteTaxiDate',
      moment(`${parseString(queryParams?.outwardDate)} ${parseString(queryParams?.outwardTime)}`),
    );
  }
  if (isReturnDateFilled && (queryParams.roundTrip || queryParams.roundTrip === undefined)) {
    store.commit(
      'booking/generateReturnRouteTaxiDate',
      moment(`${parseString(queryParams?.returnDate)} ${parseString(queryParams?.returnTime)}`),
    );
  }
}

export const setPlace = async (address, placeType, queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled) => {
  if (address) {
    const isAddressPoi = uuidValidate(address)
    if (isAddressPoi) {
      const response = await RetailerUserService.getPartnerProfile();
      const retailerPartner = response.data;
      let poiFromUrl = pois.pointOfInterestList.find((poi) => poi.pointOfInterestId === address);

      if (poiFromUrl) {
        const isPoiAcceptable = !retailerPartner.restrictedTenants.find((tenant) => tenant === poiFromUrl.tenantShortName);
        if (!isPoiAcceptable) {
          return;
        }
        const location = {
          address: poiFromUrl.address,
          error: '',
          isFirstSelected: true,
          isPoi: true,
          lat: poiFromUrl.stops[0].latitude,
          lon: poiFromUrl.stops[0].longitude,
          poiGroupId: '',
          poiName: poiFromUrl.name,
          poiStopId: poiFromUrl.stops[0].poiStopId,
          poiStopName: poiFromUrl.stops[0].name,
          timeZone: poiFromUrl.timeZone,
          stopId: '',
          type: poiFromUrl.type,
        }
        if (placeType === 'setDeparturePlace') {
          store.commit('booking/setArrivalFirstSelected', false);
        } else {
          store.commit('booking/setDepartureFirstSelected', false);
        }
        await store.dispatch('booking/processQueryPlace', { placeType, location, queryParams }).then(() => {
          generateDateAndTime(queryParams, isOutwardDateFilled, isReturnDateFilled);
        })
      } else {
        poiFromUrl = pois.pointOfInterestGroupList.find((poiGroup) => poiGroup.poiGroupId === address)
          || pois.pointOfInterestGroupList
            .find((poiGroup) => poiGroup.pointsOfInterest.find((poi) => poi.pointOfInterestId === address));

        if (!poiFromUrl) {
          vm.$toasted.error(i18n.t('bookingForm.addressIsUndefined'))
        } else {
          const firstPermittedPoi = poiFromUrl.pointsOfInterest
            .find((poi) => !retailerPartner.restrictedTenants.find((tenant) => tenant === poi.tenantShortName))

          if (!firstPermittedPoi) {
            return;
          }
          const location = {
            address: firstPermittedPoi.address,
            error: '',
            isFirstSelected: true,
            isPoi: true,
            lat: firstPermittedPoi.stops[0].latitude,
            lon: firstPermittedPoi.stops[0].longitude,
            poiGroupId: poiFromUrl.poiGroupId,
            poiName: poiFromUrl.poiGroupName,
            poiStopId: firstPermittedPoi.stops[0].poiStopId,
            poiStopName: firstPermittedPoi.stops[0].name,
            timeZone: firstPermittedPoi.timeZone,
            stopId: '',
            type: poiFromUrl.poiGroupType,
          }
          if (placeType === 'setDeparturePlace') {
            store.commit('booking/setArrivalFirstSelected', false);
          } else {
            store.commit('booking/setDepartureFirstSelected', false);
          }
          await store.dispatch('booking/processQueryPlace', { placeType, location, queryParams }).then(() => {
            generateDateAndTime(queryParams, isOutwardDateFilled, isReturnDateFilled);
          })
        }
      }
    } else {
      const addresses = await determiningSelectedQuery(parseString(address));
      if (!addresses.length) {
        vm.$toasted.error(i18n.t('bookingForm.addressIsUndefined'))
      } else {
        const addressComponents = address.split(',').map((component) => component.trim().replace(/"/g, ''));
        const addresses = await determiningSelectedQuery(addressComponents.map((component) => component).join(', '));
        const doesAddressMatchExactly = addressComponents.every((component) => addresses[0].address.includes(component));

        let lat;
        let lon;
        if (!addresses.length || addresses[0].isGoogleApi || !doesAddressMatchExactly) {
          vm.$toasted.error(i18n.t('bookingForm.addressIsUndefined'))
        } else {
          // eslint-disable-next-line prefer-destructuring
          lat = addresses[0].geocode[1];
          // eslint-disable-next-line prefer-destructuring
          lon = addresses[0].geocode[0];
        }
        const location = {
          address: addresses[0].address,
          error: '',
          isFirstSelected: false,
          isPoi: false,
          lat,
          lon,
          poiGroupId: '',
          poiName: '',
          poiStopId: '',
          poiStopName: '',
          stopId: '',
          type: '',
        }
        if (placeType === 'setDeparturePlace') {
          store.commit('booking/setArrivalFirstSelected', true);
        } else {
          store.commit('booking/setDepartureFirstSelected', true);
        }
        await store.dispatch('booking/processQueryPlace', { placeType, location, queryParams }).then(() => {
          generateDateAndTime(queryParams, isOutwardDateFilled, isReturnDateFilled);
        })
      }
    }
  }
}

export const getData = async (queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled) => {
  if (uuidValidate(queryParams.addressFrom) && uuidValidate(queryParams.addressTo)) {
    setPlace(
      queryParams.addressFrom, 'setDeparturePlace', queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled,
    );
  } else if (!uuidValidate(queryParams.addressFrom) && !uuidValidate(queryParams.addressTo)) {
    if (queryParams.addressFrom) {
      setPlace(
        queryParams.addressFrom, 'setDeparturePlace', queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled,
      );
    }

    if (queryParams.addressTo) {
      setPlace(
        queryParams.addressTo, 'setArrivalPlace', queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled,
      );
    }
  } else {
    setPlace(
      queryParams.addressFrom, 'setDeparturePlace', queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled,
    );
    setPlace(
      queryParams.addressTo, 'setArrivalPlace', queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled,
    );
  }

  const dataToSet = {
    ...queryParams,
    flightOutwardDate: parseString(queryParams?.flightOutwardDate),
    flightOutwardTime: parseString(queryParams?.flightOutwardTime),
    flightReturnDate: parseString(queryParams?.flightReturnDate),
    flightReturnTime: parseString(queryParams?.flightReturnTime),
    midocoPassengers: getPassengerFromUrl(queryParams),
  }
  return dataToSet;
}

export const clearForm = (getIsMidocoAuth) => {
  store.commit('booking/clearBookingState');
  if (getIsMidocoAuth) {
    const passenger = {
      firstName: '',
      lastName: '',
      passengerType: '',
      phone: '',
      email: '',
    }
    store.commit('booking/addNewPassenger', passenger);
  }
  store.commit('booking/clearPhoneField', true);
}

export const processMidocoUrl = async (queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled) => {
  if (queryParams.auth_token) {
    store.commit('booking/setExtraLuggage', 0)
    const dataToSet = await getData(queryParams, pois, vm, isOutwardDateFilled, isReturnDateFilled).catch(() => {
      store.commit('common/setGlobalLoading', false);
      vm.$toasted.error(i18n.$t('general.somethingWentWrong'));
    })

    store.commit('booking/setDataForMidocoBooking', dataToSet);
    // case if no pickup and drop off locations
    if (!uuidValidate(queryParams.addressFrom) && !uuidValidate(queryParams.addressTo)) {
      store.commit('booking/generateOutwardRouteTaxiDate');
      store.commit('booking/generateReturnRouteTaxiDate');
    }
  } else {
    store.commit('booking/setExtraLuggage', 0)
  }
}
