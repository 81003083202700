import UserRetailerBookingsManagement from '@/services/UserRetailerBookingsManagement';
import PointOfInterstGroupManagement from '@/services/PointOfInterstGroupManagement';
import { BOOKING_STATUSES } from '@/utils/constants/booking';
import moment from 'moment';
import helpers from './helpers'

const actions = {
  async getBookings({ commit, state }) {
    const { limit } = state;
    const { offset } = state;
    let { sortField } = state;
    const { sortParams } = state;
    const { numericFilters } = state;
    const { stringFilters } = state;
    if (sortField && sortField === 'tripDate') {
      sortField = 'bookingDateTime';
    }
    commit('updateLoading', true);
    try {
      const { data } = await UserRetailerBookingsManagement.getBookings({
        limit,
        offset,
        sortField,
        ...(sortField && sortParams),
        ...(numericFilters.length && { numericFilters }),
        ...(stringFilters.length && { stringFilters }),
      });
      commit('setBookings', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('updateLoading', false);
    }
  },
  async updateBooking({ state, rootState }) {
    const booking = rootState.bookingEdit;
    const { retailerBookingId } = state.bookingForEdit;
    const { bookingStatus } = state.bookingForEdit;
    const { passengerDetails } = state.originalBookingDetails;

    passengerDetails[0] = {
      firstName: booking.passengerDetails.firstName,
      lastName: booking.passengerDetails.lastName,
      phone: booking.passengerDetails.phone,
      email: booking.passengerDetails.email,
    }

    let model = {
      mainPassengerDetails: {
        firstName: booking.passengerDetails.firstName,
        lastName: booking.passengerDetails.lastName,
        language: booking.passengerDetails.language, // doesn't update
        phone: booking.passengerDetails.phone,
        email: booking.passengerDetails.email,
        resendConfirmationEmail: booking.passengerDetails.resendConfirmationEmail,
      },
      tripComment: booking.outwardForm.comment,
      passengerDetails,
    }

    if (bookingStatus === BOOKING_STATUSES.AWAITING_VEHICLE) {
      const awaitingBookingModel = {
        flightNumber: booking.outwardForm.flightNumber,
        bookingDateTime: moment(`${booking.outwardForm.routeTaxiDate.date} ${booking.outwardForm.routeTaxiDate.time}`).valueOf(),
        flightDateTimeMillis: moment(`${booking.outwardForm.outwardDate.date} ${booking.outwardForm.outwardDate.time}`).valueOf(),
        adultsCount: booking.adultsCount,
        infantsCount: booking.infantsCount,
        featureCapacity: booking.extraLuggage,
        departureDateTime: !!booking.departurePlace.isFirstSelected,
      }
      awaitingBookingModel.stops = await helpers.generateStopsList(booking);
      model = {
        ...model,
        ...awaitingBookingModel,
      }
    }

    try {
      await UserRetailerBookingsManagement.updateBooking(retailerBookingId, model);
    } catch (e) {
      console.log(e);
    }
  },
  async getBooking({ commit }, id) {
    const { data: { pointOfInterestGroupList } } = await PointOfInterstGroupManagement.getAllPois(null);

    try {
      const { data } = await UserRetailerBookingsManagement.getBooking(id);
      commit('updateBookingForEdit', data);
      commit('setOriginalBookingDetails', data);
      const departure = data.departureDateTime
        ? data.extendedBookingStops.find((i) => i.poiType) : data.extendedBookingStops.find((i) => !i.poiType);
      const arrival = data.departureDateTime
        ? data.extendedBookingStops.find((i) => !i.poiType) : data.extendedBookingStops.find((i) => i.poiType);

      const departurePoiGroupId = pointOfInterestGroupList.find(
        (i) => i.pointsOfInterest.find((id) => id.pointOfInterestId === departure.poiId),
      );
      const arrivalPoiGroupId = pointOfInterestGroupList.find(
        (i) => i.pointsOfInterest.find((id) => id.pointOfInterestId === arrival.poiId),
      );

      const departurePlace = {
        address: departure?.address,
        error: '',
        isFirstSelected: !!data.departureDateTime,
        isPoi: !!departure.poiType,
        lat: departure.latitude,
        lon: departure.longitude,
        poiGroupId: departurePoiGroupId?.poiGroupId || '',
        poiName: '',
        poiStopId: departure.poiStopId ? departure.poiStopId : '',
        poiStopName: departure.poiName ? departure.poiName : '',
        stopId: '',
        type: departure?.poiType ? departure.poiType : '',
      }
      const arrivalPlace = {
        address: arrival?.address,
        error: '',
        isFirstSelected: !data.departureDateTime,
        isPoi: !!arrival.poiType,
        lat: arrival.latitude,
        lon: arrival.longitude,
        poiGroupId: arrivalPoiGroupId?.poiGroupId || '',
        poiName: '',
        poiStopId: arrival.poiStopId ? arrival.poiStopId : '',
        poiStopName: arrival.poiName ? arrival.poiName : '',
        stopId: '',
        type: arrival?.poiType ? arrival?.poiType : '',
      }
      commit('bookingEdit/setDeparturePlace', departurePlace, { root: true });
      commit('bookingEdit/setArrivalPlace', arrivalPlace, { root: true });

      const outwardTaxiDate = data.dateTime;
      const formatedTaxiDate = moment(outwardTaxiDate).format('YYYY-MM-DD, HH:mm');
      commit('bookingEdit/setOutwardTaxiDate', formatedTaxiDate.split(',')[0], { root: true });
      commit('bookingEdit/setOutwardTaxiTime', formatedTaxiDate.split(',')[1], { root: true });

      const outwardDate = data.round ? data?.flightReturnDateTimeMillis : data.flightOutwardDateTimeMillis;

      if (outwardDate) {
        const formatedDate = moment(outwardDate).format('YYYY-MM-DD, HH:mm');
        commit('bookingEdit/setOutwardDate', formatedDate.split(',')[0], { root: true });
        commit('bookingEdit/setOutwardTime', formatedDate.split(',')[1], { root: true });
        commit('bookingEdit/generateOutwardRouteTaxiDate',
          moment(`${formatedTaxiDate.split(',')[0]}${formatedTaxiDate.split(',')[1]}`),
          { root: true });
      }

      commit('bookingEdit/setOutwardFlightNumber', data?.round ? data?.flightReturnNumber || '' : data.flightNumber || '', { root: true });
      commit('bookingEdit/setOutwardComment', data.comment, { root: true });

      commit('bookingEdit/setAdults', data.adultsCount, { root: true });
      commit('bookingEdit/setChildren', data.infantsCount, { root: true });
      commit('bookingEdit/setExtraLuggage', data.featureCapacity, { root: true });

      commit('bookingEdit/setPassengerPhone', data.mainPassengerDetails.phone, { root: true });
      commit('bookingEdit/setPassengerFirstName', data.mainPassengerDetails.firstName, { root: true });
      commit('bookingEdit/setPassengerLastName', data.mainPassengerDetails.lastName, { root: true });
      commit('bookingEdit/setPassengerLanguage', data.mainPassengerDetails.language, { root: true });
      commit('bookingEdit/setPassengerEmail', data.mainPassengerDetails.email, { root: true });
      commit('bookingEdit/setPassengerEmailConfirmation', data.mainPassengerDetails.sendConfirmationEmail, { root: true });
      commit('bookingEdit/setResendPassengerEmailConfirmation', false, { root: true });
    } catch (e) {
      console.log(e)
    }
  },
};

export default actions;
