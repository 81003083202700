import { render, staticRenderFns } from "./BookingForm.vue?vue&type=template&id=2866c888&scoped=true"
import script from "./BookingForm.vue?vue&type=script&lang=js"
export * from "./BookingForm.vue?vue&type=script&lang=js"
import style0 from "./BookingForm.vue?vue&type=style&index=0&id=2866c888&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2866c888",
  null
  
)

export default component.exports