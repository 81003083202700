import {
  STripTypeEnum, SBookingRequestService, SReservationService,
} from '@slg/web-customer-shared';
import moment from 'moment';
import RetailerBookingReservationManagement from '@/services/RetailerBookingReservationManagement';
import { bookingDetailsEdit } from './bookingDetailsEdit/bookingDetailsEditState';

export default {
  namespaced: true,
  state: {
    ...bookingDetailsEdit,
  },
  mutations: {
    setArrivalPlace(state, data) {
      state.arrivalPlace.address = data.address;
      state.arrivalPlace.poiName = data.poiName;
      state.arrivalPlace.poiStopName = data.poiStopName;
      state.arrivalPlace.lat = data.lat;
      state.arrivalPlace.lon = data.lon;
      state.arrivalPlace.type = data.type;
      state.arrivalPlace.isPoi = data.isPoi;
      state.arrivalPlace.poiStopId = data.poiStopId;
      state.arrivalPlace.poiGroupId = data.poiGroupId;
      state.arrivalPlace.stopId = data.stopId;
      state.arrivalPlace.error = data.error;
      state.arrivalPlace.isFirstSelected = data.isFirstSelected;
    },
    clearArrivalPlace(state) {
      const keys = Object.keys(state.arrivalPlace);
      keys.forEach((key) => {
        state.arrivalPlace[key] = '';
      });
      if ((!state.departurePlace.address && !state.departurePlace.lat && !state.departurePlace.lon)
        || (state.departurePlace.address && state.departurePlace.lat && state.departurePlace.lon && state.departurePlace.isPoi)) {
        state.departurePlace.isFirstSelected = true;
        state.arrivalPlace.isFirstSelected = false;
      } else if (state.departurePlace.address && state.departurePlace.lat && state.departurePlace.lon && !state.departurePlace.isPoi) {
        state.departurePlace.isFirstSelected = false;
        state.arrivalPlace.isFirstSelected = true;
      }
    },
    setDeparturePlace(state, data) {
      state.departurePlace.address = data.address;
      state.departurePlace.poiName = data.poiName;
      state.departurePlace.poiStopName = data.poiStopName;
      state.departurePlace.lat = data.lat;
      state.departurePlace.lon = data.lon;
      state.departurePlace.type = data.type;
      state.departurePlace.isPoi = data.isPoi;
      state.departurePlace.poiStopId = data.poiStopId;
      state.departurePlace.poiGroupId = data.poiGroupId;
      state.departurePlace.stopId = data.stopId;
      state.departurePlace.error = data.error;
      state.departurePlace.isFirstSelected = data.isFirstSelected;
    },
    clearDeparturePlace(state) {
      const keys = Object.keys(state.departurePlace);
      keys.forEach((key) => {
        state.departurePlace[key] = '';
      })
      if ((!state.arrivalPlace.address && !state.arrivalPlace.lat && !state.arrivalPlace.lon)
        || (state.arrivalPlace.address && state.arrivalPlace.lat && state.arrivalPlace.lon && !state.arrivalPlace.isPoi)) {
        state.departurePlace.isFirstSelected = true;
        state.arrivalPlace.isFirstSelected = false;
      } else if (state.arrivalPlace.address && state.arrivalPlace.lat && state.arrivalPlace.lon && state.arrivalPlace.isPoi) {
        state.departurePlace.isFirstSelected = false;
        state.arrivalPlace.isFirstSelected = true;
      }
    },
    setRideType(state, rideType) {
      state.rideType = rideType;
    },
    populateBookingDetails(state, bookingDetails) {
      const keys = Object.keys(state.bookingDetails);
      keys.forEach((key) => {
        state.bookingDetails[key] = bookingDetails[key];
      });
    },
    clearBookingDetails(state) {
      state.bookingDetails = { ...bookingDetailsEdit };
    },
    setTripType(state, tripType) {
      state.tripType = tripType;
    },
    setAdults(state, number) {
      state.adultsCount = number;
    },
    setChildren(state, number) {
      state.infantsCount = number;
    },
    setExtraLuggage(state, number) {
      state.extraLuggage = number;
    },
    setFeatures(state, selectedFeatures) {
      state.selectedFeatures = selectedFeatures;
    },
    setDataForMidocoBooking(state, queryParams) {
      state.isMidocoBooking = true;
      state.adultsCount = queryParams.adults;
      state.infantsCount = queryParams.children;
      state.outwardForm.outwardDate.date = queryParams.flightOutwardDate;
      state.outwardForm.outwardDate.time = queryParams.flightOutwardTime;
      state.returnForm.returnDate.date = queryParams.flightReturnDate;
      state.returnForm.returnDate.time = queryParams.flightReturnTime;
      state.outwardForm.flightNumber = queryParams.flightOutwardNumber;
      state.returnForm.flightNumber = queryParams.flightDepartureNumber;
      state.orderId = queryParams.orderId;
      state.tripType = queryParams.roundTrip ? 'ROUND_TRIP' : 'ONE_WAY';
      state.midocoPassengers = queryParams.midocoPassengers;
    },
    setMidocoPassengerDetails(state, details) {
      state.midocoPassengers[details.index][details.fieldName] = details.val;
    },
    addNewPassenger(state, passenger) {
      state.midocoPassengers.push(passenger);
    },
    removePassenger(state, index) {
      state.midocoPassengers.splice(index, 1);
    },
    setFeatureAmount(state, data) {
      const targetFeatureIndex = state.selectedFeatures.findIndex(({ vehicleFeatureId }) => vehicleFeatureId === data.vehicleFeatureId);
      state.selectedFeatures[targetFeatureIndex].amount = data.amount;
    },
    setAnimalsTransportation(state, animalsTransportation) {
      state.animalsTransportation = animalsTransportation;
    },
    setVehicleCategoryId(state, vehicleCategoryId) {
      state.vehicleCategoryId = vehicleCategoryId;
    },
    setVehicleCategoryLocalizedName(state, localizedName) {
      state.vehicleCategoryLocalizedName = localizedName;
    },
    setOutwardDate(state, data) {
      state.outwardForm.outwardDate.date = data;
    },
    setReturnDate(state, data) {
      state.returnForm.returnDate.date = data;
    },
    setReturnTime(state, data) {
      state.returnForm.returnDate.time = data;
    },
    setOutwardTime(state, data) {
      state.outwardForm.outwardDate.time = data;
    },
    setOutwardTaxiDate(state, data) {
      state.outwardForm.routeTaxiDate.date = data;
    },
    setOutwardTaxiTime(state, data) {
      state.outwardForm.routeTaxiDate.time = data;
    },
    setReturnTaxiTime(state, data) {
      state.returnForm.routeTaxiDate.time = data;
    },
    setReturnTaxiDate(state, data) {
      state.returnForm.routeTaxiDate.date = data;
    },
    setOutwardFlightNumber(state, data) {
      state.outwardForm.flightNumber = data;
    },
    setReturnFlightNumber(state, data) {
      state.returnForm.flightNumber = data;
    },
    setOutwardComment(state, data) {
      state.outwardForm.comment = data;
    },
    setReturnComment(state, data) {
      state.returnForm.comment = data;
    },
    populateRideList(state, rideList) {
      state.rideList = rideList;
    },
    setCommentForOutward(state, comment) {
      state.commentForOutward = comment;
    },
    setCommentForReturn(state, comment) {
      state.commentForReturn = comment;
    },
    setPaymentType(state, type) {
      state.checkoutInfo.paymentType = type;
    },
    setPaymentMethod(state, paymentMethod) {
      state.checkoutInfo.paymentMethod = paymentMethod;
    },
    setFlightDetails(state, details) {
      state.checkoutInfo.flightDetails = details;
    },
    setPassengerPhone(state, phone) {
      state.passengerDetails.phone = phone;
    },
    setPassengerFirstName(state, firstName) {
      state.passengerDetails.firstName = firstName;
    },
    setPassengerLastName(state, lastName) {
      state.passengerDetails.lastName = lastName;
    },
    setPassengerEmail(state, email) {
      state.passengerDetails.email = email;
    },
    setPassengerLanguage(state, language) {
      state.passengerDetails.language = language;
    },
    setPassengerEmailConfirmation(state, confirmationEmail) {
      state.passengerDetails.confirmationEmail = confirmationEmail;
    },
    setResendPassengerEmailConfirmation(state, resendConfirmationEmail) {
      state.passengerDetails.resendConfirmationEmail = resendConfirmationEmail;
    },
    clearPhoneField(state, value) {
      state.clearPhoneField = value;
    },
    setAnotherPassenger(state, isAnotherPassenger) {
      state.checkoutInfo.passengerDetails.isAnotherPassenger = isAnotherPassenger;
      state.checkoutInfo.passengerDetails.phone = '';
      state.checkoutInfo.passengerDetails.firstName = '';
      state.checkoutInfo.passengerDetails.lastName = '';
      state.checkoutInfo.passengerDetails.email = '';
    },
    populateReservation(state, reservationDetails) {
      const keys = Object.keys(state.reservation);
      keys.forEach((key) => {
        state.reservation[key] = reservationDetails[key] || null;
      });
    },
    clearReservation(state) {
      const keys = Object.keys(state.reservation);
      keys.forEach((key) => {
        state.reservation[key] = null;
      });
    },
    setLoggedInPassengerDetails({ checkoutInfo }, passengerDetails) {
      checkoutInfo.passengerDetails.isAnotherPassenger = false;
      checkoutInfo.passengerDetails.phone = passengerDetails.phone;
      checkoutInfo.passengerDetails.firstName = passengerDetails.firstName;
      checkoutInfo.passengerDetails.lastName = passengerDetails.lastName;
      checkoutInfo.passengerDetails.email = passengerDetails.email;
    },
    clearDates({ outwardDate, returnDate }) {
      outwardDate.type = '';
      outwardDate.time = '';
      outwardDate.date = '';
      returnDate.type = '';
      returnDate.time = '';
      returnDate.date = '';
    },
    clearOptions(state) {
      state.animalsTransportation = false;
      state.rideType = '';
      state.tripType = 'ONE_WAY';
      state.vehicleCategoryId = '';
      state.adults = 1;
      state.children = 0;
      state.selectedFeatures = [];
    },
    clearBookingState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(bookingDetailsEdit)));
    },
    generateOutwardRouteTaxiDate(state, prefilledDate) {
      const minPickUpIntervalAfterFlight = this.state.settingsConfiguration.minPickIpIntervalAfterFlightLandingTime;
      const minDropOffIntervalBeforeFlight = this.state.settingsConfiguration.minDropOffIntervalBeforeFlightDepartureTime;
      const { bookingMinutesStep } = this.state.settingsConfiguration;
      const date = moment(new Date(`${state.outwardForm.outwardDate.date} ${state.outwardForm.outwardDate.time}`));
      const currentRouteTaxiDate = prefilledDate
      || moment(new Date(`${state.outwardForm.routeTaxiDate.date} ${state.outwardForm.routeTaxiDate.time}`));
      // Process intervals for booking step
      const intervals = [];
      for (let i = 0; i < 60; i += 1) {
        if (i % bookingMinutesStep === 0) {
          intervals.push(i);
        }
      }

      if (state.arrivalPlace.isFirstSelected) {
        date.subtract(minDropOffIntervalBeforeFlight, 'minutes');
        if ((!intervals.some((i) => i === Number(date.minutes())))) {
          let currentMinutes = date.minutes();
          for (let i = date.minutes(); i < 61; i += 1) {
            currentMinutes -= 1;
            if (currentMinutes % Number(bookingMinutesStep) === 0) {
              date.minute(Number(currentMinutes));
              break;
            }
          }
        }
      } else {
        date.add(minPickUpIntervalAfterFlight, 'minutes');
        if ((!intervals.some((i) => i === Number(date.minutes())))) {
          for (let i = date.minutes(); i < 61; i += 1) {
            if (i % Number(bookingMinutesStep) === 0) {
              date.minute(Number(i));
              break;
            }
          }
        }
      }

      if (state.departurePlace.isFirstSelected) {
        if (currentRouteTaxiDate.isSameOrAfter(date)) {
          state.outwardForm.routeTaxiDate.date = currentRouteTaxiDate.format('YYYY-MM-DD');

          if (intervals.includes(+currentRouteTaxiDate.format('mm'))) {
            state.outwardForm.routeTaxiDate.time = currentRouteTaxiDate.format('HH:mm');
          } else {
            for (let i = currentRouteTaxiDate.minutes(); i < 61; i += 1) {
              if (intervals.includes(i)) {
                currentRouteTaxiDate.minute(Number(i));
                break;
              }
            }
            state.outwardForm.routeTaxiDate.time = currentRouteTaxiDate.format('HH:mm');
          }
        } else {
          state.outwardForm.routeTaxiDate.date = date.format('YYYY-MM-DD');
          state.outwardForm.routeTaxiDate.time = date.format('HH:mm');
        }
      } else if (state.arrivalPlace.isFirstSelected) {
        if (currentRouteTaxiDate.isSameOrBefore(date)) {
          state.outwardForm.routeTaxiDate.date = currentRouteTaxiDate.format('YYYY-MM-DD');
          if (intervals.includes(+currentRouteTaxiDate.format('mm'))) {
            state.outwardForm.routeTaxiDate.time = currentRouteTaxiDate.format('HH:mm');
          } else {
            state.outwardForm.routeTaxiDate.time = date.format('HH:mm');
          }
        } else {
          state.outwardForm.routeTaxiDate.date = date.format('YYYY-MM-DD');
          state.outwardForm.routeTaxiDate.time = date.format('HH:mm');
        }
      }
      // Always fill nearest allowed date/time
      state.outwardForm.routeTaxiDate.nearestAllowedDate = date.format('YYYY-MM-DD');
      state.outwardForm.routeTaxiDate.nearestAllowedTime = date.format('HH:mm');
    },
    generateReturnRouteTaxiDate(state, prefilledDate) {
      const minPickUpIntervalAfterFlight = this.state.settingsConfiguration.minPickIpIntervalAfterFlightLandingTime;
      const minDropOffIntervalBeforeFlight = this.state.settingsConfiguration.minDropOffIntervalBeforeFlightDepartureTime;
      const { bookingMinutesStep } = this.state.settingsConfiguration;
      const date = moment(new Date(`${state.returnForm.returnDate.date} ${state.returnForm.returnDate.time}`));
      const currentReturnRouteTaxiTime = prefilledDate
       || moment(new Date(`${state.returnForm.routeTaxiDate.date} ${state.returnForm.routeTaxiDate.time}`));
      // Process intervals for booking step
      const intervals = [];
      for (let i = 0; i < 60; i += 1) {
        if (i % bookingMinutesStep === 0) {
          intervals.push(i);
        }
      }

      if (state.arrivalPlace.isFirstSelected) {
        date.add(minPickUpIntervalAfterFlight, 'minutes');
        if ((!intervals.some((i) => i === Number(date.minutes())))) {
          for (let i = date.minutes(); i < 61; i += 1) {
            if (i % Number(bookingMinutesStep) === 0) {
              date.minute(Number(i));
              break;
            }
          }
        }
      } else {
        date.subtract(minDropOffIntervalBeforeFlight, 'minutes');
        if ((!intervals.some((i) => i === Number(date.minutes())))) {
          let currentMinutes = date.minutes();
          for (let i = date.minutes(); i < 61; i += 1) {
            currentMinutes -= 1;
            if (currentMinutes % Number(bookingMinutesStep) === 0) {
              date.minute(Number(currentMinutes));
              break;
            }
          }
        }
      }

      if (state.departurePlace.isFirstSelected) {
        if (currentReturnRouteTaxiTime.isSameOrBefore(date)) {
          state.returnForm.routeTaxiDate.date = currentReturnRouteTaxiTime.format('YYYY-MM-DD');
          if (intervals.includes(+currentReturnRouteTaxiTime.format('mm'))) {
            state.returnForm.routeTaxiDate.time = currentReturnRouteTaxiTime.format('HH:mm');
          } else {
            for (let i = currentReturnRouteTaxiTime.minutes(); i < 61; i += 1) {
              if (intervals.includes(i)) {
                currentReturnRouteTaxiTime.minute(Number(i));
                break;
              }
            }
            state.returnForm.routeTaxiDate.time = currentReturnRouteTaxiTime.format('HH:mm');
          }
        } else {
          state.returnForm.routeTaxiDate.date = date.format('YYYY-MM-DD');
          state.returnForm.routeTaxiDate.time = date.format('HH:mm');
        }
      } else if (state.arrivalPlace.isFirstSelected) {
        if (currentReturnRouteTaxiTime.isSameOrAfter(date)) {
          state.returnForm.routeTaxiDate.date = currentReturnRouteTaxiTime.format('YYYY-MM-DD');
          if (intervals.includes(+currentReturnRouteTaxiTime.format('mm'))) {
            state.returnForm.routeTaxiDate.time = currentReturnRouteTaxiTime.format('HH:mm');
          } else {
            for (let i = currentReturnRouteTaxiTime.minutes(); i < 61; i += 1) {
              if (intervals.includes(i)) {
                currentReturnRouteTaxiTime.minute(Number(i));
                break;
              }
            }
            state.returnForm.routeTaxiDate.time = currentReturnRouteTaxiTime.format('HH:mm');
          }
        } else {
          state.returnForm.routeTaxiDate.date = date.format('YYYY-MM-DD');
          state.returnForm.routeTaxiDate.time = date.format('HH:mm');
        }
      }
      // Always fill nearest allowed date/time
      state.returnForm.routeTaxiDate.nearestAllowedDate = date.format('YYYY-MM-DD');
      state.returnForm.routeTaxiDate.nearestAllowedTime = date.format('HH:mm');
    },
    setArrivalFirstSelected(state, data) {
      state.arrivalPlace.isFirstSelected = data;
    },
    setDepartureFirstSelected(state, data) {
      state.departurePlace.isFirstSelected = data;
    },
  },
  actions: {
    async setBookingDetails({ commit }, bookingCreateModel) {
      const response = await SBookingRequestService.bookingCreate(bookingCreateModel);
      commit('populateBookingDetails', response.data);
    },
    async setTemporaryReservation({ commit }, bookingRequestId) {
      const now = new Date().valueOf();
      const response = await SReservationService.reservationCreate(bookingRequestId);
      response.data.now = now;
      commit('populateTemporaryReservation', response.data);
    },
    async cancelTemporaryReservation({ commit, state }) {
      if (state.reservation.reservationId) {
        try {
          const model = {
            reservationId: state.reservation.reservationId,
            tenantShortName: state.reservation.tenantShortName,
          }
          await RetailerBookingReservationManagement.cancelReservation(model)
        } catch (_error) {
          // do nothing
        } finally {
          commit('clearReservation');
        }
      } else {
        commit('clearReservation');
      }
    },
  },
  getters: {
    getArrivalPlace: (state) => state.arrivalPlace,
    isArrivalPlaceFilled: ({ arrivalPlace }) => !!(arrivalPlace.address && arrivalPlace.lat && arrivalPlace.lon),
    isArrivalPlacePoi: ({ arrivalPlace }) => !!(arrivalPlace.isPoi),
    getDeparturePlace: (state) => state.departurePlace,
    isDeparturePlaceFilled: ({ departurePlace }) => !!(departurePlace.address && departurePlace.lat && departurePlace.lon),
    isDeparturePlacePoi: ({ departurePlace }) => !!(departurePlace.isPoi),
    getAtLeastOnePlaceIsPoi: ({ departurePlace, arrivalPlace }) => departurePlace.isPoi || arrivalPlace.isPoi,
    getRideType: (state) => state.rideType,
    getTripType: (state) => state.tripType,
    isRoundTrip: (state) => state.tripType === STripTypeEnum.ROUND_TRIP,
    getOutwardForm: (state) => state.outwardForm,
    getReturnForm: (state) => state.returnForm,
    getArrivalFirstSelected: (state) => state.arrivalPlace.isFirstSelected,
    getDepartureFirstSelected: (state) => state.departurePlace.isFirstSelected,
    isOutwardDateFilled: ({ outwardForm }) => !!(outwardForm.outwardDate.date && outwardForm.outwardDate.time),
    isReturnDateFilled: ({ returnForm }) => !!(returnForm.returnDate.date && returnForm.returnDate.time),
    isPickUpRide: (state) => (state.departurePlace.isFirstSelected ? true : !state.arrivalPlace.isFirstSelected),
    getAdults: (state) => state.adultsCount,
    getChildren: (state) => state.infantsCount,
    getExtraLuggage: (state) => state.extraLuggage,
    getPassengerDetailsState: (state) => state.passengerDetails,
    getDaysDifferenceBetweenTodayAndOutward: (state) => Math.abs(moment()
      .diff(moment(`${state.outwardForm.outwardDate.date} ${state.outwardForm.outwardDate.time}`), 'days')),
    getDaysDifferenceBetweenTodayAndReturn: (state) => Math.abs(moment()
      .diff(moment(`${state.returnForm.returnDate.date} ${state.returnForm.returnDate.time}`), 'days')),
    getBookingForm: (state) => state,
    getReservation: (state) => state.reservation,
    getIsMidocoBooking: (state) => state.isMidocoBooking,
    getMidocoPassengers: (state) => state.midocoPassengers,
    getClearPhoneFieldStatus: (state) => state.clearPhoneField,
  },
}
