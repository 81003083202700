<template>
  <div>
    <ServicesSelection />
    <div
      v-for="(item, index) in getMidocoPassengers"
      :key="`passenger-${index}`"
      class="passenger-wrapper mb-2"
    >
      <ValidationObserver>
        <div class="subtitle-1 pb-4 d-flex justify-content-between">
          <div>
            {{ $t('bookingForm.midocoPassenger.passenger', { number: index + 1 }) }}
            <CustomBtnToggle
              :value="item.passengerType"
              :items="getPassengerTypeItems"
              @change="(val) => setPassengerDetails(val, 'passengerType', index)"
            />
          </div>
          <div>
            <VIcon
              v-if="getMidocoPassengers.length !== 1"
              color="textPrimary"
              @click="removePassenger(item.passengerType, index)"
            >
              mdi-delete
            </VIcon>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <ValidationProvider
            :ref="`firstName-${index}`"
            v-slot="{ errors }"
            :name="`firstName-${index}`"
            :rules="{ required: true }"
            mode="aggressive"
          >
            <VTextField
              :value="item.firstName"
              :hide-details="!errors.length"
              :label="`${$t('bookingForm.midocoPassenger.firstName')} *`"
              :error="!!errors.length"
              :error-messages="$t(errors[0])"
              @input="(val) => setPassengerDetails(val, 'firstName', index)"
            />
          </ValidationProvider>
          <ValidationProvider
            :ref="`lastName-${index}`"
            v-slot="{ errors }"
            :name="`lastName-${index}`"
            :rules="{ required: true }"
            mode="aggressive"
          >
            <VTextField
              :value="item.lastName"
              :hide-details="!errors.length"
              :label="`${$t('bookingForm.midocoPassenger.lastName')} *`"
              :error="!!errors.length"
              :error-messages="$t(errors[0])"
              @input="(val) => setPassengerDetails(val, 'lastName', index)"
            />
          </ValidationProvider>
        </div>
        <div
          v-if="!index"
          class="phone-wrapper"
        >
          <ValidationProvider
            :ref="`passengerPhone${index}`"
            v-slot="{ errors }"
            name="passengerPhone"
            :rules="{ phoneNumber: { isValidPhoneNumber: isValidPhoneNumber[index] }, required: true }"
            :allowFalse="false"
            mode="eager"
          >
            <vue-tel-input-vuetify
              :ref="`phonePassengerInput`"
              v-phone-restrict
              :value="item.phone"
              class="body-1 pb-0 "
              :class="{ 'mb-5': !!errors.length, }"
              :loading="isLoadingCountries"
              :defaultCountry="getCountryCode(item.phone)"
              disabledFetchingCountry
              :error="!!errors.length"
              :error-messages="$t(errors[0])"
              :placeholder="`${$t('bookingForm.passenger.enterPhoneNumber')}*`"
              :mode="'international'"
              :label="`${$t('bookingForm.passenger.phone')}*`"
              @input="onInput(index)"
            />
          </validationprovider>
        </div>
        <div v-if="!index">
          <VAutocomplete
            :items="getLangList"
            :value="item.language"
            hideNoData
            itemText="text"
            class="pb-0"
            :filter="e => e"
            hideDetails
            itemValue="value"
            :label="`${$t('bookingForm.midocoPassenger.customerLanguage')} *`"
            returnObject
            @change="(val) => setPassengerDetails(val.value, 'language', index)"
          />
        </div>
        <div
          v-if="!index"
          class="pt-2"
        >
          <ValidationProvider
            :ref="`email${index}`"
            v-slot="{ errors }"
            name="email"
            :rules="item.sendConfirmationEmail ? { email: true, required: { allowFalse: false } } : { email: true }"
            mode="aggressive"
          >
            <VTextField
              :value="item.email"
              :hide-details="!errors.length"
              :label="item.sendConfirmationEmail ? `${$t('authForm.email')} *` : $t('authForm.email')"
              :error="!!errors.length"
              :error-messages="$t(errors[0])"
              @input="(val) => setPassengerDetails(val, 'email', index)"
            />
          </ValidationProvider>
        </div>
        <div
          v-if="!index"
          class="send-confirmation-checkbox"
        >
          <VCheckbox
            v-model="item.sendConfirmationEmail"
            color="brandingPrimary"
            :label="$t('bookingForm.midocoPassenger.sendConfirmationEmail')"
            @change="(val) => setPassengerDetails(val, 'sendConfirmationEmail', index)"
          />
        </div>
      </ValidationObserver>
    </div>
    <VBtn
      :disabled="getMidocoPassengers.length >= getMaximumPassengersPerBooking"
      class="w-100 border-radius-8"
      dark
      large
      color="pageBg"
      @click="addPassenger"
    >
      <VIcon color="textPrimary">
        mdi-plus
      </VIcon>
      <span class="button  textPrimary--text">
        {{ $t('bookingForm.midocoPassenger.addPassenger') }}
      </span>
    </VBtn>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomBtnToggle from '@slg/web-customer-shared/src/components/common/CustomBtnToggle.vue';
import { PhoneNumberUtil } from 'google-libphonenumber';
import ServicesSelection from '../passengersAndServices/servicesSelection/ServicesSelection.vue';

export default {
  name: 'MidocoPassengers',
  components: {
    CustomBtnToggle,
    ServicesSelection,
  },
  data() {
    return {
      isLoadingCountries: false,
      isValidPhoneNumber: {},
    };
  },
  computed: {
    ...mapGetters('settingsConfiguration', ['getMaximumPassengersPerBooking']),
    ...mapGetters('booking', [
      'isRoundTrip',
      'getDaysDifferenceBetweenTodayAndOutward',
      'getDaysDifferenceBetweenTodayAndReturn',
      'getMidocoPassengers',
      'getAdults',
      'getChildren',
    ]),
    ...mapGetters('auth', ['getLocation']),
    getPassengerTypeItems() {
      return [
        {
          text: this.$t('bookingForm.midocoPassenger.adult'),
          value: 'adult',
        },
        {
          text: this.$t('bookingForm.midocoPassenger.child'),
          value: 'child',
        },
      ];
    },
    getLangList() {
      return [
        {
          text: this.$t('bookingForm.midocoPassenger.languages.en'),
          value: 'en',
        },
        {
          text: this.$t('bookingForm.midocoPassenger.languages.fr'),
          value: 'fr',
        },
        {
          text: this.$t('bookingForm.midocoPassenger.languages.de'),
          value: 'de',
        },
      ];
    },
  },
  watch: {
    getMidocoPassengers: {
      deep: true,
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal.length !== oldVal?.length) {
          this.isValidPhoneNumber = newVal.map(() => false);
        } else if (!this.isValidPhoneNumber.length) {
          this.isValidPhoneNumber = [false];
        }
      },
    },
  },
  methods: {
    getCountryCode(phone) {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const formattedPhone = phoneUtil.parseAndKeepRawInput(phone);
        return phoneUtil.getRegionCodeForNumber(formattedPhone);
      } catch (_e) {
        return this.getLocation || 'LU';
      }
    },
    removePassenger(passengerType, index) {
      this.$store.commit('booking/removePassenger', index);

      if (passengerType === 'adult') {
        this.$store.commit('booking/setAdults', this.getAdults - 1);
      } else {
        this.$store.commit('booking/setChildren', this.getChildren - 1);
      }
    },
    setPassengerDetails(val, fieldName, index) {
      this.$store.commit('booking/setMidocoPassengerDetails', {
        val,
        fieldName,
        index,
      });

      if (fieldName === 'passengerType') {
        if (this.getAdults || val === 'adult') {
          this.$store.commit('booking/setAdults', val === 'adult' ? this.getAdults + 1 : this.getAdults - 1);
        }
        if (this.getChildren || val === 'child') {
          this.$store.commit('booking/setChildren', val === 'adult' ? this.getChildren - 1 : this.getChildren + 1);
        }
      }
    },
    async onInput(index) {
      this.$nextTick(async () => {
        this.setPassengerDetails(
          this.$refs?.phonePassengerInput[index]?.phoneObject.number?.input,
          'phone',
          index,
        );
        this.isValidPhoneNumber = [
          ...this.isValidPhoneNumber.map((val, i) => {
            if (i === index) {
              return this.$refs?.phonePassengerInput[index]?.phoneObject?.isValid;
            }
            return val;
          }),
        ];
      });
    },
    addPassenger() {
      const passenger = {
        firstName: '',
        lastName: '',
        passengerType: 'adult',
        phone: '',
        email: '',
      };
      this.$store.commit('booking/addNewPassenger', passenger);
      this.$store.commit('booking/setAdults', this.getAdults + 1);
    },
  },
};
</script>

<style scoped lang="scss">
.passenger-wrapper {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 16px;

  .phone-wrapper {
    margin: -16px 0 -20px 0;
  }

  .send-confirmation-checkbox {
    margin: -16px 0 -48px 0;
  }
}
</style>
