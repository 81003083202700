<template>
  <CustomDateSelect
    :date="date"
    :time="time"
    :isDialogOpen="isDialogOpen"
    :minDate="minDateSelect"
    :maxDate="maxDateSelect"
    :minTime="minTime"
    :maxTime="maxTime"
    :isOutward="true"
    :dialogTitle="isPickUpRide ? $t('bookingForm.outward.pickUp') : $t('bookingForm.outward.dropOff')"
    :isRouteTaxi="true"
    @submitSelect="submitSelect"
    @closeDialog="closeDialog"
    @updateTime="updateTime"
    @updateDate="updateDate"
  />
</template>

<script>
import moment from 'moment';
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import CustomDateSelect from '@/components/common/CustomDateSelect.vue';
import TimeConverter from '@/utils/timeConvertor/timeConverter';
import { mapGetters } from 'vuex';

export default {
  name: 'RouteTaxiDateSelect',
  components: {
    CustomDateSelect,
  },
  props: {
    outwardState: {
      type: Object,
      default: null,
    },
    outwardTaxiState: {
      type: Object,
      default: null,
    },
    returnTime: {
      type: String,
      default: null,
    },
    returnDate: {
      type: String,
      default: null,
    },
    isRoundTrip: {
      type: Boolean,
      default: false,
    },
    defaultMaxDate: {
      type: String,
      default: null,
    },
    outwardDate: {
      type: String,
      default: null,
    },
    defaultMinDate: {
      type: String,
      default: null,
    },
    dateNow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      isOutwardNow: false,
      time: null,
      date: null,
    };
  },
  computed: {
    ...mapGetters('settingsConfiguration', ['getBookingMinutesStep']),
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
    }),
    ...mapGetters({
      getArrivalFirstSelectedCreate: 'booking/getArrivalFirstSelected',
      getDepartureFirstSelectedCreate: 'booking/getDepartureFirstSelected',
      getOutwardFormCreate: 'booking/getOutwardForm',
      isPickUpRideCreate: 'booking/isPickUpRide',
    }),
    ...mapGetters({
      getArrivalFirstSelectedEdit: 'bookingEdit/getArrivalFirstSelected',
      getDepartureFirstSelectedEdit: 'bookingEdit/getDepartureFirstSelected',
      getOutwardFormEdit: 'bookingEdit/getOutwardForm',
      isPickUpRideEdit: 'bookingEdit/isPickUpRide',
    }),
    getArrivalFirstSelected() {
      return this.bookingEdit ? this.getArrivalFirstSelectedEdit : this.getArrivalFirstSelectedCreate;
    },
    getDepartureFirstSelected() {
      return this.bookingEdit ? this.getDepartureFirstSelectedEdit : this.getDepartureFirstSelectedCreate;
    },
    getOutwardForm() {
      return this.bookingEdit ? this.getOutwardFormEdit : this.getOutwardFormCreate;
    },
    isPickUpRide() {
      return this.bookingEdit ? this.isPickUpRideEdit : this.isPickUpRideCreate;
    },
    isOutwardAndTaxiSameDay() {
      return this.outwardState.date === this.date;
    },
    isTaxiDateSameDateOutward() {
      let date;
      if (!this.isPickUpRide) {
        date = TimeConverter.subtractDateTime(this.outwardState.date, this.outwardState.time, this.getBookingMinutesStep, 'm');
      } else {
        date = TimeConverter.addDateTime(this.outwardState.date, this.outwardState.time, this.getBookingMinutesStep, 'm');
      }
      return moment(date.format(SConstants.TIME_FORMAT.YYYY_MM_DD)).isSame(
        moment(this.date),
        'day',
      );
    },
    minDateSelect() {
      if (this.getDepartureFirstSelected && this.outwardState.date) {
        return this.outwardState.date;
      }
      if (this.getArrivalFirstSelected && this.outwardState.date) {
        return this.defaultMinDate;
      }
      return this.defaultMinDate;
    },
    maxDateSelect() {
      if (this.getDepartureFirstSelected && this.outwardState.date) {
        return this.defaultMaxDate;
      }
      if (this.getArrivalFirstSelected && this.outwardState.date) {
        return this.outwardState.date;
      }
      return this.outwardTaxiState.date;
    },
    getDefaultMinTime() {
      const minTime = this.dateNow.clone();
      return TimeConverter.convertDateToDefaultMinTimeFormat(minTime)
        === this.date
        ? minTime
          .format(SConstants.TIME_FORMAT.HH_mm)
        : null;
    },
    minTime() {
      if (this.isPickUpRide) {
        if (this.isOutwardAndTaxiSameDay || this.isTaxiDateSameDateOutward) {
          return this.outwardTaxiState.nearestAllowedTime
        }
        return null;
      }
      return this.getDefaultMinTime
    },
    maxTime() {
      if (!this.isPickUpRide) {
        if (this.isOutwardAndTaxiSameDay || this.isTaxiDateSameDateOutward) {
          return this.outwardTaxiState.nearestAllowedTime
        }
      }
      return null;
    },
  },
  created() {
    const { date, time } = this.outwardTaxiState;
    this.date = date;
    this.time = time;
  },
  methods: {
    updateDate(newValue) {
      this.date = newValue;
    },
    updateTime(newValue) {
      this.time = newValue;
    },
    submitSelect() {
      const outwardRouteTaxiDate = {
        date: this.date,
        time: this.time,
      };
      this.$emit('setOutwardRouteTaxiDate', outwardRouteTaxiDate);
      this.$emit('closeDialog');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
};
</script>
<style lang="scss" scoped></style>
