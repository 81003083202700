<template>
  <div>
    <DirectionSelection />
    <TripTypeSelection v-if="showTripType" />
  </div>
</template>
<script>
import DirectionSelection from './directionSelection/DirectionSelection.vue';
import TripTypeSelection from './tripTypeSelection/TripTypeSelection.vue';

export default {
  name: 'RideDetails',
  components: {
    DirectionSelection,
    TripTypeSelection,
  },
  props: {
    showTripType: {
      type: Boolean,
      default: true,
    },
    showRideType: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
