<template>
  <CustomDateSelect
    :date="date"
    :time="time"
    :isDialogOpen="isDialogOpen"
    :minDate="minDateSelect"
    :maxDate="maxDateSelect"
    :minTime="minTime"
    :maxTime="maxTime"
    :isOutward="true"
    :dialogTitle="isPickUpRide ? $t('bookingForm.outward.dropOff') : $t('bookingForm.outward.pickUp')"
    :pickerDate="getPickerDate"
    :isRouteTaxi="true"
    @submitSelect="submitSelect"
    @closeDialog="closeDialog"
    @updateTime="updateTime"
    @updateDate="updateDate"
    @onChangePickerDate="onChangePickerDate"
  />
</template>

<script>
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import CustomDateSelect from '@/components/common/CustomDateSelect.vue';
import { mapGetters } from 'vuex';
import TimeConverter from '@/utils/timeConvertor/timeConverter';
import moment from 'moment';

export default {
  name: 'ReturnTaxiDateSelect',
  components: {
    CustomDateSelect,
  },
  props: {
    outwardState: {
      type: Object,
      default: null,
    },
    returnState: {
      type: Object,
      default: null,
    },
    returnTaxiState: {
      type: Object,
      default: null,
    },
    returnTime: {
      type: String,
      default: null,
    },
    returnDate: {
      type: String,
      default: null,
    },
    defaultMaxDate: {
      type: String,
      default: null,
    },
    outwardDate: {
      type: String,
      default: null,
    },
    defaultMinDate: {
      type: String,
      default: null,
    },
    dateNow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      isOutwardNow: false,
      time: null,
      date: null,
      pickerDate: null,
    };
  },
  computed: {
    ...mapGetters('booking', ['getArrivalFirstSelected', 'getDepartureFirstSelected', 'getOutwardForm', 'getReturnForm', 'isPickUpRide']),
    ...mapGetters('settingsConfiguration', ['getBookingMinutesStep']),
    isReturnAndTaxiSameDay() {
      return this.returnState.date === this.date;
    },
    getPickerDate() {
      return this.pickerDate || this.getReturnForm.returnDate.date || this.getOutwardForm.outwardDate.date || this.minDateSelect || null;
    },
    isTaxiDateSameDateReturnd() {
      let date;
      if (!this.isPickUpRide) {
        date = TimeConverter.addDateTime(this.returnState.date, this.returnState.time, this.getBookingMinutesStep, 'm');
      } else {
        date = TimeConverter.subtractDateTime(this.returnState.date, this.returnState.time, this.getBookingMinutesStep, 'm');
      }
      return moment(date.format(SConstants.TIME_FORMAT.YYYY_MM_DD)).isSame(
        moment(this.date),
        'day',
      );
    },
    minDateSelect() {
      if (this.getDepartureFirstSelected && this.returnState.date) {
        return this.defaultMinDate;
      }
      if (this.getArrivalFirstSelected && this.returnState.date) {
        return this.returnState.date;
      }
      return this.defaultMinDate;
    },
    maxDateSelect() {
      if (this.getDepartureFirstSelected && this.returnState.date) {
        return this.returnState.date;
      }
      if (this.getArrivalFirstSelected && this.returnState.date) {
        return this.defaultMaxDate;
      }
      return this.returnTaxiState.date;
    },
    getDefaultMinTime() {
      const minTime = this.dateNow.clone();
      return TimeConverter.convertDateToDefaultMinTimeFormat(minTime)
        === this.date
        ? minTime
          .format(SConstants.TIME_FORMAT.HH_mm)
        : null;
    },
    minTime() {
      if (this.isPickUpRide) {
        return this.getDefaultMinTime;
      }
      if (this.isReturnAndTaxiSameDay || this.isTaxiDateSameDateReturnd) {
        return this.returnTaxiState.nearestAllowedTime
      }
      return null;
    },
    maxTime() {
      if (this.isPickUpRide) {
        if (this.isReturnAndTaxiSameDay || this.isTaxiDateSameDateReturnd) {
          return this.returnTaxiState.time;
        }
        return null;
      }
      return null
    },
  },
  created() {
    const { date, time } = this.returnTaxiState;
    this.date = date;
    this.time = time;
  },
  methods: {
    updateDate(newValue) {
      this.date = newValue;
    },
    updateTime(newValue) {
      this.time = newValue;
    },
    submitSelect() {
      const returndRouteTaxiDate = {
        date: this.date,
        time: this.time,
      };
      this.$emit('setReturndRouteTaxiDate', returndRouteTaxiDate);
      this.$emit('closeDialog');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    onChangePickerDate(data) {
      this.pickerDate = data;
    },
  },
};
</script>
<style lang="scss" scoped></style>
