import PoiTypeEnum from '@slg/web-customer-shared/src/enum/PoiTypeEnum';
import i18n from '@/plugins/i18n';

export const generatePoiList = () => ({
  AIRPORT: {
    icon: 'airport',
    hasImage: true,
    type: PoiTypeEnum.AIRPORT,
    title: i18n.t('poiTypes.airports'),
    items: [],
  },
  BUS_STATION: {
    icon: 'bus-station',
    hasImage: true,
    type: PoiTypeEnum.BUS_STATION,
    title: i18n.t('poiTypes.busStations'),
    items: [],
  },
  OTHER: {
    type: PoiTypeEnum.OTHER,
    hasImage: false,
    icon: 'mdi-map-marker',
    title: i18n.t('poiTypes.places'),
    items: [],
  },
  TRAIN_STATION: {
    type: PoiTypeEnum.TRAIN_STATION,
    icon: 'train-station',
    hasImage: true,
    title: i18n.t('poiTypes.trainStations'),
    items: [],
  },
  ACTIVITIES: {
    icon: 'activities',
    hasImage: true,
    type: PoiTypeEnum.ACTIVITIES,
    title: i18n.t('poiTypes.activities'),
    items: [],
  },
  BUS_STOP: {
    icon: 'bus-stop',
    hasImage: true,
    type: PoiTypeEnum.BUS_STOP,
    title: i18n.t('poiTypes.busStop'),
    items: [],
  },
  DAY_CARE_CENTER: {
    type: PoiTypeEnum.DAY_CARE_CENTER,
    icon: 'day-care-center',
    hasImage: true,
    title: i18n.t('poiTypes.dayCareCenter'),
    items: [],
  },
  LEISURE: {
    type: PoiTypeEnum.LEISURE,
    icon: 'leisure',
    hasImage: true,
    title: i18n.t('poiTypes.leisure'),
    items: [],
  },
  PRIVATE: {
    icon: 'mdi-map-marker',
    hasImage: false,
    type: PoiTypeEnum.PRIVATE,
    title: i18n.t('poiTypes.private'),
    items: [],
  },
  PUBLIC_ADMINISTRATIONS: {
    icon: 'public-administration',
    hasImage: true,
    type: PoiTypeEnum.PUBLIC_ADMINISTRATIONS,
    title: i18n.t('poiTypes.publicAdministrations'),
    items: [],
  },
  SCHOOL_AND_SPORT_COMPLEX: {
    type: PoiTypeEnum.SCHOOL_AND_SPORT_COMPLEX,
    icon: 'school-and-sport-center',
    hasImage: true,
    title: i18n.t('poiTypes.schoolAndSportComplex'),
    items: [],
  },
  SENIOR_CITIZENS_MEETING: {
    type: PoiTypeEnum.SENIOR_CITIZENS_MEETING,
    icon: 'senior-citizen',
    hasImage: true,
    title: i18n.t('poiTypes.seniorCitizensMeeting'),
    items: [],
  },
  SHOPPING: {
    icon: 'shopping',
    type: PoiTypeEnum.SHOPPING,
    hasImage: true,
    title: i18n.t('poiTypes.shopping'),
    items: [],
  },
  WORKSHOP_FOR_DISABLED_PEOPLE: {
    icon: 'workshop-for-disabled-people',
    hasImage: true,
    type: PoiTypeEnum.WORKSHOP_FOR_DISABLED_PEOPLE,
    title: i18n.t('poiTypes.workshopForDisabledPeople'),
    items: [],
  },
})
