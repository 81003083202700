var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{ref:"datesSelection"},[_c('ValidationObserver',{ref:"dateForm",attrs:{"name":"dateForm"},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('ValidationProvider',{ref:"date",attrs:{"name":"date","rules":{ required: true },"mode":"eager"}},[_c('VDatePicker',{class:_vm.isCustomHeightDatePickerHeight ? 'date-picker body-1' : '',attrs:{"value":_vm.date,"full-width":"","show-current":false,"data-test":"datePicker","locale":_vm.getCurrentLang,"scrollable":"","calendar-class":"my-datepicker_calendar","picker-date":_vm.pickerDate,"firstDayOfWeek":"1","allowedDates":_vm.allowedDates,"min":_vm.minDate,"max":_vm.maxDate,"color":"brandingPrimary"},on:{"update:picker-date":_vm.onChangePickerDate,"change":_vm.updateDate}},[_vm._t("customDefaultHeader",function(){return [(!_vm.date)?_c('span',{staticClass:"date__picker-custom-header"},[_vm._v(_vm._s(_vm.dialogTitle ? _vm.dialogTitle : _vm.$t("bookingForm.outward.selectFlightDate"))+" ")]):_vm._e()]}),_c('VBtn',{staticClass:"close-button mx-2",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('VIcon',[_vm._v(" mdi-close ")])],1)],2)],1),(_vm.date)?[_c('VRow',{staticClass:"dialog-row pl-10"},[_c('VCol',{staticClass:"p-0",attrs:{"cols":"10"}},[_c('VMenu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-top":180,"nudge-right":18,"value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"276px","min-width":"276px"},on:{"change":_vm.updateTime},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ValidationProvider',{ref:"minutesInput",attrs:{"name":"minutesInput","mode":"aggressive","rules":{
                  required: true,
                  timeInInterval: {
                    time: _vm.pickerTimeInMinutes,
                    ranges: [],
                  },
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VTextField',_vm._g(_vm._b({attrs:{"value":_vm.pickerTime,"label":_vm.$t('bookingForm.outward.selectTime'),"data-test":"timeTextField","prepend-icon":"mdi-clock-outline","hide-details":!errors.length,"error":!!errors.length,"errorMessages":_vm.$t(errors[0]),"readonly":""}},'VTextField',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.timePickerMenuOpen),callback:function ($$v) {_vm.timePickerMenuOpen=$$v},expression:"timePickerMenuOpen"}},[(_vm.timePickerMenuOpen)?_c('VTimePicker',{attrs:{"format":"24hr","color":"brandingPrimary","data-test":"timePicker","allowed-hours":_vm.allowedHours,"allowed-minutes":_vm.allowedMinutes,"min":_vm.minTime,"max":_vm.maxTime,"full-width":""},on:{"click:minute":_vm.updateMinutesPicker,"click:hour":_vm.updateTime},model:{value:(_vm.pickerTime),callback:function ($$v) {_vm.pickerTime=$$v},expression:"pickerTime"}}):_vm._e()],1)],1)],1)]:_vm._e(),_vm._t("customBottom",function(){return [_c('VRow',{staticClass:"ma-0 dialog-row px-6 pb-4"},[_c('VBtn',{staticClass:"button",class:{ disabled: invalid },attrs:{"color":"brandingPrimary","width":"100%","data-test":"setDateAndTimeButton"},on:{"click":_vm.submitSelect}},[_c('span',{staticClass:"textWhite--text"},[_vm._v(" "+_vm._s(_vm.$t("bookingForm.outward.select"))+" ")])])],1)]},{"invalid":invalid})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }