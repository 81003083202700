<template>
  <div class="footer" />
</template>

<script>
export default {
  name: 'Footer',
  components: {},
};
</script>
<style lang="scss" scoped>
.footer {
  position: sticky;
  bottom: 0%;
  height: 80px;
  background: #FFFFFF;
}
</style>
