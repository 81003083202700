const RIDE_TYPES = {
  PRIVATE: 'PRIVATE',
  ONE_WAY: 'ONE_WAY',
}

const PASSENGER_TYPES = {
  ADULT: 'adult',
  INFANT: 'infant',
}

const ERROR_CODES = {
  RETAILER_BOOKING_STOP_WITH_ADDRESS_NOT_EXISTS: 'RETAILER_BOOKING_STOP_WITH_ADDRESS_NOT_EXISTS',
}

const BOOKING_STATUSES = {
  BOOKED: 'BOOKED',
  AWAITING_VEHICLE: 'AWAITING_VEHICLE',
}

export {
  RIDE_TYPES, PASSENGER_TYPES, ERROR_CODES, BOOKING_STATUSES,
}
