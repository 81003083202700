<template>
  <VCard
    :max-height="$vuetify.breakpoint.xs ? '100vh' : undefined"
    :height="!$vuetify.breakpoint.xs ? '80vh' : undefined"
    class="d-flex flex-column"
    :class="{
      'overflow-auto': $vuetify.breakpoint.xs,
      'overflow-hidden': !$vuetify.breakpoint.xs,
    }"
  >
    <template #progress>
      <Spinner />
    </template>
    <ScrollButtons
      v-if="$vuetify.breakpoint.xs"
      :selectedPoiId="selectedPoiId"
      :selectedPoiType="selectedPoiType"
      :directionContainerScrollPosition="directionContainerScrollPosition"
      @scrollToPoiSelection="(type, poi, position) => scrollToPoiSelection(type, poi, position)"
      @scrolToTop="scrolToTop"
    />
    <VCardTitle
      v-show="!isLoading && isMapReady"
      class="p-0 box-shadow-elevation-4 position-relative z-index-1000"
    >
      <Stepper
        class="px-1"
        :currentStep="currentStep"
        :steps="getSteps"
        @onStepClick="changeStep"
      />
      <VBtn
        class="close-icon mr-3 grey-lighten-4 box-shadow-elevation-6"
        icon
        @click="closeDialog"
      >
        <VIcon color="textPrimary">
          mdi-close
        </VIcon>
      </VBtn>
    </VCardTitle>
    <VCardText
      v-show="!isLoading && isMapReady"
      id="direction-dialog-container"
      ref="directionDialogContainer"
      v-scroll.self="onScroll"
      class="p-0 shadow-none flex-grow-1 overflow-auto"
    >
      <AddressSelection
        ref="addressSelection"
        :key="currentStep"
        :isLoading="isLoading"
        :error="error"
        :poiList="poiList"
        :pickedLocation="getSelectedLocation"
        :opsZoneList="opsZoneList"
        :poiListWithoutGrouping="poiListWithoutGrouping"
        :currentStep="currentStep"
        :isDeparturePlaceFilled="getIsDeparturePlaceFilled"
        :isArrivalPlaceFilled="getIsArrivalPlaceFilled"
        :additionalHeightForMap="additionalHeightForMap"
        :isDeparturePlacePoi="isDeparturePlacePoi"
        :isArrivalPlacePoi="isArrivalPlacePoi"
        @setLocation="setLocation"
        @updatePoiType="updatePoiType"
        @updatePoiId="updatePoiId"
        @recalculateHeight="recalculateHeight"
      />
    </VCardText>
    <DirectionDialogSkeletonLoader
      v-if="isLoading || !isMapReady"
      @closeDialog="$emit('closeDialog')"
    />
  </VCard>
</template>

<script>
import Stepper from './stepper/Stepper.vue';
import Spinner from './Spinner.vue';
import AddressSelection from './directionDialog/AddressSelection.vue';
import ScrollButtons from './directionDialog/ScrollButtons.vue';
import DirectionDialogSkeletonLoader from './DirectionDialogSkeletonLoader.vue';

export default {
  name: 'DirectionDialog',
  components: {
    DirectionDialogSkeletonLoader,
    AddressSelection,
    Stepper,
    Spinner,
    ScrollButtons,
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    currentStep: {
      type: String,
      default: 'pickUp',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDeparturePlacePoi: {
      type: Boolean,
      default: false,
    },
    isArrivalPlacePoi: {
      type: Boolean,
      default: false,
    },
    getArrivalPlace: {
      type: Object,
      default: () => ({}),
    },
    getDeparturePlace: {
      type: Object,
      default: () => ({}),
    },
    poiList: {
      type: Object,
      default: () => ({}),
    },
    getIsArrivalPlaceFilled: {
      type: Boolean,
      default: false,
    },
    getIsDeparturePlaceFilled: {
      type: Boolean,
      default: false,
    },
    poiListWithoutGrouping: {
      type: Array,
      default: () => ([]),
    },
    opsZoneList: {
      type: Array,
      default: () => ([]),
    },
    stepsMeta: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      directionContainerScrollPosition: 0,
      selectedPoiId: null,
      selectedPoiType: null,
      isMapReady: true,
      additionalHeightForMap: 0,
    }
  },
  computed: {
    getSteps() {
      return [{
        title: this.$t('bookingForm.rideDetails.pickUpAddress'),
        subTitle: this.getDeparturePlace?.address,
        isComplete: this.getIsDeparturePlaceFilled,
        value: this.stepsMeta.pickUp,
      },
      {
        title: this.$t('bookingForm.rideDetails.dropOffAddress'),
        subTitle: this.getArrivalPlace?.address,
        isComplete: this.getIsArrivalPlaceFilled,
        value: this.stepsMeta.dropOff,
      }]
    },
    getSelectedLocation() {
      if (this.currentStep === this.stepsMeta.pickUp) {
        return this.getDeparturePlace;
      }
      return this.getArrivalPlace;
    },
  },
  watch: {
    isLoading: {
      handler(newVal) {
        if (!newVal) {
          this.$refs.addressSelection.mapKey = new Date().toString();
        }
      },
    },
  },
  created() {
    this.$emit('loadState');
    this.selectedPoiId = this.getSelectedLocation.poiStopId;
    this.selectedPoiType = this.getSelectedLocation.type;
  },
  mounted() {
    this.$watch(
      () => this.$refs.addressSelection.$refs.map.isMapReady,
      (val) => {
        this.isMapReady = val;
        this.$refs.addressSelection.mapKey = new Date().toString();
      },
    );
  },
  methods: {
    async recalculateHeight() {
      this.additionalHeightForMap = this.$refs.directionDialogContainer.clientHeight
        - this.$refs.addressSelection.$refs.addressSelectionWrapper.clientHeight;
    },
    updatePoiType(poiType) {
      this.selectedPoiType = poiType;
    },
    updatePoiId(poiId) {
      this.selectedPoiId = poiId;
    },
    onScroll() {
      this.directionContainerScrollPosition = this.$refs.directionDialogContainer.scrollTop;
      this.$refs.addressSelection.$refs.map.isFocused = this.directionContainerScrollPosition === 0;
    },
    scrollToPoiSelection(type, poi, position = 'center') {
      this.$refs.addressSelection.scrollToPoiSelection(type, poi, position);
    },
    scrolToTop() {
      this.$refs.directionDialogContainer.scroll({ top: 0, behavior: 'smooth' });
    },
    invalidateMapSize() {
      this.$refs.addressSelection.invalidateMapSize();
    },
    changeStep(step, opsZoneIdsList = []) {
      this.$emit('changeStep', { step, opsZoneIdsList });
    },
    setLocation(location) {
      this.$emit('setLocation', location);
      this.selectedPoiId = null;
    },
    closeDialog() {
      this.$refs.addressSelection.setLocation();
      this.$emit('closeDialog');
    },
  },
}
</script>

<style scoped lang="scss">
.close-icon {
  z-index: 10;
  position: absolute;
  right: 0;
}
</style>
