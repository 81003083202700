<template>
  <div
    data-test="directionSelectionSkeletonLoader"
    :class="isMobile ? 'skeleton-wrapper-mobile d-flex flex-column' : 'skeleton-wrapper'"
  >
    <div
      class="d-flex py-2 box-shadow-elevation-4"
      :class="isMobile ? '' : 'justify-content-between'"
    >
      <div
        class="d-flex"
        :class="isMobile ? 'w-100' : 'w-50'"
      >
        <VSkeletonLoader
          class="w-100"
          type="list-item-avatar"
        />
        <VSkeletonLoader
          class="w-100"
          type="list-item-avatar"
        />
      </div>
      <div>
        <VBtn
          class="close-icon mr-3 grey-lighten-4 box-shadow-elevation-6"
          icon
          @click="$emit('closeDialog')"
        >
          <VIcon color="textPrimary">
            mdi-close
          </VIcon>
        </VBtn>
      </div>
    </div>
    <div
      class="d-flex h-100"
      :class="isMobile ? 'd-flex flex-column' : ''"
    >
      <VSkeletonLoader
        :class="isMobile ? 'w-100 img-skeleton-xs' : 'w-75 img-skeleton-lg'"
        type="image"
      />
      <div
        class="d-flex flex-column justify-content-between"
        :class="isMobile ? 'w-100' : 'w-25'"
      >
        <div>
          <VSkeletonLoader type="card-heading" />
          <VSkeletonLoader type="card-heading" />
          <VSkeletonLoader type="card-heading" />
        </div>
        <div>
          <VSkeletonLoader
            class="mb-16"
            type="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DirectionDialogSkeletonLoader',
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
}
</script>
<style lang="scss" scoped>
.skeleton-wrapper {
    height: 80vh;
}

::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading.img-skeleton-xs  {
     .v-skeleton-loader__image {
        height: 428px !important;
        width: 100%;
     }

    .v-skeleton-loader__heading {
        width: unset !important;
    }
}

::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading.img-skeleton-lg {
     .v-skeleton-loader__image {
        height: 100% !important;
     }

    .v-skeleton-loader__heading {
        width: unset !important;
    }
}

::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
     .v-skeleton-loader__image {
        height: 100% !important;
     }

    .v-skeleton-loader__heading {
        width: unset !important;
    }
}

.skeleton-wrapper-mobile {
    height: 100%;
}
</style>
