<template>
  <VAutocomplete
    data-test="findAddressField"
    :items="addressList"
    :loading="isLoading"
    :searchInput="selectedAddress"
    hideNoData
    solo
    clearable
    hideSelected
    itemText="address"
    class="pb-0"
    :filter="e => e"
    hideDetails
    itemValue="id"
    :placeholder="$t('bookingForm.rideDetails.searchAddressTitle')"
    :append-icon="addressList.length < 2 ? null : $dropdown"
    prependInnerIcon="mdi-magnify"
    returnObject
    @update:search-input="searchAddress"
    @change="selectAddress"
  />
</template>

<script>
import { determiningSelectedQuery, getGeoCodeByGoogleApi } from '@slg/web-customer-shared/src/providers/MapProvider';

export default {
  name: 'AddressSearch',
  props: {
    selectedAddress: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addressList: [],
      isLoading: false,
      search: '',
      cancelSource: null,
    }
  },
  watch: {
    search(e) {
      this.searchAddress(e)
    },
  },
  methods: {
    async selectAddress(data) {
      if (!data) {
        return;
      }
      if (data.isGoogleApi) {
        const { lat, lon } = await getGeoCodeByGoogleApi(data.id);
        const dataToSet = {
          lat,
          lon,
          id: data.id,
          address: data.address,
        }
        this.$emit('selectAddress', dataToSet);
        return;
      }

      const dataToSet = {
        lat: data.geocode[1],
        lon: data.geocode[0],
        address: data.address,
      }
      this.$emit('selectAddress', dataToSet);
    },
    async searchAddress(value) {
      if (!value) {
        this.addressList = [];
        return;
      }
      this.isLoading = true;

      // if (this.cancelSource) {
      //   this.cancelSource.cancel();
      // }
      // this.cancelSource = axios.CancelToken.source();

      this.addressList = await determiningSelectedQuery(value);
      this.isLoading = false;
    },
  },
}
</script>

<style lang="scss">
@import "@slg/web-customer-shared/src/style/variables.scss";

.v-list-item__title {
  font-size: $fs-14 !important;
  .v-list-item__mask {
    font-weight: $fw-700 !important;
    background: transparent !important;
    color: var(--v-textPrimary-base) !important;
  }
}
</style>
