<template>
  <ValidationObserver
    ref="bookingForm"
    name="bookingForm"
    class="w-100"
    tag="form"
  >
    <VForm
      class="booking-form d-flex justify-content-between align-items-start"
      @submit.prevent="onSubmit"
    >
      <VCard
        class="p-4 direction-selection-card"
        :class="{ 'midoco-auth': getIsMidocoBooking }"
      >
        <div class="heading-5 pb-8">
          {{ $t('bookingForm.rideDetails.title') }}
        </div>
        <RideDetails />
      </VCard>
      <div class="dates-selection-wrapper">
        <VCard class="p-4 direction-selection-card">
          <div class="heading-5 pb-8">
            {{ $t('bookingForm.outward.title') }}
          </div>
          <OutwardTrip />
        </VCard>
        <VCard
          v-if="isRoundTrip"
          class="p-4 direction-selection-card mt-6"
        >
          <div class="heading-5 pb-8">
            {{ $t('bookingForm.return.title') }}
          </div>
          <ReturnTrip />
        </VCard>
      </div>
      <div class="features-passengers-selection-wrapper">
        <VCard
          v-if="getIsMidocoAuth"
          class="midoco-passengers-wrapper p-4 mb-6"
        >
          <div class="heading-5 pb-8">
            {{ $t('bookingForm.passengersAndServices.title') }}
          </div>
          <MidocoPassengers />
        </VCard>
        <template v-else>
          <VCard class="p-4 mb-6">
            <div class="heading-5 pb-8">
              {{ $t('bookingForm.passengersAndServices.title') }}
            </div>
            <PassengersAndServices />
          </VCard>
          <VCard class="p-4 mb-6">
            <div class="heading-5 pb-8">
              {{ $t('bookingForm.passenger.title') }}
            </div>
            <PassengerInfo />
          </VCard>
        </template>
      </div>
    </VForm>
    <BookingFormFooter
      @clearForm="clearForm(getIsMidocoAuth)"
      @findVehicle="findVehicle"
      @bookNow="bookNow"
    />
  </ValidationObserver>
</template>

<script>
import {
  SGlobalErrorDialogMixin,
} from '@slg/web-customer-shared';
import { mapGetters } from 'vuex';
import { getQuery } from 'queryzz';
import PointOfInterstGroupManagement from '@/services/PointOfInterstGroupManagement';
import UserRetailerBookingManagement from '@/services/UserRetailerBookingManagement';
import RetailerBookingReservationManagement from '@/services/RetailerBookingReservationManagement';
import { execute } from '@/utils/asyncTaskManagement';
import RideDetails from './rideDetails/RideDetails.vue';
import OutwardTrip from './outwardTrip/OutwardTrip.vue';
import ReturnTrip from './returnTrip/ReturnTrip.vue';
import PassengersAndServices from './passengersAndServices/PassengersAndServices.vue';
import MidocoPassengers from './midocoPassengers/MidocoPassengers.vue';
import PassengerInfo from './passengerInfo/PassengerInfo.vue';
import BookingFormFooter from './bookingFormFooter/BookingFormFooter.vue';
import {
  buildCreateReservationModel,
  buildCreateBookingModel,
  buildCalculatePriceModelForReturn,
  buildBookingCreateModelForOutward,
  buildAwaitingBookingModelForReturn,
  generateDateAndTime,
  clearForm,
  onSuccessfulBookingRequest,
  onErrorBookingRequest,
  onSuccessfulPriceCalculation,
  onErrorPriceCalculation,
  onSuccessfulBookingCreate,
  onErrorBookingCreate,
  onSuccessfulBookingPriceForReturn,
  onErrorBookingPriceForReturn,
  processMidocoUrl,
} from './bookingForm.logic';

export default {
  name: 'BookingForm',
  components: {
    RideDetails,
    OutwardTrip,
    ReturnTrip,
    PassengersAndServices,
    MidocoPassengers,
    PassengerInfo,
    BookingFormFooter,
  },
  mixins: [
    SGlobalErrorDialogMixin,
  ],
  data() {
    return {
      clearForm,
      showSearchResultDialog: false,
      pois: null,
    };
  },
  computed: {
    ...mapGetters('settingsConfiguration', ['getMaximumPassengersPerBooking']),
    ...mapGetters('booking',
      [
        'isRoundTrip',
        'getDaysDifferenceBetweenTodayAndOutward',
        'getDaysDifferenceBetweenTodayAndReturn',
        'getIsMidocoBooking',
        'getMidocoPassengers',
        'getMidocoOrderId',
        'isOutwardDateFilled',
        'isReturnDateFilled',
        'getMidocoPassengers',
      ]),
    ...mapGetters('auth', ['getIsMidocoAuth']),
  },
  async created() {
    this.$store.commit('common/setGlobalLoading', true);
    try {
      await this.$store.dispatch('settingsConfiguration/populateSettingsConfiguration');
      const { data } = await PointOfInterstGroupManagement.getAllPois(null);
      this.pois = data;
      const queryParams = getQuery();
      await processMidocoUrl(queryParams, this.pois, this, this.isOutwardDateFilled, this.isReturnDateFilled);
    } catch (e) {
      this.$store.commit('common/setGlobalLoading', false);
    }
    this.$store.commit('common/setGlobalLoading', false);
    generateDateAndTime(getQuery(), this.isOutwardDateFilled, this.isReturnDateFilled);
  },
  methods: {
    findVehicle() {
      if (this.getIsMidocoAuth) {
        if (!this.getMidocoOrderId) {
          this.$toasted.error(this.$i18n.t('bookingForm.midocoOrderIdNotFound'))
          return;
        }

        if (this.getMidocoPassengers.length > this.getMaximumPassengersPerBooking) {
          this.$toasted.error(this.$i18n.t('validations.maxLimitForStandardSeats', { number: this.getMaximumPassengersPerBooking }))
          return;
        }
      }

      this.$refs.bookingForm.validate().then(async (success) => {
        if (!success) {
          return;
        }
        this.$nextTick(async () => {
          this.$store.commit('common/setGlobalLoading', true);
          try {
            const task = {
              executionStage: 'PROCESSING',
              delay: 5000,
            }

            if (this.isRoundTrip) {
              if (this.getDaysDifferenceBetweenTodayAndOutward < 14 && this.getDaysDifferenceBetweenTodayAndReturn < 14) {
                const model = await buildCreateReservationModel();
                const { data: { taskId } } = await RetailerBookingReservationManagement.createReservation(model);
                task.taskId = taskId;
                await execute(task, onSuccessfulBookingRequest, onErrorBookingRequest, this);
              } else if (this.getDaysDifferenceBetweenTodayAndOutward >= 14 && this.getDaysDifferenceBetweenTodayAndReturn >= 14) {
                const model = await buildCreateReservationModel();
                const { data: { taskId } } = await UserRetailerBookingManagement.calculatePrice(model);
                task.taskId = taskId;
                await execute(task, onSuccessfulPriceCalculation, onErrorPriceCalculation, this);
              } else {
                // if outward date is earlier than 14 days from today and return date is later
                const modelForReturn = await buildCalculatePriceModelForReturn();
                const response = await UserRetailerBookingManagement.calculatePrice(modelForReturn);
                const taskForReturn = { ...task };
                taskForReturn.taskId = response.data.taskId;
                await execute(taskForReturn, onSuccessfulBookingPriceForReturn, onErrorBookingPriceForReturn, this);
              }
            } else if (this.getDaysDifferenceBetweenTodayAndOutward < 14) {
              const model = await buildCreateReservationModel();
              const { data: { taskId } } = await RetailerBookingReservationManagement.createReservation(model);
              task.taskId = taskId;
              await execute(task, onSuccessfulBookingRequest, onErrorBookingRequest, this);
            } else if (this.getDaysDifferenceBetweenTodayAndOutward >= 14) {
              const model = await buildCreateReservationModel();
              const { data: { taskId } } = await UserRetailerBookingManagement.calculatePrice(model);
              task.taskId = taskId;
              await execute(task, onSuccessfulPriceCalculation, onErrorPriceCalculation, this);
            }
          } catch (e) {
            this.$toasted.error(this.$i18n.t('general.somethingWentWrong'))
          }
        });
      });
    },
    async bookNow() {
      this.$refs.bookingForm.validate().then(async (success) => {
        if (!success) {
          return;
        }
        this.$store.commit('common/setGlobalLoading', true);
        try {
          const task = {
            executionStage: 'PROCESSING',
            delay: 5000,
          }
          if (this.isRoundTrip) {
            if (this.getDaysDifferenceBetweenTodayAndOutward < 14 && this.getDaysDifferenceBetweenTodayAndReturn < 14) {
              const model = await buildCreateBookingModel();
              const { data: { taskId } } = await UserRetailerBookingManagement.createBooking(model);
              task.taskId = taskId;
              await execute(task, onSuccessfulBookingCreate, onErrorBookingCreate, this);
            } else if (this.getDaysDifferenceBetweenTodayAndOutward >= 14 && this.getDaysDifferenceBetweenTodayAndReturn >= 14) {
              const model = await buildCreateBookingModel();
              await UserRetailerBookingManagement.createAwaitingBooking(model);
              this.$store.commit('booking/clearReservation');
              this.$store.commit('common/setGlobalLoading', false);
              this.$router.push({ name: 'Bookings list' });
              if (this.isRoundTrip) {
                this.$toasted.success(this.$i18n.t('bookingForm.bookingsAreSuccessfullyCreated'))
              } else {
                this.$toasted.success(this.$i18n.t('bookingForm.bookingIsSuccessfullyCreated'))
              }
            } else {
              const modelForReturn = await buildAwaitingBookingModelForReturn(true);
              await UserRetailerBookingManagement.createAwaitingBooking(modelForReturn).then(async () => {
                const modelForOutward = await buildBookingCreateModelForOutward();
                const { data: { taskId } } = await UserRetailerBookingManagement.createBooking(modelForOutward);
                task.taskId = taskId;
                await execute(task, onSuccessfulBookingCreate, onErrorBookingCreate, this);
              });
              this.$store.commit('booking/clearReservation');
            }
          } else if (this.getDaysDifferenceBetweenTodayAndOutward < 14) {
            const model = await buildCreateBookingModel();
            const { data: { taskId } } = await UserRetailerBookingManagement.createBooking(model);
            task.taskId = taskId;
            await execute(task, onSuccessfulBookingCreate, onErrorBookingCreate, this);
          } else if (this.getDaysDifferenceBetweenTodayAndOutward >= 14) {
            const model = await buildCreateBookingModel();
            await UserRetailerBookingManagement.createAwaitingBooking(model);
            this.$store.commit('common/setGlobalLoading', false);
            this.$store.commit('booking/clearReservation');
            this.$router.push({ name: 'Bookings list' });
            if (this.isRoundTrip) {
              this.$toasted.success(this.$i18n.t('bookingForm.bookingsAreSuccessfullyCreated'))
            } else {
              this.$toasted.success(this.$i18n.t('bookingForm.bookingIsSuccessfullyCreated'))
            }
          }
        } catch (e) {
          this.$toasted.error(this.$i18n.t('general.somethingWentWrong'))
        }
      });
    },
  },
}
</script>

<style scoped>
.booking-form {
  position: relative;
  height: 100vh;
}

.v-form>.direction-selection-card {
  width: 32%;
}

.v-form>.dates-selection-wrapper {
  width: 32%;
}

.features-passengers-selection-wrapper {
  width: 32%;
}

.midoco-auth ::v-deep .swap-button {
  top: 45% !important;
}

.midoco-passengers-wrapper {
  overflow-y: auto;
  max-height: 910px;
}
</style>
