<template>
  <VCard class="poi-selection-container p-3 overflow-auto shadow-none">
    <template v-for="(item, key) in poiList">
      <VCard
        v-if="item.items.length"
        :key="key"
        :data-test="`${item.type}-card`"
        class="p-0 box-shadow-elevation-2 poi-group-container"
      >
        <VCardTitle
          :ref="key"
          :data-test="`${item.type}-title`"
          class="py-3 cursor-pointer d-flex"
          @click="changePoiSelectedTypeView(item.type)"
        >
          <div>
            <img
              v-if="item.hasImage"
              :src="require(`@slg/web-customer-shared/src/assets/img/${item.icon}.svg`)"
              alt="...poi"
            >
            <VIcon
              v-else
              color="brandingSecondary"
            >
              {{ item.icon }}
            </VIcon>
          </div>
          <div class="poi-title ml-4">
            <template v-for="(word, index) in splitTitle(item.title)">
              <span
                v-if="index < splitTitle(item.title).length - 1"
                :key="`${word}-${index}`"
                class="body-1"
              >
                {{ word }}
              </span>
              <span
                v-else
                :key="`${word}-${index}`"
                class="body-1 text-nowrap"
              >
                {{ word }} ({{ item.items.length }})
              </span>
            </template>
          </div>
          <VSpacer />
          <div>
            <VIcon>{{ selectedPoiType === item.type ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</VIcon>
          </div>
        </VCardTitle>
        <VExpandTransition>
          <VCardText
            v-show="selectedPoiType === item.type"
            class="p-0"
          >
            <div
              v-for="(stopItem, stopKey) in item.items"
              :id="!stopItem.deep && `poi-${stopItem.subItems[0].id}`"
              :ref="stopItem.poiId"
              :key="stopKey"
              class="poi-item-container px-4"
              :class="selectedPoi.address === stopItem.address && 'poi-item--active'"
              @click="poiSelect(stopItem)"
            >
              <div
                class="poi-item-main-info-container py-2"
              >
                <div>
                  <div class="name">
                    <span class="body-1 textPrimary--text">
                      {{ stopItem.name }}
                    </span>
                  </div>
                  <div class="address">
                    <span
                      class="body-2 textTertiary--text"
                    >
                      {{ stopItem.address }}
                    </span>
                  </div>
                </div>
                <VExpandTransition>
                  <div
                    v-if="selectedPoi.address === stopItem.address && stopItem.deep"
                    class="sub-items-list-container d-flex flex-column pt-2"
                  >
                    <div class="sub-items-list-title">
                      <span class="textSecondary--text body-2 text-uppercase">
                        {{ $t('bookingForm.selectSuitableStop') }}
                      </span>
                      <VRadioGroup
                        :value="selectedPoiStopId"
                        @change="data => $emit('updateSelectedPoiStopId', data)"
                      >
                        <VRadio
                          v-for="(subItem, subItemKey) in stopItem.subItems"
                          :id="`poi-stop-${subItem.id}`"
                          :key="subItemKey"
                          :ref="subItem.id"
                          class="pt-1"
                          :value="subItem.id"
                          color="brandingPrimary"
                          @click="$emit('selectPlace', subItem)"
                        >
                          <template #label>
                            <div class="d-flex flex-column">
                              <div>
                                <span class="body-1 textPrimary--text">
                                  {{ subItemKey + 1 }}. {{ subItem.name }}
                                </span>
                              </div>
                              <div class="pt-1">
                                <span class="caption textTertiary--text text-wrap text-ellipsis-2-line">
                                  {{ subItem.description }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </VRadio>
                      </VRadioGroup>
                    </div>
                    <div />
                  </div>
                </VExpandTransition>
              </div>
            </div>
          </VCardText>
        </VExpandTransition>
      </VCard>
    </template>
  </VCard>
</template>
<script>

export default {
  name: 'PoiSelection',
  props: {
    poiList: {
      type: Object,
      default: () => {},
    },
    selectedPoi: {
      type: Object,
      default: () => {},
    },
    selectedPoiStopId: {
      type: String,
      default: '',
    },
    selectedPoiType: {
      type: String,
      default: '',
    },
    directionContainerScrollPosition: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    splitTitle(title) {
      return title.split(/(\s+)/);
    },
    changePoiSelectedTypeView(poiSectionType) {
      const newValue = this.selectedPoiType === poiSectionType ? '' : poiSectionType;
      this.$emit('updateSelectedPoiType', newValue);
    },
    poiSelect(stopItem) {
      if (this.selectedPoi.address !== stopItem.address) {
        this.$emit('updateSelectedPoiStopId', stopItem.subItems[0]);
      }
      this.$emit('selectPlace', stopItem.subItems[0]);
    },
  },
}
</script>

<style scoped lang="scss">
.poi-item-container {
  &:hover{
    background: var(--v-grey-base);
    cursor: pointer;
  }
}

.scroll-up-button {
  z-index: 3;
  left: 50%;
}

.scroll-down-button {
  z-index: 3;
  left: 50%;
}

.poi-group-container {
  border-radius: 0 !important;
  margin-top: 1px !important;

  .v-card__title {
    flex-wrap: nowrap !important;
  }

  &:last-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}

.poi-item--active {
  position: relative;
  &:hover{
    background: transparent;
    cursor: default;
  }
  &::before {
    content: "";
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .12;
    background-color: var(--v-brandingPrimary-base) !important;
  }
}
</style>
