import UserServiceConfigurationSettingsManagement from '@/services/UserServiceConfigurationSettingsManagement';

export default {
  namespaced: true,
  state: {
    bookingMinutesStep: 20,
    extraLuggageFeaturePrice: 0,
    extraLuggageMaximumQuantityPerBooking: 0,
    maximumPassengersPerBooking: 0,
    minDropOffIntervalBeforeFlightDepartureTime: 0,
    minPickIpIntervalAfterFlightLandingTime: 0,
    settingId: '',
  },
  mutations: {
    setSettingsConfiguration(state, data) {
      const keys = Object.keys(state);
      keys.forEach((key) => {
        state[key] = data[key];
      });
      if (state.bookingMinutesStep === 0) {
        state.bookingMinutesStep = 1;
      }
    },
    clearSettingsConfiguration(state) {
      const keys = Object.keys(state);
      keys.forEach((key) => {
        state[key] = null;
      })
    },
  },
  actions: {
    async populateSettingsConfiguration({ commit }) {
      try {
        const { data } = await UserServiceConfigurationSettingsManagement.getSettingsConfiguration();
        commit('setSettingsConfiguration', data);
      } catch (e) {
        //
      }
    },
  },
  getters: {
    getBookingMinutesStep: (state) => state.bookingMinutesStep,
    getExtraLuggageFeaturePrice: (state) => state.extraLuggageFeaturePrice,
    getExtraLuggageMaximumQuantityPerBooking: (state) => state.extraLuggageMaximumQuantityPerBooking,
    getMaximumPassengersPerBooking: (state) => state.maximumPassengersPerBooking,
    getMinDropOffIntervalBeforeFlightDepartureTime: (state) => state.minDropOffIntervalBeforeFlightDepartureTime,
    getMinPickIpIntervalAfterFlightLandingTimes: (state) => state.minPickIpIntervalAfterFlightLandingTime,
    getSettingId: (state) => state.settingId,
  },
}
