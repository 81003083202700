import { axios } from '@slg/web-customer-shared';

const apiDomain = 'abs-retailer/api/v1/retailer/user/point-of-interest';

const getAllPois = (pointOfInterestType) => {
  if (pointOfInterestType) {
    return axios.get(`${apiDomain}`, { params: { pointOfInterestType } });
  }

  return axios.get(`${apiDomain}`);
};

export default { getAllPois }
