import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './appMessages/en.json';
import de from './appMessages/de.json';
import fr from './appMessages/fr.json';

export const fallbackLocale = 'en';

Vue.use(VueI18n);

export default new VueI18n({
  messages: {
    en,
    de,
    fr,
  },
  fallbackLocale,
  silentTranslationWarn: true,
});
