import Vue from 'vue'
import Vuex from 'vuex'

import VuexPersistence from 'vuex-persist'
import auth from './auth'
import settingsConfiguration from './serviceConfiguration';
import booking from './booking'
import bookingEdit from './bookingEdit'
import bookingsTable from './bookingsTable'
import common from './common'
import featureFlags from './featureFlags';

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersistence({
  key: 'vuex-session-storage',
  storage: window.sessionStorage,
  reducer: (state) => ({
    booking: state.booking,
  }),
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    settingsConfiguration,
    bookingsTable,
    bookingEdit,
    booking,
    common,
    featureFlags,
  },
  plugins: [vuexSessionStorage.plugin],
})
