<template>
  <div class="d-flex flex-column breakpoint-xl">
    <div class="booking-container position-relative ">
      <div class="container--fluid h-100 position-relative d-flex flex-wrap mx-6">
        <VRow
          class="booking-container__top d-flex position-relative align-items-top flex-wrap"
          style="z-index: 1"
        >
          <VCol
            cols="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            class="d-flex col-12 offset-lg-1"
          >
            <BookingForm class="booking-form-container" />
          </VCol>
        </VRow>
      </div>
    </div>
  </div>
</template>

<script>

import { getMetaInfo } from '@/utils/seo';
import BookingForm from '@/components/bookingForm/BookingForm.vue';

export default {
  name: 'BookingPage',
  components: {
    BookingForm,
  },
  metaInfo() {
    return getMetaInfo(this.homePageData?.Seo);
  },
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.booking-container {
  padding-top: 24px;
  padding-bottom: 31px;
  margin-bottom: 61px;
  min-height: 537px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;

  .booking-container__top {
    .title-info {
      background: rgba(0, 0, 0, 0.45);
      border: 2px solid;
      border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(96, 96, 96, 0.2) 100%);
      border-radius: 8px;
      padding: 24px;
    }

    .subtitle-1 {
      max-width: 320px;
    }
  }

  .booking-form-container {
    z-index: 1;
  }

  .breakpoint-xl {
    @media screen and (max-width: 960px) {
      max-width: 810px;
    }
  }
}

.wrap {
  max-width: 810px;
}

@media screen and (min-width: 1280px) {
  .offset-lg-2 {
    margin-right: 16.6666666667% !important;
    margin-left: unset !important;
  }

   .offset-lg-1 {
    margin-right: 8.3333333333% !important;
    margin-left: unset !important;
  }

  .booking-container__top {
    .title-info {
      margin-top: 130px;
    }
  }
}

@media screen and (min-width: 960px) {
  .booking-form-container {
    margin-bottom: auto;
  }
  .booking-container__top {
    .title-info {
      margin-top: 95px;
    }
  }
}

@media screen and (max-width: 960px) {
  .booking-container__top {
    .heading-3 {
      font-size: $fs-32;
      line-height: $lh-43;
      letter-spacing: $ls-0025;
    }
  }
}

@media screen and (max-width: 600px) {
  .booking-container__top {
    .heading-3 {
      font-size: $fs-20;
    }

    .subtitle-1 {
      font-size: $fs-14;
    }
  }
}
</style>
