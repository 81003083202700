<template>
  <div>
    <PassengerInfoForm />
  </div>
</template>
<script>
import PassengerInfoForm from './passengerInfoForm/PassengerInfoForm.vue';

export default {
  name: 'PassengerInfo',
  components: {
    PassengerInfoForm,
  },
}
</script>

<style scoped lang="scss"></style>
