export const bookingDetails = {
  arrivalPlace: {
    address: '',
    poiName: '',
    poiStopName: '',
    lat: '',
    lon: '',
    type: '',
    isPoi: false,
    poiStopId: '',
    poiGroupId: '',
    timeZone: '',
    stopId: '',
    error: '',
    isFirstSelected: false,
  },
  departurePlace: {
    address: '',
    poiName: '',
    poiStopName: '',
    lat: '',
    lon: '',
    type: '',
    isPoi: false,
    poiStopId: '',
    poiGroupId: '',
    timeZone: '',
    stopId: '',
    error: '',
    isFirstSelected: true,
  },
  animalsTransportation: false,
  roundTrip: false,
  outwardForm: {
    outwardDate: {
      date: '',
      time: '',
    },
    routeTaxiDate: {
      date: '',
      time: '',
      nearestAllowedDate: '',
      nearestAllowedTime: '',
    },
    flightNumber: '',
    comment: '',
  },
  returnForm: {
    returnDate: {
      date: '',
      time: '',
    },
    routeTaxiDate: {
      date: '',
      time: '',
      nearestAllowedDate: '',
      nearestAllowedTime: '',
    },
    flightNumber: '',
    comment: '',
  },
  adultsCount: 1,
  infantsCount: 0,
  extraLuggage: 0,
  featureCapacity: 0,
  opsZoneId: '',
  rideType: 'SHARED',
  tripType: 'ROUND_TRIP',
  privateShuttle: false,
  isMidocoBooking: false,
  midocoPassengers: [],
  orderId: '',
  flightOutwardDateTimeMillis: 0,
  flightOutwardNumber: '',
  flightReturnDateTimeMillis: 0,
  flightReturnNumber: 0,
  reservation: {
    originalOutwardFeaturePrice: null,
    originalReturnFeaturePrice: null,
    emptyLeg: null,
    originalOutwardPrice: null,
    originalReturnPrice: null,
    reservationId: null,
    reservationTill: null,
    tenantId: null,
    tenantShortName: null,
    type: null,
  },
  passengerDetails: {
    isAnotherPassenger: false,
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    language: 'fr',
    confirmationEmail: false,
    resendConfirmationEmail: false,
  },
  commentForOutward: '',
  commentForReturn: '',
  clearPhoneField: false,
}
