<template>
  <VCard
    ref="card"
    class="h-100"
  >
    <VRow
      ref="addressSelectionWrapper"
      class="m-0 w-100"
      :class="{
        'h-100': !$vuetify.breakpoint.xs || (!$vuetify.breakpoint.xs && !getIsPoiListNotEmpty)
      }"
    >
      <VCol
        class="p-0 map-container"
        cols="12"
        :lg="getIsPoiListNotEmpty && (!getAtLeastOnePlaceIsPoi || selectedLocation.isPoi) ? 8 : 12"
        :md="getIsPoiListNotEmpty && (!getAtLeastOnePlaceIsPoi || selectedLocation.isPoi) ? 8 : 12"
        :sm="getIsPoiListNotEmpty && (!getAtLeastOnePlaceIsPoi || selectedLocation.isPoi) ? 7 : 12"
        :xs="12"
        :style="getMapContainerStyle"
      >
        <VMap
          ref="map"
          :isLoading="isLoading"
          :mapKey="mapKey"
          :placeMarkers="getPoiMarkers"
          :selectedPoiType="selectedPoiType"
          :mapCenter="getMapCenter"
          :zoom="zoom"
          :selectedLocation="selectedLocation"
          :selectedPoi="getSelectedPoi"
          :userMarkers="getUserMarkers"
          :isDeparturePlaceFilled="isDeparturePlaceFilled"
          :isArrivalPlaceFilled="isArrivalPlaceFilled"
          :currentStep="currentStep"
          :additionalHeightForMap="additionalHeightForMap"
          @onMapClick="setUserMarker"
          @selectMarker="selectMarker"
          @updateZoom="updateZoom"
          @selectPlace="selectPlace"
          @recalculateHeight="$emit('recalculateHeight')"
        >
          <template
            v-if="isAddressSelectionAllowed"
            #prepend-inner
          >
            <div class="w-100 px-3">
              <AddressSearch
                :selectedAddress="selectedLocation.address"
                @selectAddress="onAddressSelect"
              />
            </div>
          </template>
          <template #prepend-inner-after>
            <div
              v-if="!!error"
              data-test="directionDialogErrorMessageContainer"
              class="w-100 px-3 mt-3"
            >
              <div class="error-container px-4 py-2 d-flex align-center">
                <div class="icon-container">
                  <VIcon color="error">
                    mdi-alert-octagon
                  </VIcon>
                </div>
                <div class="message-container pl-2">
                  <span class="body-1">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template #append-inner>
            <div
              v-if="(!getIsPoiListNotEmpty || (getAtLeastOnePlaceIsPoi && !selectedLocation.isPoi) && isLocationSelected)
                || ($vuetify.breakpoint.xs && isLocationSelected)"
              class="action-container w-100 px-3 d-flex justify-content-center"
            >
              <VBtn
                id="set-location-button"
                ref="setLocationButton"
                data-test="setLocationButton"
                class="w-50"
                color="brandingPrimary"
                dark
                @click="setLocation"
              >
                <span class="button">
                  {{ $t('general.next') }}
                </span>
              </VBtn>
            </div>
          </template>
        </VMap>
      </VCol>
      <VCol
        v-if="getIsPoiListNotEmpty && !getAtLeastOnePlaceIsPoi || selectedLocation.isPoi"
        class="p-0 d-flex flex-column justify-content-between shadow-none poi-list-container"
        data-test="poiSidebar"
        cols="12"
        lg="4"
        md="4"
        sm="5"
        xs="12"
        :class="{
          'h-100': !$vuetify.breakpoint.xs,
          'pb-2': $vuetify.breakpoint.xs
        }"
        :style="{
          'height': $vuetify.breakpoint.xs ? '100%' : '100%',
        }"
      >
        <PoiSelection
          ref="poiSelection"
          class="overflow-auto"
          :poiList="poiList"
          :selectedPoi="getSelectedPoi"
          :selectedPoiStopId="selectedLocation.selectedPoiStopId"
          :selectedPoiType="selectedPoiType"
          @updateSelectedPoiType="updateSelectedPoiType"
          @updateSelectedPoiStopId="selectedPoiStopStopId"
          @selectPlace="selectPlace"
        />
        <div
          v-if="!$vuetify.breakpoint.xs"
          class="p-4"
        >
          <VBtn
            ref="setLocationButton"
            class="w-100"
            color="brandingPrimary"
            :class="{'disabled': error || !isLocationSelected}"
            data-test="setLocationButton"
            dark
            @click="setLocation"
          >
            <span class="button">
              {{ $t('general.next') }}
            </span>
          </VBtn>
        </div>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
import { mapGetters } from 'vuex';
import pointInPolygon from 'point-in-polygon';
import ripple from '@slg/web-customer-shared/src/utils/ripple';
import { markerTypesMeta } from '@slg/web-customer-shared/src/utils/iconResolver';
import { determiningSelectedPoint } from '@slg/web-customer-shared/src/providers/MapProvider';
import VMap from '../vMap/VMap.vue';
import PoiSelection from './PoiSelection.vue';
import AddressSearch from './AddressSearch.vue';

export default {
  name: 'AddressSelection',
  components: {
    PoiSelection,
    VMap,
    AddressSearch,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    poiListWithoutGrouping: {
      type: Array,
      default: () => [],
    },
    poiList: {
      type: Object,
      default: () => {
      },
    },
    pickedLocation: {
      type: Object,
      default: () => {
      },
    },
    currentStep: {
      type: String,
      default: '',
    },
    additionalHeightForMap: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    isArrivalPlaceFilled: {
      type: Boolean,
      default: false,
    },
    isDeparturePlaceFilled: {
      type: Boolean,
      default: false,
    },
    isDeparturePlacePoi: {
      type: Boolean,
      default: false,
    },
    isArrivalPlacePoi: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapKey: new Date().toString(),
      zoom: 9,
      poiId: '',
      selectedPoiType: '',
      selectedLocation: {
        address: '',
        lat: '',
        lon: '',
        type: '',
        isPoi: false,
        selectedPoiStopId: '',
      },
      prevLocation: {},
    }
  },
  computed: {
    ...mapGetters({
      bookingEdit: 'bookingsTable/getEditBookingDialogStatus',
    }),
    ...mapGetters({
      getAtLeastOnePlaceIsPoiCreate: 'booking/getAtLeastOnePlaceIsPoi',
    }),
    ...mapGetters({
      getAtLeastOnePlaceIsPoiEdit: 'bookingEdit/getAtLeastOnePlaceIsPoi',
    }),
    getAtLeastOnePlaceIsPoi() {
      return this.bookingEdit ? this.getAtLeastOnePlaceIsPoiEdit : this.getAtLeastOnePlaceIsPoiCreate;
    },
    getCorrectHeightValue() {
      return this.additionalHeightForMap > 0 ? this.additionalHeightForMap : 0;
    },
    getMapContainerStyle() {
      return this.$vuetify.breakpoint.xs
        ? {
          height: `${400 + this.getCorrectHeightValue}px`,
        }
        : {
          height: '100%',
        }
    },
    getUserMarkers() {
      if (!this.selectedLocation.isPoi) {
        return [{
          valid: !this.error,
          lat: this.selectedLocation.lat,
          lon: this.selectedLocation.lon,
        }]
      }
      return []
    },
    getIsPoiListNotEmpty() {
      return !!Object.keys(this.poiList).map((key) => this.poiList[key].items).flat(Infinity).length;
    },
    getSelectedPoi() {
      if (!this.getSelectedStop) {
        return {};
      }

      if (this.getSelectedStop?.isPoiGroup) {
        const poiGroups = this.poiListWithoutGrouping.filter((poi) => poi?.pointsOfInterest);
        return poiGroups?.find((item) => item?.pointsOfInterest[0]?.pointOfInterestId === this.getSelectedStop?.id)
          || {};
      }
      const pois = this.poiListWithoutGrouping.filter((poi) => !poi?.pointsOfInterest);
      return pois?.find((item) => item?.pointOfInterestId === this.getSelectedStop?.id)
          || {};
    },
    getSelectedStop() {
      if (this.selectedLocation.selectedPoiStopId) {
        const allStops = Object.keys(this.poiList)
          .map((poiType) => this.poiList[poiType].items.map((subItems) => subItems.subItems))
          .flat(Infinity);
        return allStops.find((item) => item.id === this.selectedLocation.selectedPoiStopId)
      }
      return null;
    },
    getMapCenter() {
      return [0, 0];
    },
    getPoiMarkers() {
      let allStops = Object.keys(this.poiList)
        .map((poiType) => this.poiList[poiType].items.map((gates) => gates.subItems[0]));

      if (this.getSelectedStop) {
        allStops = Object.keys(this.poiList)
          .map((poiType) => this.poiList[poiType].items.map((gates) => {
            if (gates.subItems[0].parentInd === this.getSelectedStop.parentInd) {
              return gates.subItems;
            }
            return gates.subItems[0];
          }))
      }

      return allStops.flat(Infinity).map((stop) => ({
        poiId: stop.parentInd,
        id: stop.id,
        activeStop: stop.id === this.selectedLocation.selectedPoiStopId,
        activePoi: stop.parentInd === this.getSelectedPoi.pointOfInterestId,
        isPoiGroup: stop.isPoiGroup,
        tooltip: stop.address,
        position: [+stop.lat, +stop.lon],
        type: stop.type,
      })).filter((item) => item.type === this.selectedPoiType || item.activeStop || item.activePoi || !this.selectedPoiType)
    },
    isLocationSelected() {
      return this.selectedLocation?.address && this.selectedLocation?.lat && this.selectedLocation?.lon;
    },
    isAddressSelectionAllowed() {
      if (this.currentStep === 'pickUp') {
        if (!this.isArrivalPlaceFilled) {
          return true;
        } if (this.isArrivalPlaceFilled && !this.isArrivalPlacePoi) {
          return false
        } if (this.isArrivalPlaceFilled && this.isArrivalPlacePoi) {
          return true
        }
      } else if (this.currentStep === 'dropOff') {
        if (!this.isDeparturePlaceFilled) {
          return true;
        } if (this.isDeparturePlaceFilled && !this.isDeparturePlacePoi) {
          return false
        } if (this.isDeparturePlaceFilled && this.isDeparturePlacePoi) {
          return true
        }
      }
      return false;
    },
  },
  created() {
    this.selectedLocation.selectedPoiStopId = this.pickedLocation.poiStopId;
    this.selectedLocation.type = this.pickedLocation.type;
    this.selectedLocation.lat = this.pickedLocation.lat;
    this.selectedLocation.lon = this.pickedLocation.lon;
    this.selectedLocation.isPoi = !!this.pickedLocation.poiStopId;
    this.selectedLocation.poiGroupId = this.pickedLocation.poiGroupId;
    this.selectedLocation.poiName = this.pickedLocation.poiName;
    this.selectedLocation.poiStopId = this.pickedLocation.poiStopId;
    this.selectedLocation.poiStopName = this.pickedLocation.poiStopName;
    this.selectedLocation.stopId = this.pickedLocation.stopId;
    this.selectedLocation.timeZone = this.pickedLocation?.timeZone || '';
    this.prevLocation = { ...this.selectedLocation };
    setTimeout(() => {
      this.selectedLocation.address = this.pickedLocation.address;
    }, 500)
  },
  mounted() {
    this.$emit('recalculateHeight');
  },
  methods: {
    invalidateMapSize() {
      this.$nextTick(() => {
        this.$refs.map.invalidateMapSize();
      });
    },
    coordsInsidePoiChecker(coords, poiList) {
      return poiList.find((poi) => pointInPolygon(coords, poi.polygon));
    },
    onAddressSelect(locationInfo) {
      const data = {
        ...locationInfo,
        latlng: {
          lat: locationInfo.lat,
          lng: locationInfo.lon,
        },
      }
      this.setUserMarker(data, true);
    },
    async setUserMarker(locationInfo, isSelectedFromSearchDropdown = false) {
      const poiList = this.poiListWithoutGrouping.map((poi) => ({
        polygon: poi?.polygon
          ? poi?.polygon?.map((marker) => [+marker.latitude, +marker.longitude])
          : poi?.pointsOfInterest[0]?.polygon?.map((marker) => [+marker.latitude, +marker.longitude]),
        id: poi?.polygon
          ? poi.pointOfInterestId
          : poi?.pointsOfInterest[0]?.pointOfInterestId,
      }))
      const selectedPoi = this.coordsInsidePoiChecker([locationInfo.latlng.lat, locationInfo.latlng.lng], poiList);
      if (selectedPoi) {
        const selectedStop = this.poiListWithoutGrouping.find((poi) => poi.pointOfInterestId === selectedPoi.id);
        this.selectedPoiStopStopId(selectedStop.stops[0]);
      } else if (this.isAddressSelectionAllowed) {
        const selectedLocation = isSelectedFromSearchDropdown
          ? locationInfo
          : await determiningSelectedPoint(locationInfo.latlng.lat, locationInfo.latlng.lng);
        this.selectAddress(selectedLocation);
      }
    },
    selectAddress(data) {
      if (this.selectedLocation.address === data.address) {
        ripple(this.$refs.setLocationButton.$el);
      }
      this.selectedLocation.address = data.address;
      this.selectedLocation.type = markerTypesMeta.other;
      this.selectedLocation.lat = data.lat;
      this.selectedLocation.lon = data.lng || data.lon;
      this.selectedLocation.isPoiGroup = false;
      this.selectedLocation.poiName = null;
      this.selectedLocation.poiGroupId = data?.poiGroupId;
      this.selectedLocation.selectedPoiStopId = '';
      this.selectedLocation.isPoi = false;
      this.selectedLocation.timeZone = '';
    },
    setPoi(poi) {
      this.selectedLocation.address = poi.address;
      this.selectedLocation.type = poi.type;
      this.selectedLocation.lat = poi.lat;
      this.selectedLocation.lon = poi.lon;
      this.selectedLocation.selectedPoiStopId = poi.id;
      this.selectedLocation.poiName = poi.name;
      this.selectedLocation.isPoiGroup = poi.isPoiGroup;
      this.selectedLocation.poiGroupId = poi.poiGroupId;
      this.selectedLocation.isPoi = true;
      this.selectedLocation.timeZone = poi.timeZone;
    },
    setLocation() {
      const dataToSet = {
        address: this.selectedLocation?.address,
        lat: this.selectedLocation?.lat,
        lon: this.selectedLocation?.lon,
        type: this.selectedLocation?.type,
        isPoi: !!this.selectedLocation?.selectedPoiStopId,
        isPoiGroup: this.selectedLocation.isPoiGroup,
        poiName: this.selectedLocation?.poiName || '',
        poiGroupId: this.selectedLocation?.poiGroupId,
        poiStopId: this.selectedLocation?.selectedPoiStopId,
        timeZone: this.selectedLocation?.timeZone || '',
      };
      this.$emit('setLocation', dataToSet)
    },
    scrollToPoiSelection(type, e, position = 'center') {
      if (this.$vuetify.breakpoint.xs) {
        this.updateSelectedPoiType(type);
      }

      if (!type) {
        return;
      }

      setTimeout(() => {
        const poiTypeLabel = this.$refs.poiSelection.$refs[type];
        let target = poiTypeLabel;
        if (e) {
          const itemId = e.activePoi ? e.id : e.poiId;
          const poiStopLabel = this.$refs.poiSelection.$refs[itemId];
          if (poiStopLabel) {
            target = poiStopLabel;
          }
        }
        const targetEl = target[0].$el || target[0];
        targetEl.scrollIntoView({ behavior: 'smooth', block: position, inline: 'end' });
      }, 800);
    },
    selectPlace(location) {
      if (!location) {
        return;
      }

      if (this.prevLocation.selectedPoiStopId === location.id) {
        ripple(this.$refs.setLocationButton.$el);
      } else {
        this.prevLocation = { ...this.selectedLocation };
      }
    },
    selectedPoiStopStopId(poiItem) {
      if (this.selectedLocation.selectedPoiStopId === poiItem.id) {
        return;
      }

      this.$emit('updatePoiId', poiItem.id);
      let selectedPoi;

      if (poiItem.isPoiGroup) {
        selectedPoi = this.poiListWithoutGrouping
          .find((item) => item?.pointsOfInterest?.find((poi) => poi?.stops?.find((poiStop) => poiStop.poiStopId === poiItem.id)));
      } else {
        selectedPoi = this.poiListWithoutGrouping.find((item) => item?.stops?.find((poiStop) => poiStop.poiStopId === poiItem.id));
      }
      this.selectedPoiType = selectedPoi?.type;
      this.$emit('updatePoiType', selectedPoi?.type);
      this.selectedLocation.selectedPoiStopId = poiItem.id;
      this.setPoi(this.getSelectedStop);
    },
    selectMarker(e) {
      if (this.selectedLocation.selectedPoiStopId === e.id) {
        ripple(this.$refs.setLocationButton.$el);
        return
      }

      if (!this.$vuetify.breakpoint.xs) {
        this.scrollToPoiSelection(e.type, e, 'center');
      }

      let selectedPoi;
      if (e.poiGroupId) {
        selectedPoi = this.poiListWithoutGrouping
          .find((poi) => poi?.pointsOfInterest[0]?.stops?.find((poiStop) => poiStop.poiStopId === e.id));
      } else {
        selectedPoi = this.poiListWithoutGrouping.find((poi) => poi?.stops?.find((poiStop) => poiStop.poiStopId === e.id));
      }

      this.selectedPoiType = selectedPoi?.type;
      this.selectedLocation.selectedPoiStopId = e.id;
      this.$emit('updatePoiId', e.id);
      this.$emit('updatePoiType', e.type);
      this.setPoi(this.getSelectedStop);
    },
    updateZoom(e) {
      this.zoom = e;
    },
    updateSelectedPoiType(e) {
      this.selectedPoiType = e;
      if (!this.$vuetify.breakpoint.xs && e) {
        this.scrollToPoiSelection(e);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.action-container {
  width: 50%;
}

.map-container {
  z-index: 1000;

  &.mobile {
    height: 500px;
  }

  &.full-height {
    height: 100%;
  }
}

.poi-list-container {
  z-index: 2;
}

.error-container {
  position: relative;
  border-radius: 0.4rem;
  border: 1px solid var(--v-error-base);
  background: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.12;
    background-color: var(--v-error-base) !important;
  }
}
</style>
