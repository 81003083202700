<template>
  <div
    v-if="getReservation.originalOutwardPrice === null"
    class="footer d-flex align-items-center justify-content-end"
  >
    <VBtn
      class="w-20 border-radius-8 mr-6 clear-button"
      dark
      large
      color="pageBg"
      data-test="cleatFormButton"
      @click="$emit('clearForm')"
    >
      <span class="button  textPrimary--text">
        {{ $t('bookingForm.clearForm') }}
      </span>
      <VIcon color="textPrimary">
        mdi-backspace
      </VIcon>
    </VBtn>
    <VBtn
      class="w-20 border-radius-8 submit-button"
      data-test="findVehicleButton"
      dark
      large
      color="brandingPrimary"
      @click="$emit('findVehicle')"
    >
      <span class="button">
        {{ isGlobalLoading ? $t('bookingForm.processing') : $t('bookingForm.findAVehicle') }}
      </span>
      <v-progress-circular
        v-if="isGlobalLoading"
        :width="3"
        indeterminate
        color="textWhite"
      />
      <VIcon
        v-else
        color="textWhite"
      >
        mdi-chevron-right
      </VIcon>
    </VBtn>
  </div>
  <div
    v-else
    class="footer d-flex align-items-center justify-content-between"
  >
    <div class="info-block d-flex">
      <img
        :src="require('@/assets/img/vehicle-is-found.svg')"
        alt=""
      >
      <div class="ml-6">
        <span class="body-1">{{ $t('bookingForm.vehicleIsFound') }}</span>
        <div>
          <template v-if="getReservation.reservationTill">
            <span class="body-1">{{ $t('bookingForm.reservationExpiredAt') }}</span>
            <span class="body-bold">
              {{ minutes }}:{{ seconds }}
            </span>
          </template>
        </div>
      </div>
    </div>
    <div class="price">
      <template v-if="getReservation.originalReturnPrice">
        <div class="d-flex justify-content-between">
          <span class="body-2">{{ $t('bookingForm.outwardTripPrice') }}</span>
          <span class="body-2">€{{ getOutwardPrice }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="body-2">{{ $t('bookingForm.returnTripPrice') }}</span>
          <span class="body-2">€{{ getReturnPrice }}</span>
        </div>
      </template>
      <div class="d-flex justify-content-between">
        <span class="heading-5">{{ $t('bookingForm.totalPrice') }}</span>
        <span
          class="heading-5"
          data-test="oneWayTripTotalPrice"
        >€{{ getTotalPrice }}</span>
      </div>
    </div>
    <div class="buttons">
      <VBtn
        class="w-20 border-radius-8 mr-6"
        dark
        large
        color="pageBg"
        data-test="cancelReservationButton"
        @click="cancelReservation"
      >
        <span class="button  textPrimary--text">
          {{ $t('bookingForm.cancel') }}
        </span>
        <VIcon color="textPrimary">
          mdi-close-circle
        </VIcon>
      </VBtn>
      <VBtn
        class="w-20 border-radius-8"
        data-test="bookNowButton"
        dark
        large
        color="brandingPrimary"
        @click="$emit('bookNow')"
      >
        <span class="button">
          {{ $t('bookingForm.bookNow') }}
        </span>
        <VIcon color="textWhite">
          mdi-check
        </VIcon>
      </VBtn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'BookingFormFooter',
  components: {
  },
  data() {
    return {
      minutes: null,
      seconds: null,
    }
  },
  computed: {
    ...mapGetters('common', ['isGlobalLoading']),
    ...mapGetters('booking', ['getReservation']),
    getOutwardPrice() {
      return Number(this.getReservation.originalOutwardPrice);
    },
    getReturnPrice() {
      return Number(this.getReservation.originalReturnPrice);
    },
    getTotalPrice() {
      return Number(this.getOutwardPrice + this.getReturnPrice).toFixed(2);
    },
  },
  watch: {
    getReservation: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.getReservation.reservationTill === null) {
          return;
        }
        if (moment().valueOf > this.getReservation.reservationTill || !this.getReservation.reservationTill) {
          this.$store.commit('booking/clearReservation')
        } else {
          this.expiredTimerIntervalId = setInterval(this.startTimer, 1000);
        }
      },
    },
  },
  methods: {
    startTimer() {
      const now = moment().valueOf();
      if (this.getReservation.reservationTill === null) {
        clearInterval(this.expiredTimerIntervalId);
        return;
      }
      const diffInMs = this.getReservation.reservationTill - now;
      if (diffInMs <= 0) {
        this.$toasted.error(this.$i18n.t('bookingForm.reservationExpired'))
        this.$store.commit('booking/clearReservation');
        clearInterval(this.expiredTimerIntervalId);
      } else {
        this.minutes = Math.trunc((diffInMs / 1000) / 60).toString();
        const tmpSeconds = ((diffInMs / 1000) % 60).toFixed().toString();
        this.seconds = tmpSeconds.length !== 1 ? tmpSeconds : tmpSeconds.padStart(2, '0');
      }
    },
    async cancelReservation() {
      await this.$store.dispatch('booking/cancelTemporaryReservation');
    },
  },
}
</script>

<style scoped lang="scss">
.footer {
  position: fixed;
  bottom: 0%;
  width: 1280px;
  height: 80px;
  background: #FFFFFF;

  .info-block {
    width: 30%;
  }

  .price {
    width: 30%;
  }

  .buttons {
    width: 33%;
  }

  .submit-button {
    width: 358px;
  }
}
</style>
