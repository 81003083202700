<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div class="bookings-filters">
    <v-text-field
      v-debounce:600ms="input"
      class="pb-0 mt-0 mr-5 bookings-filter"
      :label="$t('bookingsTable.bookingID')"
      prepend-inner-icon="mdi-magnify"
      name="retailerBookingId"
    />
    <v-text-field
      v-debounce:600ms="input"
      class="pb-0 mt-0 mr-5 bookings-filter"
      :label="$t('bookingsTable.customerLastName')"
      prepend-inner-icon="mdi-magnify"
      name="retailerBookingRequest.mainPassengerDetails.lastName"
    />
    {{ filterKey }}
    <div @click="dateMenu = true">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{attrs }">
          <v-text-field
            v-bind="attrs"
            ref="dateSelect"
            class="bookings-filter pb-0 mt-0 mr-5"
            :value="formattedDate"
            clearable
            :label="$t('bookingsTable.bookingDate')"
            readonly
            prepend-inner-icon="mdi-calendar"
            @click:clear="clearDate"
          />
        </template>
        <v-date-picker
          v-model="date"
          @change="onDateChange"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
import { getMetaInfo } from '@/utils/seo';
import moment from 'moment';
import { mapActions } from 'vuex';
import TimeConverter from '@/utils/timeConvertor/timeConverter';

export default {
  name: 'BookingsListFilters',
  metaInfo() {
    return getMetaInfo(this.homePageData?.Seo);
  },
  data() {
    return {
      dateMenu: false,
      date: null,
      filterKey: '',
    };
  },
  computed: {
    getDate() {
      return this.date
    },
    formattedDate() {
      return this.date ? TimeConverter.bookingsTableFilterDateFormat(this.date) : '';
    },
  },
  methods: {
    ...mapActions('bookingsTable', ['getBookings']),
    onDateChange(date) {
      this.$store.commit('bookingsTable/setPagination', {
        pageStart: 0,
      });
      const filterDate = moment(date, 'YYYY-MM-DD');
      this.dateMenu = false;
      this.$store.commit('bookingsTable/updateNumericFilter', [filterDate.startOf('day').valueOf(), filterDate.endOf('day').valueOf()]);
      this.getBookings();
      this.$emit('updateTableKey');
    },
    clearDate() {
      this.date = null;
      this.$store.commit('bookingsTable/updateNumericFilter', []);
      this.$refs.dateSelect.blur();
      this.getBookings();
    },
    input(value, e) {
      this.$store.commit('bookingsTable/updateStringFilter', { fieldName: e.target.name, value });
      this.getBookings();
      this.$emit('updateTableKey');
    },
  },

}
</script>

<style lang="scss" scoped>
.bookings-filters {
  .bookings-filter {
    width: 225px;
    float: left;
  }
}
</style>
