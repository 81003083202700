<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <RouterView class="pb-15" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TheMainContent',
}
</script>
