<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div class="d-flex flex-column breakpoint-xl">
    <div class="bookings-list-container position-relative h-100 mb-0">
      <div class="container--fluid h-100 position-relative d-flex mx-6">
        <VRow
          class="bookings-list-container__top d-flex position-relative"
          style="z-index: 1"
        >
          <VCol
            cols="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            class="d-flex col-12 offset-lg-1 table-container"
          >
            <BookingsTableFilters @updateTableKey="tableKey = new Date().toString()" />
            <v-data-table
              :key="tableKey"
              :headers="headers"
              :items="bookings"
              :loading="loading"
              class="elevation-1"
              :server-items-length="totalCount"
              :sort-by.sync="initSortBy"
              must-sort
              :sort-desc.sync="initSortDesc"
              :footer-props="{
                'items-per-page-options': itemsPerPage,
                disablePagination: loading
              }"
              @update:options="onUpdateOption"
            >
              <template #item.retailerUser="{ item }">
                {{ `${item.retailerUser.firstName} ${item.retailerUser.lastName}` }}
              </template>
              <template #item.createdAt="{ item }">
                {{ TimeConverter.bookingTableDate(item.createdAt) }}
              </template>
              <template #item.tripDate="{ item }">
                {{ TimeConverter.bookingTableDate(item.tripDate) }}
              </template>
              <template #item.privateShuttle="{ item }">
                {{ item.privateShuttle ? $t('bookingForm.rideDetails.PRIVATE') : $t('bookingForm.rideDetails.SHARED') }}
              </template>
              <template #item.mainPassengerDetails="{ item }">
                {{ item.mainPassengerDetails.firstName }} {{ item.mainPassengerDetails.lastName }}
              </template>
              <template #item.retailerBookingStatus="{ item }">
                {{ $t(`bookingStatuses.${item.retailerBookingStatus}`) }}
              </template>
              <template #item.action="{ item }">
                <div class="table-actions">
                  <VBtn
                    v-if="['AWAITING_VEHICLE', 'BOOKED'].includes(item.retailerBookingStatus) && !item.orderMidocoId"
                    class="bookings-table-action"
                    fab
                    outlined
                    color="error"
                    x-small
                    @click="cancelBooking(item)"
                  >
                    <v-icon>
                      mdi-cancel
                    </v-icon>
                  </VBtn>
                  <VBtn
                    v-if="['AWAITING_VEHICLE', 'BOOKED'].includes(item.retailerBookingStatus) && !item.orderMidocoId"
                    class="bookings-table-action"
                    fab
                    outlined
                    color="brandingPrimary"
                    x-small
                    @click="editBooking(item)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </VBtn>
                </div>
              </template>
              <template #no-data>
                <NoDataTable />
              </template>
            </v-data-table>
          </vcol>
        </vrow>
      </div>
    </div>
    <CancelBookingDialog />
    <EditBookingDialog v-if="editBookingDialog" />
  </div>
</template>

<script>

import { getMetaInfo } from '@/utils/seo';
import { mapGetters, mapState, mapActions } from 'vuex';
import CancelBookingDialog from '@/components/common/CancelBookingDialog.vue';
import EditBookingDialog from '@/components/common/EditBookingDialog.vue';
import TimeConverter from '@/utils/timeConvertor/timeConverter';
import SConstants from '@slg/web-customer-shared/src/utils/constants';
import NoDataTable from './components/NoDataTable.vue';
import BookingsTableFilters from './components/bookingsListFilters/BookingsListFilters.vue';

export default {
  name: 'BookingsListPage',
  components: {
    CancelBookingDialog,
    NoDataTable,
    BookingsTableFilters,
    EditBookingDialog,
  },
  metaInfo() {
    return getMetaInfo(this.homePageData?.Seo);
  },
  data() {
    return {
      itemsPerPage: [25, 50],
      TimeConverter,
      SConstants,
      tableKey: new Date().toDateString(),
      initSortBy: 'tripDate',
      initSortDesc: true,
      BOOKING_STATUSES: {
        BOOKED: 'BOOKED',
        CANCELLED: 'CANCELLED',
        AWAITING_VEHICLE: 'AWAITING_VEHICLE',
      },
    };
  },
  computed: {
    ...mapState('bookingsTable', ['bookings', 'totalCount', 'loading', 'sortField', 'editBookingDialog']),
    ...mapGetters('auth', ['getIsMidocoAuth']),
    headers() {
      return [
        { text: this.$t('bookingsTable.bookingID'), value: 'retailerBookingId', sortable: false },
        ...(!this.getIsMidocoAuth
          ? []
          : [
            { text: this.$t('bookingsTable.midocoOrderId'), value: 'orderMidocoId', sortable: false },
            { text: this.$t('bookingsTable.orgUnit'), value: 'orgUnit', sortable: false },
          ]),
        { text: this.$t('bookingsTable.retailerUser'), value: 'retailerUser', sortable: false },
        { text: this.$t('bookingsTable.createdAt'), value: 'createdAt' },
        { text: this.$t('bookingsTable.requestedDateTime'), value: 'tripDate' },
        { text: this.$t('bookingsTable.bookingPrice'), value: 'bookingPrice', sortable: false },
        { text: this.$t('bookingsTable.shuttleType'), value: 'privateShuttle', sortable: false },
        { text: this.$t('bookingsTable.mainPassenger'), value: 'mainPassengerDetails', sortable: false },
        { text: this.$t('bookingsTable.email'), value: 'mainPassengerDetails.email', sortable: false },
        { text: this.$t('bookingsTable.phoneNumber'), value: 'mainPassengerDetails.phone', sortable: false },
        { text: this.$t('bookingsTable.status'), value: 'retailerBookingStatus', sortable: false },
        { text: this.$t('bookingsTable.actions'), value: 'action', sortable: false },
      ]
    },
  },
  async created() {
    await this.$store.dispatch('settingsConfiguration/populateSettingsConfiguration');
  },
  beforeDestroy() {
    this.$store.commit('bookingsTable/clearFilters');
  },
  methods: {
    ...mapActions('bookingsTable', ['getBookings', 'getBooking']),
    cancelBooking(item) {
      this.$store.commit('bookingsTable/updateCancelBookingDialog', true);
      this.$store.commit('bookingsTable/updateBookingForCancel', item);
    },
    editBooking(item) {
      this.$store.commit('common/setGlobalLoading', true, { root: true });
      this.getBooking(item.retailerBookingId).then(() => {
        this.$store.commit('bookingsTable/updateEditBookingDialog', true);
      }).finally(() => {
        this.$store.commit('common/setGlobalLoading', false, { root: true });
      })
    },
    onSortBy(sort) {
      if ((Array.isArray(sort) && sort.length) || sort) {
        const field = Array.isArray(sort) ? sort[0] : sort;
        this.$store.commit('bookingsTable/updateSortField', field);
      } else {
        this.$store.commit('bookingsTable/updateSortField', '');
      }
      this.getBookings();
    },
    onUpdateOption(options) {
      // desc
      const { sortDesc } = options;
      this.$store.commit('bookingsTable/updateSortParams', {
        field: 'descending',
        value: (Array.isArray(sortDesc) && sortDesc.length && !!sortDesc[0]) || false,
      });
      // sort by
      const { sortBy } = options;
      this.$store.commit('bookingsTable/updateSortField', (Array.isArray(sortBy) && sortBy.length) || sortBy
        ? (Array.isArray(sortBy) ? sortBy[0] : sortBy)
        : '')
      // pagination
      this.$store.commit('bookingsTable/setPagination', {
        itemsPerPage: options.itemsPerPage,
        pageStart: (options.page - 1) * options.itemsPerPage,
      });
      // get bookings
      this.getBookings();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./bookingsListPage.scss";
</style>
