import { SGeoService } from '@slg/web-customer-shared';
import RetailerUserService from '@/services/RetailerUserService';

export default {
  namespaced: true,
  state: {
    profile: {
      firstName: '',
      lastName: '',
      login: '',
      partnerId: '',
      state: '',
      userId: '',
    },
    authToken: '',
    isMidocoAuth: false,
    location: null,
  },
  mutations: {
    setAuthToken(state, data) {
      localStorage.setItem('authToken', data);
      state.authToken = data;
    },
    setIsMidocoAuth(state, isMidocoAuth) {
      localStorage.setItem('isMidocoAuth', JSON.stringify(isMidocoAuth));
      state.isMidocoAuth = isMidocoAuth;
    },
    setLocation(state, ip) {
      const location = ip.split(';')[1];
      localStorage.setItem('location', location);
      state.location = location;
    },
    setUserProfileInfo(state, data) {
      const keys = Object.keys(state.profile);
      keys.forEach((key) => {
        state.profile[key] = data[key];
      });
      state.profile.anotherPassengerList = state.profile.anotherPassengerList.sort((a, b) => b.lastUsedDateTime - a.lastUsedDateTime);
    },
    clearAuthTokenAndProfileInfo(state) {
      state.authToken = '';
      localStorage.removeItem('authToken');
      localStorage.setItem('isMidocoAuth', JSON.stringify(false));
      const keys = Object.keys(state.profile);
      keys.forEach((key) => {
        state.profile[key] = null;
      })
    },
  },
  actions: {
    async populateUserProfileInfo({ commit }) {
      try {
        const { data } = await RetailerUserService.getProfile();
        commit('setUserProfileInfo', data);
      } catch (e) {
        //
      }
    },
    async setGeoData({ commit }) {
      try {
        const ip = await SGeoService.initIP();
        commit('setLocation', ip.data);
      } catch (e) {
        //
      }
    },
  },
  getters: {
    getIsAuth: (state) => !!state.authToken,
    getLocation: (state) => state.location || localStorage.getItem('location'),
    getAuthToken: (state) => state.authToken,
    getUserProfile: (state) => state.profile,
    getFirstName: (state) => state.profile.firstName,
    getFirstLetters: (state) => (state.profile.firstName && state.profile.lastName)
      && `${state.profile?.firstName[0]}${state.profile?.lastName[0]}`,
    getFullName: (state) => `${state.profile.firstName} ${state.profile.lastName}`,
    getIsMidocoAuth: (state) => state.isMidocoAuth,
  },
}
