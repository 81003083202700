<template>
  <VApp id="app">
    <VCard
      v-scroll.self="onScroll"
      class="p-0 shadow-none app-container d-flex flex-column overflow-y-auto"
    >
      <SSpinner
        :isGlobalLoading="isGlobalLoading"
        class="spinner-wrapper"
        size="70"
        :label="getLabel"
      />
      <SGlobalErrorDialog
        ref="globalErrorDialog"
      />
      <SessionExpiredDialog />
      <div
        id="main-container"
        class="main-container"
      >
        <TheHeader :isHeaderShown="isHeaderShown" />
        <VMain
          class="pt-0 page-content-container"
          :class="isHeaderShown ? 'mt-13' : ''"
        >
          <RouterView class="h-100 pageBg page-content-container d-flex justify-content-center" />
          <Footer v-if="getIsBookingPage" />
        </VMain>
      </div>
    </VCard>
  </VApp>
</template>

<script>
import { mapGetters } from 'vuex';
import { SSpinner, SGlobalErrorDialog } from '@slg/web-customer-shared';
import TheHeader from '@/components/theHeader/TheHeader.vue';
import Footer from '@/components/footer/Footer.vue';
import SessionExpiredDialog from '@/components/common/SessionExpiredDialog.vue';

export default {
  name: 'App',
  components: {
    SSpinner,
    TheHeader,
    Footer,
    SGlobalErrorDialog,
    SessionExpiredDialog,
  },
  metaInfo: {
    title: 'LOOP',
  },
  data() {
    return {
      isHeaderShown: true,
      lastScrollPosition: 0,
    };
  },
  computed: {
    ...mapGetters({
      isGlobalLoading: 'common/isGlobalLoading',
      getLocation: 'auth/getLocation',
      getLabel: 'common/getLabel',
    }),
    getIsBookingPage() {
      return this.$route.name === 'Booking';
    },
  },
  watch: {
    '$route.params.lang': {
      handler(lang) {
        let isLanguageSupported = false;
        Object.keys(this.$i18n.messages).forEach((key) => {
          if (key === lang) {
            isLanguageSupported = true;
          }
        });
        this.$i18n.locale = isLanguageSupported ? lang : 'en';
      },
      deep: true,
      immediate: true,
    },
    '$route.name': {
      handler() {
        this.isHeaderShown = this.$route.name !== 'Login';
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch('featureFlags/getAllEnabledFeatures');
    await this.initPlacesApi();
    await this.checkAuth();
    await this.checkLocation();
  },
  mounted() {
    this.$root.openGlobalErrorDialog = this.$refs.globalErrorDialog.open;
  },
  methods: {
    async checkLocation() {
      const location = localStorage.getItem('location');
      if (!location) {
        await this.$store.dispatch('auth/setGeoData');
      }
    },
    async checkAuth() {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          await this.$store.dispatch('auth/populateUserProfileInfo');
          this.$store.commit('auth/setAuthToken', token);
        } catch (e) {
          this.$store.commit('auth/clearAuthTokenAndProfileInfo');
        }
      }
    },
    async initPlacesApi() {
      // eslint-disable-next-line no-undef
      const key = config.VUE_APP_GOOGLE_KEY || process.env.VUE_APP_GOOGLE_KEY;
      if (!document.querySelector('[data-name="googlePlaceApi"]')) {
        const placeApiScript = document.createElement('script');
        placeApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=${this.$route.params.lang}`;
        placeApiScript.dataset.name = 'googlePlaceApi';
        document.head.appendChild(placeApiScript);
      }
    },
    onScroll(e) {
      const defaultScrollValue = 60;
      if (e.target.scrollTop > defaultScrollValue && this.lastScrollPosition < e.target.scrollTop) {
        this.isHeaderShown = false;
      }
      if (this.lastScrollPosition > e.target.scrollTop) {
        this.isHeaderShown = true;
      }
      this.lastScrollPosition = e.target.scrollTop;
    },
  },
}
</script>

<style lang="scss">
.app-container {
  height: 100vh;

  .main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .page-content-container {
      @media screen and (max-width: 1264px) {
        min-height: calc(100vh - 52px);
      }
    }
  }
}
</style>
