const STATUSES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
}

export {
  STATUSES,
}
