<template>
  <div>
    <PassengersSelection />
    <ServicesSelection />
  </div>
</template>
<script>
import PassengersSelection from './passengersSelection/PassengersSelection.vue';
import ServicesSelection from './servicesSelection/ServicesSelection.vue';

export default {
  name: 'PassengersAndServices',
  components: {
    PassengersSelection,
    ServicesSelection,
  },
}
</script>

<style scoped lang="scss">
</style>
